import React from 'react'
import { Row, Col } from 'reactstrap'
import { withFirebase } from '../../components/Fb'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers'
// import MomentUtils from '@date-io/moment'
import moment from 'moment'
// import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Pivoting from '../../components/Report/Pivoting'
// import Busy from '../../components/Report/Busy'
import BusySimple from '../../components/Report/BusySimple'
import Persons from '../../components/Report/Persons'
// import { DatePicker } from '@material-ui/pickers'
import SportmoneyReport from '../../components/Report/SportmoneyReport'
import ReportsTimePicker from '../../components/Report/ReportsTimePicker'
import ReportDashboard from '../../components/Report/ReportDashboard'

const REPORTS = [
  { name: 'Myyntiraportti', value: 'sales' },
  { name: 'Maksuraportti (Kirjanpitäjälle)', value: 'payments' },
  { name: 'Saldoraportti', value: 'saldos' },
  { name: 'Saldoraportti yksilöity', value: 'personalsaldos' },
  // { name: 'Varausaste', value: 'busy', disabled: false },
  { name: 'Varausaste', value: 'busyraw', disabled: false },
  { name: 'Käyttäjälistaus', value: 'persons', disabled: false, dates: false },
  { name: 'Liikuntarahalataukset', value: 'sportmoney', disabled: false },
]

const HEADERS = {
  payments: [
    'id',
    'Käyttöpäivä',
    'Paikka',
    'Vakio/Irto',
    'Laji',
    'Vuoro',
    'Maksupäivä',
    'Maksuvälittäjä',
    '€',
    'Kanava',
    'Kategoria',
    'tagit',
  ],
}

const mappings = {
  payments: {
    channel: {
      pos: 'Kassa',
      cash: 'Käteismerkintä',
      cashwallet: 'Saldomaksu',
      storage: 'Pankki/Varasto',
      online: 'Verkkomaksu',
      invoice: 'Lasku',
      personelbenefit: 'Henkilökuntaetu',
      manualsaldo: 'Manuaalinen saldomaksu',
      cashstore: 'Tuntipankkimaksu',
      'sportmoney-epassi': 'Epassi kassalla',
      'sportmoney-smartum': 'Smartum kassalla',
      'sportmoney-edenred': 'Edenred kassalla',
      'sportmoney-eazybreak': 'Easybreak kassalla',
      'gift-certificate': 'Lahjakortti kassalla',
    }
  }
}

const formatPayments = (rows) => {
  const data = []
  data.push(HEADERS['payments'])
  const r = rows.map((row) => {
    const vals = row.split(';')
    vals[8] = parseInt(vals[8]) / 100
    vals[9] = mappings.payments.channel[vals[9]]
    vals[3] = vals[3] === 'true' ? 'Vakio' : 'Irto'
    vals[6] = moment.utc(vals[6]).format('YYYY-MM-DD')
    return [...vals]
  })
  return [...data, ...r]
}

const formatSales = (rows) => {
  return rows.map((row) => {
    const keys = Object.keys(row)
    row['€'] = row.cost
    row['Laji'] = row.sport
    row['Vakio/Irto'] = row.recurrent ? 'Vakio' : 'Irto'
    row['Varauskanava'] = row.channel
    row['Päivä'] = moment(row.occurency).format('YYYY-MM-DD')
    row['Kesto'] = row.duration
    row['Kenttä'] = row.displayName
    row['Alkava tunti'] = row.startHour
    row['Tags'] = row.tags
    row['Email'] = row.email
    row['Kategoria'] = row.category
    for (let k of keys) {
      delete row[k]
    }
    return row
  })
}

const formatSaldos = (rows) => {
  return rows.map((row) => {
    const keys = Object.keys(row)
    row['€'] = row.total
    row['Käyttäjiä'] = row.usercount
    row['Keskiarvo'] = row.averagePerUser
    row['Tapahtumia'] = row.transactionscount
    row['Päivä'] = moment().year(row.year).month(row.month - 1).date(row.day).format('YYYY-MM-DD')
    for (let k of keys) {
      delete row[k]
    }
    return row
  })
}

const formatPersonalSaldos = (rows) => {
  return rows.map((row) => {
    const keys = Object.keys(row)
    row['€'] = row.amount / 100
    row['Nimi'] = row.name
    row['Email'] = row.email
    row['Paikka'] = row.venue
    row['Päivä'] = moment().year(row.year).month(row.month - 1).date(row.day).format('YYYY-MM-DD')
    for (let k of keys) {
      delete row[k]
    }
    return row
  })
}

const formatBusy = (rows) => {
  const dayCount = rows.reduce((acc, row) => {
    const d = moment(row.date).format('YYYY-MM-DD')
    if (!acc.includes(d)) {
      acc.push(d)
    }
    return acc
  }, []).length
  return rows.map((row) => {
    const avgo = row.occupancy / dayCount
    row.avgo = avgo
    return row
  })
}
      
const formatBusyRaw = (rows) => {
  return rows
}

const format = (rows, report) => {
  switch (report) {
    case 'payments':
      return formatPayments(rows)
    case 'sales':
      return formatSales(rows)
    case 'saldos':
      return formatSaldos(rows)
    case 'personalsaldos':
      return formatPersonalSaldos(rows)
    case 'busy':
      return formatBusy(rows)
    case 'busyraw':
        return formatBusyRaw(rows)
      default:
      return rows
  }
}

const getTimeSteps = (step) => {
  const times = []
  let tt = 0; // start time
  for (var i=0;tt<24*60; i++) {
    var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
    var mm = (tt%60); // getting minutes of the hour in 0-55 format
    times[i] = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2) // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + step;
  }
  times.push('23:59')
  return times
}

class ReportingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startdate: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      enddate: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      starttime: '06:00',
      endtime: '23:00',
      report: '',
      rows: [],
      loading: false,
      mappings: mappings,
    }
  }

  componentDidMount() {
    this.unsubscribePaymenttypes = this.props.firebase.getConfig('paymenttypes').onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const data = snapshot.data()
        if (data && data.types) {
          const _channels = data.types.reduce((acc, type) => {
            acc[type.value] = type.label
          }, {})
          const maps = { ...this.state.mappings }
          Object.keys(maps.payments.channel).forEach((k) => {
            if (!_channels[k]) {
              _channels[k] = mappings.payments.channel[k]
            }
          })
          maps.payments.channel = _channels
          this.setState({ mappings: maps })
        }
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribePaymenttypes) {
      this.unsubscribePaymenttypes()
    }
  }

  setStartDate = (e) => {
    this.setState({ startdate: e.target.value })
  }

  setEndDate = (e) => {
    this.setState({ enddate: e.target.value })
  }

  setReport = (e) => {
    if (this.state.report !== e.target.value) {
      this.setState({ report: e.target.value, rows: [] })
    } else {
      this.setState({ report: e.target.value })
    }
  }

  setEndtime = (event) => {
    this.setState({ endtime: event.target.value })
  }

  setStarttime = (event) => {
    this.setState({ starttime: event.target.value })
  }

  getComparisonReport() {
    this.setState({ loading: true })
    this.props.firebase.getComparisonReport(this.state.comparisonstartdate, this.state.comparisonenddate, this.state.report).then((response) => {
      if (!response.data.error) {
        const r = format(response.data.rows, this.state.report)
        this.setState({ comparisonrows: r, loading: false })
      } else {
        this.setState({ comparisonrows: [], loading: false })
      }
    })
  }

  getReport() {
    this.setState({ loading: true })
    this.props.firebase.getReport(this.state.startdate, this.state.enddate, this.state.report, this.state.starttime || '00:00', this.state.endtime || '23:59').then((response) => {
      if (!response.data.error) {
        console.log('SERVER DATA', response.data.rows)
        const r = format(response.data.rows, this.state.report)
        console.log('formatted report', r)
        this.setState({ rows: r, loading: false })
      } else {
        this.setState({ rows: [], loading: false })
      }
    })
  }


  getReportView() {
    switch (this.state.report) {
      case 'persons':
        return <Persons rows={this.state.rows} report={this.state.report} />
      case 'busy':
        return <BusySimple rows={this.state.rows} report={this.state.report} />
      case 'busyraw':
        return <BusySimple rows={this.state.rows} report={this.state.report} comparisonrows={this.state.comparisonrows} comparison={this.state.comparison} />
      case 'sportmoney':
        return <SportmoneyReport rows={this.state.rows} report={this.state.report} />
      default:
        return <Pivoting rows={this.state.rows} report={this.state.report} />
    }
  }

  handleReportChange = (data) => {
    this.setState({ loading: true })
    const { startdate, enddate, comparison, comparisonstartdate, comparisonenddate, starttime, endtime, comparisonstarttime, comparisonendtime } = data
    if (comparison) {
      Promise.all([
        this.props.firebase.getReport(startdate, enddate, this.state.report, starttime || '00:00', endtime || '23:59'),
        this.props.firebase.getReport(comparisonstartdate, comparisonenddate, this.state.report, comparisonstarttime || '00:00', comparisonendtime || '23:59'),
      ]).then((response) => {
        let rows = []
        let comparisonrows = []
        if (!response[0].data.error) {
          rows = format(response[0].data.rows, this.state.report)
        } 
        if (!response[1].data.error) {
          comparisonrows = format(response[1].data.rows, this.state.report)
          this.setState({ comparisonrows: [], loading: false })
        }
        this.setState({ rows, comparisonrows, loading: false, comparison: true })
      })
    } else {
      console.log('GETTING REPORT')
      this.props.firebase.getReport(startdate, enddate, this.state.report, starttime || '00:00', endtime || '23:59').then((response) => {
        let rows = []
        if (!response.data.error) {
          console.log('SERVER DATA', response.data.rows)
          rows = format(response.data.rows, this.state.report)
          console.log('formatted report', rows)
        }
        this.setState({ rows, loading: false, comparison: false })
      })
    }
  }


  render() {
    return (
      <React.Fragment>
        <div className='content'>
        <Row style={{ marginBottom: 10 }}>
              <Col xs={12} md={6} lg={6} xl={5} style={{ maxWidth: 600 }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id='report'>Raportti</InputLabel>
                  <Select
                    fullWidth
                    labelId='report-label'
                    id='report'
                    value={this.state.report || ''}
                    onChange={this.setReport}
                  >
                    {REPORTS.map((v, i) => {
                      return (
                        <MenuItem key={v.value} value={v.value} disabled={v.disabled}>
                          {v.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
            </Col>
          </Row>

          {/* <MuiPickersUtilsProvider utils={MomentUtils}>
            <Row>
              <Col>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id='report'>Raportti</InputLabel>
                  <Select
                    fullWidth
                    labelId='report-label'
                    id='report'
                    value={this.state.report || ''}
                    onChange={this.setReport}
                  >
                    {REPORTS.map((v, i) => {
                      return (
                        <MenuItem key={v.value} value={v.value} disabled={v.disabled}>
                          {v.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Col>

              <Col>
                {this.state.report !== 'persons' && (
                  <TextField
                    id='recurstart'
                    label='Alkupäivä'
                    type='date'
                    // defaultValue={this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : ''}
                    value={
                      this.state.startdate
                        ? moment.utc(this.state.startdate).format('YYYY-MM-DD')
                        : moment.utc().subtract(1, 'day').format('YYYY-MM-DD')
                    }
                    onChange={this.setStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </Col>
              <Col>
                {this.state.report !== 'persons' && (
                  <TextField
                    id='recurstart'
                    label='Loppupäivä'
                    type='date'
                    value={
                      this.state.enddate
                        ? moment.utc(this.state.enddate).format('YYYY-MM-DD')
                        : moment.utc().format('YYYY-MM-DD')
                    }
                    onChange={this.setEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </Col>

              {this.state.venues && this.state.venues.length > 0 ? (
                <Col>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='venue'>Paikka</InputLabel>
                    <Select
                      fullWidth
                      labelId='venue-label'
                      id='venue'
                      value={this.state.selectedVenue || ''}
                      onChange={this.setVenue}
                    >
                      {this.state.venues.map((v, i) => {
                        return (
                          <MenuItem key={v.name.toLowerCase()} value={v.name.toLowerCase()}>
                            {v.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Col>
              ) : null}
              <Col>
                <Button
                  disabled={!(this.state.startdate && this.state.enddate && this.state.report)}
                  onClick={() => this.getReport()}
                >
                  {this.state.loading ? <Spinner size={'sm'} /> : 'Hae raportti'}
                </Button>
              </Col>
            </Row>
          </MuiPickersUtilsProvider> */}
          {/* {this.state.report === 'busy' || this.state.report === 'busyraw' ? (
            <Row>
              <Col>
                <FormControl>
                  <InputLabel id='starttime-select-label'>Alkuaika</InputLabel>
                  <Select
                    labelId='starttime-select-label'
                    id='starttime-select-label'
                    value={this.state.starttime || '07:00'}
                    onChange={this.setStarttime}
                  >
                    {getTimeSteps(15).map((v, i) => {
                      return (
                        <MenuItem key={v} value={v}>
                          {v}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>{' '}
                <FormControl>
                  <InputLabel id='endttime-select-label'>Loppuaika</InputLabel>
                  <Select
                    labelId='endttime-select-label'
                    id='endttime-select-label'
                    value={this.state.endtime || '23:00'}
                    onChange={this.setEndtime}
                  >
                    {getTimeSteps(15).map((v, i) => {
                      return (
                        <MenuItem key={v} value={v}>
                          {v}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          ) : null} */}
          <Row>
            <Col>
              <ReportsTimePicker report={this.state.report} venues={this.state.venues} onSubmit={this.handleReportChange} loading={this.state.loading} />
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.report ? this.getReportView(): <ReportDashboard />}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

export default withFirebase(ReportingContainer)
