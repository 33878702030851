import React from 'react'
import { CircularProgress, TextField, InputAdornment } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import SaveAltIcon from '@material-ui/icons/SaveAlt'

function PlusMinusEditButton(props) {
  const [amount, setAmount] = React.useState(0)
  const [showAmount, setShowAmount] = React.useState(false)
  const [add, setAdd] = React.useState(false)
  const { onPlus, onMinus, loading, id } = props

  if (loading) return <CircularProgress size={10} />

  const handleShowAmount = (direction) => {
    setAdd(direction)
    setShowAmount(true)
  }

  const handlePlus = () => {
    if (amount > 0) {
      onPlus(id, amount)
      setShowAmount(false)
      setAmount(0)
    }
  }

  const handleMinus = () => {
    if (amount > 0) {
      onMinus(id, amount)
      setShowAmount(false)
      setAmount(0)
    }
  }

  if (showAmount) {
    const icon = add ? (
      <AddCircleOutlineIcon fontSize='small' color='disabled' />
    ) : (
      <RemoveCircleOutlineIcon fontSize='small' color='disabled'/>
    )
    return (
      <>
        <TextField
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>{icon}</InputAdornment>
            ),
            endAdornment: <SaveAltIcon fontSize='small' onClick={add ? handlePlus : handleMinus} style={{ cursor: 'pointer' }} />,
          }}
        />
      </>
    )
  } else {
    return (
      <>
        <AddCircleOutlineIcon fontSize='small' onClick={() => handleShowAmount(true)} />
        {' / '}
        <RemoveCircleOutlineIcon fontSize='small' onClick={() => handleShowAmount(false)} />
      </>
    )
  }
}

export default PlusMinusEditButton
