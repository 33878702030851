export const _benefitCardUISchema = {
  createcode: {
    'ui:description': 'Huom! Ominaisuus tuettuna vain Digioven kulunvalvonnan kanssa.',
  },
  accessstart: {
    'ui:description': 'Esimerkiksi 6:30',
  },
  accessend: {
    'ui:description': 'Esimerkiksi 22:00',
  },
  accesslevels: {
    'ui:description': 'Katso mahdolliset arvot Digiovesta. Erottimena pilkku.',
  },
  description: {
    'ui:widget': 'editorWidget',
  },
  confirmationMessage: {
    'ui:widget': 'editorWidget',
  },
  image: {
    'ui:widget': 'imageWidget',
  }
}

// Aktiviteetin sarjakortti
export const _benefitCardClass = {
  name: { type: 'string', title: 'Sarjakortin nimi' },
  price: { type: 'number', title: 'Hinta, käytä hintavaihtoehtoja', readOnly: true },
  // count: { type: 'number', title: 'Käyntikertojen määrä' },
  // duration: { type: 'number', title: 'Kesto (päivää)' },
  description: { type: 'string', title: 'Kuvaus' },
  validuntil: { type: 'string', format: 'date', title: 'Voimassaolon viimeinen päivä' },
  availableinshop: {
    type: 'string',
    title: 'Näytetäänkö verkkokaupassa',
    enum: ['true', 'false'],
    enumNames: ['Kyllä', 'Ei'],
    default: 'false',
  },
  sport: {
    type: 'string',
    title: 'Laji',
    enum: [],
    enumNames: [],
  },
  type: {
    type: 'string',
    title: 'Sarjakortin tyyppi',
    enum: ['time', 'count'],
    enumNames: ['Aikapohjainen', 'Kertapohjainen'],
  },
  vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%','25,5%'] },
  variations: {
    title: '',
    description: 'Hintavaihtoehdot',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        name: { type: 'string', title: 'Nimi' },
        price: { type: 'number', title: 'Hinta' },
        productCode: { type: 'string', title: 'Tuotekoodi' },
      },
    },
  },

}
export const _benefitCardClassDeps = {
  type: {
    oneOf: [
      {
        properties: {
          type: {
            enum: ['time'],
          },
          duration: {
            type: 'number',
            title: 'Kesto (päivää)',
          },
          createcode: {
            type: 'string',
            title: 'Luodaanko kortille pääsykoodi? (tukee Digiovea)',
            enum: ['true', 'false'],
            enumNames: ['Kyllä. Huom! Tukee Digiovea!', 'Ei'],
          },
          required: ['duration', 'createcode'],
        },
      },
      {
        properties: {
          type: {
            enum: ['count'],
          },
          count: {
            type: 'number',
            title: 'Käyntikertojen määrä',
          },
          required: ['count'],
        },
      },
    ],
  },
  availableinshop: {
    oneOf: [
      {
        properties: {
          availableinshop: {
            enum: ['true'],
          },
          image: { type: 'string', title: 'Verkkokaupan tuotekuva', items: { type: 'object', images: [] }, minaspectratio: 1 },
          confirmationSubject: { type: 'string', title: 'Vahvistusviestin otsikko' },
          confirmationMessage: { type: 'string', title: 'Vahvistusviestin sisältö' },
          accounting: { type: 'string', title: 'Kirjanpidontili' },
          // validity: {
          //   type: 'string',
          //   title: 'Voimassaoloaika',
          //   enum: ['rolling', 'next', 'fixed', 'permanent'],
          //   enumNames: ['Rullaava ajanjakso', 'Seuraavaan ajanjaksoon', 'Kiinteä ajanjakso', 'Pysyvä'],
          // },
          // validityperiod: {
          //   type: 'number',
          //   title: 'Voimassaoloaika päivinä ostohetkestä (0 = ei päättymistä)',
          // },
          // validfrom: {
          //   type: 'string',
          //   title: 'Voimassaolo alkaa, jätä tyhjäksi jos alkaa ostohetkestä',
          //   format: 'date',
          // },
          // validuntil: {
          //   type: 'string',
          //   title: 'Voimassaolo päättyy, jos voimassa oloaika on asetettu se kumpi on aiemmin määrää',
          //   format: 'date',
          // },
        }
      },
      {
        properties: {
          availableinshop: {
            enum: ['false'],
          },
        }
      }
    ]
  },


  createcode: {
    oneOf: [
      {
        properties: {
          createcode: {
            enum: ['true'],
          },
          accesslevels: {
            type: 'string',
            title: 'Kortin pääsykoodin kulkutasot.',
          },
          required: ['accesslevels'],
        },
      },
      {
        properties: {
          createcode: {
            enum: ['false'],
          },
        },
      },
    ],
  },
}
