import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import EuroIcon from '@material-ui/icons/Euro'
import SendIcon from '@material-ui/icons/Send'
import InvoiceDetails from '../Details/InvoiceDetails'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Chip,
  TextField,
  Divider,
  Radio,
  CircularProgress,
  Menu,
  InputAdornment,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green, amber, red } from '@material-ui/core/colors'
import moment from 'moment'
import { withFirebase } from '../Fb'
import TinyEditor from '../Editor/TinyEditor'
import SearchIcon from "@material-ui/icons/Search"
import { Index } from 'flexsearch'

const paymenttypes = [
  {
    value: 'reference',
    label: 'Viitemaksu',
  },
  {
    value: 'banktransfer',
    label: 'Tilisiirto',
  },
  {
    value: 'cash',
    label: 'Kassamaksu',
  },
  {
    value: 'manualpayment',
    label: 'Muu maksu',
  },
]

function buildIndex(data) {
  const index = new Index({
    tokenize: "full"
  });
  data.forEach((row) => {
    console.log('indexing', row)
    index.add(row.id, [row.contact, String(row.invoiceNumber)].join(' '));
  });
  return index;
}

const GreenCheckbox = withStyles({
  root: {
    // color: green[400],
    '&$checked': {
      color: green[600],
    }
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />)


const GreenButton = withStyles({
  root: {
    // color: green[400],
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: 5,
  },
})((props) => <Button {...props} />);

const YellowButton = withStyles({
  root: {
    // color: green[400],
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700],
    },
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: 5,
  },
})((props) => <Button {...props} />);

const RedButton = withStyles({
  root: {
    // color: green[400],
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: 5,
  },
})((props) => <Button {...props} />);

const toCurrency = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2).replace('.', ',') + ' €'
}

// const dropdownOptions = ['Lähetä muistutus', 'Lähetä uudelleen', 'Tee hyvitys']

function InvoiceRow(props) {
  const anchorRef = React.useRef(null)
    console.log('InvoiceRow', props)
  // const dropdownAnchorRef = React.useRef(null)
  // const [open, setOpen] = React.useState(false)
  const { row, cols, onSelect, selected, onAddPayment, onShowDetails, onCreateRefund } = props
  const isPaid = row.invoicingstate === 'paid'
  Object.keys(row).forEach((k) => {
    const col = cols.find((c) => c.accessor === k)
    if (col && col.format) {
      if (row[k] === undefined) {
        row[k] = col.defaultValue !== undefined ? col.defaultValue : ''
      }
      row[k + 'formatted'] = col.format(row[k])
      return k + 'formatted'
    }
    return k
  })

  const handleSelect = (e) => {
    if (onSelect) {
      onSelect(row.id, e.target.checked)
    }
  }

  // const handleToggle = () => {
  //   setOpen(!open)
  // }

  // const handleClose = (event) => {
  //   if (dropdownAnchorRef.current && dropdownAnchorRef.current.contains(event.target)) {
  //     return
  //   }

  //   setOpen(false)
  // }

  return (
    <tr ref={anchorRef}>
      <td style={{ textAlign: 'center', border: '1px solid lightgray' }}>
        <GreenCheckbox size='small' style={{ margin: 0, padding: 0 }} checked={selected} onChange={handleSelect} />
      </td>
      {cols.map((k, i) => (
        <td
          onClick={() => {
            if (k.details) {
              onShowDetails(row.id)
            } else if (k.addpayment) {
              onAddPayment(row.id, anchorRef)
            } else if (k.createRefund) {
              onCreateRefund(row.id)
            }
          }}
          key={k.field + 'c.' + i}
          align={i < 1 ? 'left' : 'right'}
          style={{
            border: '1px solid lightgray',
            paddingRight: 5,
            paddingLeft: 2,
            textDecoration: k.details ? 'underline' : 'normal',
            cursor: k.details ? 'pointer' : 'default',
            color: k.color ? k.color : 'rgba(0, 0, 0, 0.87)',
          }}
        >
          {k.paymentbuttons && !isPaid ? (
            <>
              {' '}
              <Chip
                variant='outlined'
                size='small'
                icon={<EuroIcon />}
                label='Maksu'
                clickable
                onClick={() => onAddPayment(row.id, anchorRef)}
                style={{ marginRight: 5 }}
              />
            </>
          ) : null}
          {row[k.field + 'formatted'] ? row[k.field + 'formatted'] : row[k.field]}
        </td>
      ))}
      {/* <td style={{ textAlign: 'center', border: '1px solid lightgray' }}>
        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          ref={dropdownAnchorRef}
          style={{ minWidth: 30, padding: 0 }}
        >
          <ArrowDropDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={dropdownAnchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 1000 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem>
                    {dropdownOptions.map((option, index) => (
                      <MenuItem key={option} onClick={(event) => handleToggle(event, index)}>
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </td> */}
    </tr>
  )
}

function GroupRow(props) {
  const {
    aggregateBy,
    cols,
    data,
    open,
    onOpenToggle,
    onSelect,
    selectedIds,
    onAddPayment,
    onShowDetails,
    // onCreateRefund,
  } = props
  const { rows, name, paid, unpaid, sum } = data
  let aggName = name
  if (aggregateBy.indexOf('date') > -1) {
    aggName = moment(name).format('D.M.YYYY')
  }
  const _colWidth = 98 / cols.length
  // const [open, setOpen] = React.useState(true)
  return (
    <React.Fragment>
      <tr>
        <td colSpan={cols.length + 2} style={{ borderBottom: '1px solid gray' }} name='ruus'>
          <div
            style={{
              display: 'flex',
              verticalAlign: 'middle',
              alignItems: 'center',
            }}
          >
            <div>
              <IconButton aria-label='expand row' size='small' onClick={() => onOpenToggle(name)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div>
            <div style={{ flex: 1 }}>
              <Typography variant='subtitle2' display='inline'>
                {aggName}
              </Typography>
              <Typography variant='subtitle2' display='inline' style={{ marginLeft: 30 }}>
                {rows.length} kpl
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}>
              <div style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
                <div style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}>
                  Maksettu <Typography variant='subtitle2'>{toCurrency(paid / 100)}</Typography>
                </div>
                <div style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}>
                  Maksamatta <Typography variant='subtitle2'>{toCurrency(unpaid / 100)}</Typography>
                </div>
                <div style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}>
                  Summa <Typography variant='subtitle2'>{toCurrency(sum / 100)}</Typography>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={cols.length + 3} style={{ border: '0px solid black' }}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={0}>
              <table style={{ width: '100%' }}>
                <colgroup>
                  <col style={{ width: '2%' }} />
                  {cols.map((c, i) => {
                    return <col key={c.field} style={c.width ? { width: c.width } : { width: _colWidth + '%' }} />
                  })}
                  {/* <col style={{ width: '2%' }} /> */}
                </colgroup>
                <thead>
                <tr>
              <th style={{ border: '0px solid black', width: '2%' }}></th>
              {cols.map((c, i) => {
                let _s = {
                  border: '0px solid black',
                  textAlign: 'right',
                  padding: 5,
                }
                if (i < 1) {
                  _s.textAlign = 'left'
                }
                return (
                  <th key={c.field} style={_s}>
                    {c.headerName}
                  </th>
                )
              })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row) => (
                    <InvoiceRow
                      key={name + 'r.' + row.id}
                      row={row}
                      ingorekeys={[]}
                      cols={cols}
                      onSelect={onSelect}
                      selected={selectedIds.includes(row.id)}
                      onAddPayment={onAddPayment}
                      onShowDetails={onShowDetails}
                    />
                  ))}
                </tbody>
              </table>
            </Box>
          </Collapse>
        </td>
      </tr>
    </React.Fragment>
  )
}

function aggregate(rows, dimension, sortBy) {
  if (!rows || rows.length === 0) {
    return []
  }
  const t = rows.reduce((acc, row) => {
    if (!acc[row[dimension]]) {
      acc[row[dimension]] = { name: row[dimension], sum: 0, rows: [], unpaid: 0, paid: 0 }
    }
    acc[row[dimension]].sum = acc[row[dimension]].sum + row.sum
    acc[row[dimension]].unpaid = acc[row[dimension]].unpaid + row.unpaid
    acc[row[dimension]].paid = acc[row[dimension]].paid + row.paid
    acc[row[dimension]].rows.push(row)
    return acc
  }, {})
  Object.keys(t).forEach((k) => t[k].rows.sort((a, b) => a[sortBy] - b[sortBy]))
  return Object.values(t)
}

function getCols(cols, ingorekeys, aggregateBy) {
  if (!cols || cols.length === 0) {
    return []
  }
  if (!ingorekeys) ingorekeys = []
  return cols.filter((k) => ingorekeys.includes(k.field) === false).filter((k) => aggregateBy !== k.field)
}

function AggregateTable(props) {
  const { rows, sortBy, cols } = props

  const [aggregateBy, setAggregateBy] = React.useState(null)
  if (aggregateBy === null) {
    setAggregateBy(props.aggregateBy)
  }
  const [aggRows, setAggRows] = React.useState([])
  const [opens, setOpens] = React.useState({})
  const [selectedIds, setSelectedIds] = React.useState([])
  const [detailsOpen, setDetailsOpen] = React.useState(false)
  const [detailsId, setDetailsId] = React.useState(null)
  const [selectedInvoice, setSelectedInvoice] = React.useState(null)
  // const [paymentMenuRef, setPaymentMenuRef] = React.useState(null)
  // const [openPaymentMenu, setOpenPaymentMenu] = React.useState(false)
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false)
  const [partPayment, setPartPayment] = React.useState(null)
  const [openSendDialog, setOpenSendDialog] = React.useState(false)
  const [readyToSend, setReadyToSend] = React.useState(false)
  const [allselectedsent, setAllselectedsent] = React.useState(false)
  const [openBillingDateDialog, setOpenBillingDateDialog] = React.useState(false)
  const [openDueDateDialog, setOpenDueDateDialog] = React.useState(false)
  const [batchBillingdate, setBatchBillingdate] = React.useState(null)
  const [batchDuedate, setBatchDuedate] = React.useState(null)
  const [filterPaidOut, setFilterPaid] = React.useState(false)
  const [paymentNote, setPaymentNote] = React.useState(null)
  const [waitingServer, setWaitingServer] = React.useState(false)
  const [paymenttype, setPaymenttype] = React.useState(null)
  const [sendtype, setSendtype] = React.useState(null)
  const [sendTitle, setSendTitle] = React.useState(null)
  const [coveringnote, setCoveringnote] = React.useState(null)
  const [messageTitle, setMessageTitle] = React.useState(null)
  const [paymentDate, setPaymentDate] = React.useState(moment.utc().format('YYYY-MM-DD'))
  const [reportAnchorEl, setReportAnchorEl] = React.useState(null)
  const reportMenuOpen = Boolean(reportAnchorEl)
  const [searchInput, setSearchInput] = React.useState('')
  const [searchIndex, setSearchIndex] = React.useState(null)
  const [searchResults, setSearchResults] = React.useState([])
  const [creditmemorandumOpen, setCreditmemorandumOpen] = React.useState(false)
  const [creditmemorandumRows, setCreditmemorandumRows] = React.useState([])
  const [overCrediting, setOverCrediting] = React.useState(false)


  const _cols = getCols(cols, null, aggregateBy)
  const _colWidth = 98 / _cols.length
  const aggregateByCols = cols.filter((c) => c.aggregateBy)

  useEffect(() => {
    const noduedate = selectedIds.some((id) => {
      const row = rows.find((r) => r.id === id)
      return !row.duedate
    })
    if (noduedate) {
      setReadyToSend(false)
    } else {
      setReadyToSend(true)
    }
    const somenotsent = selectedIds.some((id) => {
      const row = rows.find((r) => r.id === id)
      return !row.sent
    })
    if (somenotsent) {
      setAllselectedsent(false)
    } else {
      setAllselectedsent(true)
    }

    const _rows = rows.filter((r) => {
      if (filterPaidOut && r.invoicingstate === 'paid') {
        return false
      }
      return true
    }).filter((r) => {
      if (searchResults && searchResults.length > 0) {
        return searchResults.includes(r.id)
      }
      return true
    })
      
    const aggRows = aggregate(_rows, aggregateBy, sortBy)
    console.log('aggRows', aggRows)
    setAggRows(aggRows)
  }, [rows, selectedIds, filterPaidOut, aggregateBy, sortBy, searchResults])

  useEffect(() => {
    if (!searchIndex) {
      let _rows = rows
      if (filterPaidOut) {
        _rows = rows.filter((r) => r.invoicingstate !== 'paid')
      }
      const _searchIndex = buildIndex(_rows)
      setSearchIndex(_searchIndex)
    }
  }, [rows, filterPaidOut, searchIndex])

  const handleSearch = (event) => {
    const _searchInput = event.target.value
    setSearchInput(_searchInput)
    if (_searchInput.length < 3) {
      setSearchResults([])
      return
    }
    console.log('searching', _searchInput)
    const _searchResults = searchIndex.search(_searchInput)
    console.log('searchResults', _searchResults)
    setSearchResults(_searchResults)
  }


  const handleAggregateChange = (event) => {
    setAggregateBy(event.target.value)
    setOpens({})
  }

  const handleOpenToggle = (name) => {
    setOpens({ ...opens, [name]: !opens[name] })
  }

  const collapseAll = () => {
    let _opens = {}
    aggRows.forEach((row) => {
      _opens[row.name] = false
    })
    setOpens(_opens)
  }

  const expandAll = () => {
    let _opens = {}
    aggRows.forEach((row) => {
      _opens[row.name] = true
    })
    setOpens(_opens)
  }

  const selectAll = () => {
    let _selectedIds = []
    aggRows.forEach((row) => {
      row.rows.forEach((r) => {
        _selectedIds.push(r.id)
      })
    })
    setSelectedIds(_selectedIds)
  }

  const selectNone = () => {
    setSelectedIds([])
  }

  const toggleSelectAll = () => {
    if (selectedIds.length > 0 && selectedIds.length === rows.length) {
      selectNone()
    } else {
      selectAll()
    }
  }

  const onShowDetails = (id) => {
    const row = rows.find((r) => r.id === id)
    if (!row) return
    const details = row.details
    if (!details) return
    setSelectedInvoice(row)
    setDetailsId(id)
    setDetailsOpen(true)
  }

  const handleDetailsClose = () => {
    setDetailsOpen(false)
    setDetailsId(null)
    setCreditmemorandumOpen(false)
    setCreditmemorandumRows([])
  }

  const onAddPayment = (id, anchorRef) => {
    console.log('onAddPayment', id)
    // setPaymentMenuRef(anchorRef)
    // setOpenPaymentMenu(true)
    setDetailsId(id)
    setOpenPaymentDialog(true)
  }

  // const handlePaymentMenuClose = (event) => {
  //   if (paymentMenuRef.current && paymentMenuRef.current.contains(event.target)) {
  //     return
  //   }
  //   setOpenPaymentMenu(false)
  // }

  const handleBillingDateDialog = () => {
    if (batchBillingdate === null) {
      setBatchBillingdate(moment.utc())
    }
    setOpenBillingDateDialog(true)
  }

  const handleDueDateDialog = () => {
    if (batchDuedate === null) {
      setBatchDuedate(moment.utc())
    }
    setOpenDueDateDialog(true)
  }

  const handleSendDialog = (sendtype) => {
    setSendtype(sendtype)
    if (sendtype === 'invoice') {
      setSendTitle('Lähetä laskut')
    } else if(sendtype === 'creditmemorandum') {
      setSendTitle('Lähetä hyvityslaskut')
    } else {
      setSendTitle('Lähetä muistutukset')
    }
    setOpenSendDialog(true)
  }

  const handleDateChange = (event, datetype) => {
    if (datetype === 'duedate') {
      const _duedate = moment.utc(event.target.value)
      setBatchDuedate(_duedate)
    } else if (datetype === 'billingdate') {
      const _billingdate = moment.utc(event.target.value)
      setBatchBillingdate(_billingdate)
    }
  }

  const handlePaymenttypeChange = (event) => {
    setPaymenttype(event.target.value)
  }

  /* backend actions start */

  const saveDueAndBillingDate = async (due) => {
    console.log('saveDueAndBillingDate', selectedIds, batchDuedate, batchBillingdate)
    if(due) {
      await props.firebase.setduedate(batchDuedate.format('YYYY-MM-DD'), selectedIds)
    } else {
      await props.firebase.setbillingdate(batchBillingdate.format('YYYY-MM-DD'), selectedIds)
    }
    setBatchBillingdate(null)
    setBatchDuedate(null)
    if (props.onRefresh) {
      props.onRefresh()
    }
    setOpenBillingDateDialog(false)
    setOpenDueDateDialog(false)

  }

  const addPayment = async (full) => {
    if (full) {
      // get unpaid from invoice
      const invoice = rows.find((r) => r.id === detailsId)
      if (invoice) {
        const unpaid = (invoice.sum || 0) - (invoice.paid || 0)
        console.log('all', invoice, unpaid)
        setWaitingServer(true)
        const response = await props.firebase.addpayment(unpaid, detailsId, paymenttype, paymentNote)
        if (response.status === 'error') {
          alert('Maksun lisäys epäonnistui')
        }
        setWaitingServer(false)
      } else {
        // error
        alert('Laskua ei löytynyt')
      }
    } else {
      let _partPayment = null
      if (partPayment) {
        _partPayment = partPayment.replace(',', '.')
        _partPayment = parseFloat(_partPayment) * 100
      }
      if (_partPayment === null) {
        alert('Virheellinen summa')
        return
      }
      setWaitingServer(true)
      console.log('adding partpayment', _partPayment, detailsId, paymenttype, paymentNote)
      const response = await props.firebase.addpayment(_partPayment, detailsId, paymenttype, paymentNote, paymentDate)
      if (response.status === 'error') {
        alert('Maksun lisäys epäonnistui')
      }
      setWaitingServer(false)
    }
    if (props.onRefresh) {
      props.onRefresh()
    }
    setPartPayment(null)
    setPaymentNote(null)
    setPaymenttype(null)
    setPaymentDate(moment.utc().format('YYYY-MM-DD'))
    setOpenPaymentDialog(false)
    setDetailsId(null)
  }

  const sendInvoices = async (type) => {
    console.log('sendInvoices', selectedIds, type)
    setWaitingServer(true)
    const response = await props.firebase.sendinvoices(selectedIds, type, false, coveringnote, messageTitle)
    console.log('sendInvoices response', response)
    if (response?.data?.status === 'error') {
      alert('Laskujen lähetys epäonnistui, ' + response?.data?.message)
    }
    setWaitingServer(false)
    if (props.onRefresh) {
      props.onRefresh()
    }
    setCoveringnote(null)
    setMessageTitle(null)
    setOpenSendDialog(false)
    setSelectedIds([])
    setCreditmemorandumOpen(false)
  }

  const sendInvoice = async (type, justcreate) => {
    console.log('sendInvoice', detailsId, type, justcreate)
    setWaitingServer(true)
    const response = await props.firebase.sendinvoices([detailsId], type, justcreate, coveringnote, '', creditmemorandumRows)
    if (response?.data?.status === 'error') {
      alert('Laskun lähetys epäonnistui, ' + response?.data?.message)
    }
    setWaitingServer(false)
    if (props.onRefresh) {
      props.onRefresh()
    }
    setCoveringnote(null)
    setOpenSendDialog(false)
    setDetailsId(null)
    
    setDetailsOpen(false)
    setSelectedIds([])
    setCreditmemorandumOpen(false)

  }

  const handleReportMenuOpen = (event) => {
    setReportAnchorEl(event.currentTarget);
  }

  const handleReportMenuClose = () => {
    setReportAnchorEl(null);
  }

  const VATS = {
    25.5: '25,5 %',
    24: '24 %',
    10: '10 %',
    14: '14 %',
    0: '0 %',
  }

  const COLUMN_LABELS = {
    'contact': 'Asiakas',
    'sumeur': 'Laskunsumma',
    'invoiceNumber': 'Laskunumero',
    'billingdate': 'Laskutuspäivä',
    'duedate': 'Eräpäivä',
    'paideur': 'Maksettu',
    'unpaideur': 'Maksamatta',
    'invoicingstatelabel': 'Tila',
    'amount': 'Maksettu',
    'type': 'Maksutyyppi',
    'timestamp': 'Maksupäivä',
    'note': 'Muistiinpano',
    'email': 'Sähköposti',
    'vat': 'ALV',
  }

  const CSVCOLUMNS = {
    debts: ['contact',	'sumeur',	'invoiceNumber',	'billingdate',	'duedate',	'paideur',	'unpaideur',	'invoicingstatelabel'],
    payments: ['contact',	'sumeur',	'invoiceNumber',	'billingdate',	'duedate', 'amount', 'type', 'timestamp', 'note'], //[id	contact	sumeur	sum	invoiceNumber	invoicer	billingdate	duedate	status	externals	invoicingmethod	paid	paideur	unpaid	unpaideur	invoicingstate	invoicingstatelabel	details	sent	reminds	remind],
    invoicereport: ['contact',	'sumeur',	'invoiceNumber',	'billingdate',	'duedate',	'invoicingstatelabel', 'email']
  }



  const REPORTPAYMENTTYPES = {
    reference: 'Viitemaksu',
    banktransfer: 'Tilisiirto',
    cash: 'Kassamaksu',
    manualpayment: 'Muu maksu',
    creditmemorandum: 'Hyvityslasku',
    cashwallet: 'Saldomaksu',
    online: 'Verkkomaksu',
    personelbenefit: 'Henkilökuntaetu',
    manualsaldo: 'Manuaalinen saldomaksu',
    referencemanual: 'Manuaalinen viitemaksu'
  }

  const createDownloadLink = (csvRows, report) => {
    let csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" 
      + encodeURI(csvRows.join("\n"))
    console.log(csvContent)
    let link = document.createElement("a")
    link.setAttribute("href", csvContent)
    link.setAttribute("download", report + ".csv")
    document.body.appendChild(link) // Required for FF
    link.click()
  }

  const getReport = (type) => {
    handleReportMenuClose()
    
    if (type === 'payments') {
      const rowsWithPayments = rows.filter((r) => r.paid !== 0)
      const paymentRows = rowsWithPayments.map((row) => {
        if (row.details && row.details.payments && row.details.payments.length > 0) {
          const prows = row.details.payments.map((p) => {
            console.log('p', p)
            if (p.type === 'reference') {
              if (!p.reference) {
                p.type = 'referencemanual'
              }
            }
            return {
              ...p, contact: row.contact, sumeur: row.sumeur, invoiceNumber: row.invoiceNumber, billingdate: row.billingdate, duedate: row.duedate
            }
          })
          return prows
        }
        return null
      }).filter((x) => x)
      const flatRows = paymentRows.flat()
      const keys = CSVCOLUMNS[type]
      const csvRows = flatRows.map((row) => {
        return keys.map((k) => {
          const v = row[k]
          if (k.endsWith('eur')) {
            return toCurrency(v)
          } else if (k === 'amount') {
            return toCurrency(v / 100)
          } else if (k === 'timestamp') {
            return moment.utc(v).format('DD.MM.YYYY')
          } else if (k === 'type') {
            const label = REPORTPAYMENTTYPES[v]
            if (!label) {
              return v
            } else {
              return label
            }
          } else {
            return v
          }
        }).join(';')
      })
      const headers = keys.map((k) => COLUMN_LABELS[k]).join(';')
      csvRows.unshift(headers)
      createDownloadLink(csvRows, type)
      return
    } else {
      const keys = Object.keys(rows[0]).filter((k) => CSVCOLUMNS[type].includes(k))
      if (type === 'invoicereport') {
        Object.keys(VATS).forEach((k) => {
          if (!keys.includes(k)) {
            keys.push(k)
            keys.push(k + 'vat0')
            keys.push(k + 'vatS')
          }
        })
      }
      const vatTotals = {}
      const csvRows = rows.map((row) => {
        if (type === 'invoicereport') {
          const vatSums = row?.details?.rows?.reduce((acc, r) => {
            if (r.vat) {
              acc[r.vat] = acc[r.vat] ? acc[r.vat] + r.amount : r.amount
            }
            return acc
          }, {})
          Object.keys(vatSums).forEach((k) => {
            row[k] = toCurrency(vatSums[k] / 100)
            const vat0 = Math.round(vatSums[k] / (1 + k / 100))
            const vat = vatSums[k] - vat0
            row[k + 'vat0'] = toCurrency(vat0 / 100)
            row[k + 'vatS'] = toCurrency(vat / 100)
            if (!vatTotals[k]) {
              vatTotals[k] = { vat0: 0, vatS: 0, vat: 0 }
            }
            vatTotals[k].vat0 = vatTotals[k].vat0 + vat0
            vatTotals[k].vatS = vatTotals[k].vatS + vat
            vatTotals[k].vat = vatTotals[k].vat + vatSums[k]

          })
          const email = row?.details?.contact?.email ? row.details.contact.email : ''
          row.email = email
          if (!keys.includes('email')) keys.push('email')
        }
        if (type === 'debts' && row.invoicingstate === 'paid') return null
        return keys.map((k) => {
          const v = row[k]
          if (k.endsWith('eur')) {
            return toCurrency(v)
          } else {
            return v
          }
        }).join(';')
      }).filter((x) => x)

      if (type === 'invoicereport') {
        const vatTotalsRow = Object.keys(VATS).map((k) => {
          const c = []
          if (vatTotals[k]) {
            c.push(toCurrency(vatTotals[k].vat / 100))
            c.push(toCurrency(vatTotals[k].vat0 / 100))
            c.push(toCurrency(vatTotals[k].vatS / 100))
          } else {
            c.push('')
            c.push('')
            c.push('')
          }
          return c.join(';')
        }).join(';')

        const offset = new Array(CSVCOLUMNS.invoicereport.length - 1).fill(';').join('')
        csvRows.push(offset + vatTotalsRow)
      }


      const headers = keys.map((k) => {
        if (isNaN(k)) {
          if (k.includes('vat')) {
            if (k.endsWith('vat0')) {
              return VATS[k.split('vat')[0]] + ' ALV0'
            } else if (k.endsWith('vatS')) {
              return VATS[k.split('vat')[0]] + ' ALV'
            } else {
              return VATS[k]
            }
          }
          return COLUMN_LABELS[k]
        } else {
          return VATS[k]
        }
      }).join(';')
      csvRows.unshift(headers)
      createDownloadLink(csvRows, type)
    }
  }

  const handleCreditmemorandumChange = (creditmemorandumRows) => {
    console.log('handleCreditmemorandumChange', creditmemorandumRows)
    setCreditmemorandumRows(creditmemorandumRows)
    const creditmemorandumSum = creditmemorandumRows.reduce((acc, row) => {
      return acc + row.amount
    }, 0)
    if (Math.abs(creditmemorandumSum) > selectedInvoice.unpaid) {
      setOverCrediting(true)
    } else {
      setOverCrediting(false)
    }
    console.log('openSum', selectedInvoice.unpaid, creditmemorandumSum)
  }

  /* backend actions end */

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <Paper
          style={{
            padding: 10,
            marginRight: 10,
            width: '100%',
            borderRadius: 0,
          }}
        >
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={1}>
              <FormControlLabel
                value='end'
                control={
                  <GreenCheckbox
                    size='small'
                    checked={
                      selectedIds.length > 0 &&
                      selectedIds.length === rows.length
                    }
                    onChange={toggleSelectAll}
                  />
                }
                label={
                  selectedIds.length > 0 && selectedIds.length === rows.length
                    ? 'Poista valinta'
                    : 'Valitse kaikki'
                }
                labelPlacement='end'
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ minWidth: 160 }}>
                <TextField
                  id='seach-customer'
                  label='Hae asiakasta / laskunumeroa'
                  variant='outlined'
                  size='small'
                  error={searchInput.length > 0 && searchInput.length < 3}
                  value={searchInput}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon color='disabled' />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ minWidth: 160 }}>
                <InputLabel id='grouping-select-label'>Ryhmittely</InputLabel>
                <Select
                  labelId='grouping-select-label'
                  id='grouping-simple-select'
                  value={aggregateBy}
                  onChange={handleAggregateChange}
                >
                  <MenuItem value=''>
                    <em>Ei ryhmittelyä</em>
                  </MenuItem>
                  {aggregateByCols.map((c, i) => {
                    return (
                      <MenuItem key={c.field} value={c.field}>
                        {c.headerName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                value='left'
                control={
                  <Radio
                    checked={!filterPaidOut}
                    onChange={() => setFilterPaid(false)}
                    value={false}
                    name='radio-button-demo'
                    color='default'
                  />
                }
                label='Kaikki'
                labelPlacement='start'
                style={{ marginRight: 2 }}
              />
              <FormControlLabel
                value='right'
                control={
                  <Radio
                    checked={filterPaidOut}
                    onChange={() => setFilterPaid(true)}
                    value={true}
                    name='radio-button-demo'
                    color='default'
                  />
                }
                label='Avoimet'
                labelPlacement='end'
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant='outlined'
                size='small'
                onClick={() => handleSendDialog('invoice')}
                style={{ marginRight: 5 }}
                disabled={readyToSend === false || selectedIds.length < 1}
              >
                Lähetä laskut
              </Button>
              <Button
                variant='outlined'
                size='small'
                onClick={handleBillingDateDialog}
                style={{ marginRight: 5 }}
                disabled={selectedIds.length < 1}
              >
                Aseta laskupäivä
              </Button>
              <Button
                variant='outlined'
                size='small'
                onClick={handleDueDateDialog}
                disabled={selectedIds.length < 1}
                style={{ marginRight: 5 }}
              >
                Aseta eräpäivä
              </Button>
              <Button
                variant='outlined'
                size='small'
                onClick={() => handleSendDialog('reminder')}
                disabled={
                  readyToSend === false ||
                  selectedIds.length < 1 ||
                  allselectedsent === false
                }
              >
                Lähetä muistutukset
              </Button>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'left' }}>
              <Button
                variant='outlined'
                size='small'
                onClick={handleReportMenuOpen}
                aria-controls={reportMenuOpen ? 'report-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={reportMenuOpen ? 'true' : undefined}
                disabled={!rows || rows.length === 0}
              >
                Lataa raportti
              </Button>
              <Menu
                keepMounted
                id='report-menu'
                anchorEl={reportAnchorEl}
                open={reportMenuOpen}
                onClose={handleReportMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'report-button',
                }}
              >
                <MenuItem onClick={() => getReport('debts')}>
                  Myyntisaatavat
                </MenuItem>
                <MenuItem onClick={() => getReport('payments')}>
                  Myyntisuoritukset
                </MenuItem>
                <MenuItem onClick={() => getReport('invoicereport')}>
                  Myyntilaskuraportti
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                aria-label='expand all'
                size='small'
                onClick={expandAll}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <IconButton
                aria-label='collapse all'
                size='small'
                onClick={collapseAll}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <TableContainer component={Paper}>
        <table style={{ width: '100%', fontSize: '0.775rem' }}>
          <colgroup>
            <col style={{ width: 10 }} />
            {_cols.map((c, i) => {
              return (
                <col
                  key={c.field}
                  style={
                    c.width ? { width: c.width } : { width: _colWidth + '%' }
                  }
                />
              )
            })}
            {/* <col style={{ width: 10 }} /> */}
          </colgroup>
          {/* <thead>
            <tr>
              <th style={{ border: '0px solid black', width: '2%' }}></th>
              {_cols.map((c, i) => {
                let _s = {
                  border: '0px solid black',
                  textAlign: 'right',
                  padding: 5,
                }
                if (i < 1) {
                  _s.textAlign = 'left'
                }
                return (
                  <th key={c.field} style={_s}>
                    {c.headerName}
                  </th>
                )
              })}
            </tr>
          </thead> */}
          <tbody>
            {aggRows.map((row) => (
              <GroupRow
                data={row}
                key={row.name}
                aggregateBy={aggregateBy}
                cols={_cols}
                open={opens[row.name]}
                onOpenToggle={handleOpenToggle}
                selectedIds={selectedIds}
                onSelect={(id, selected) => {
                  if (selected) {
                    setSelectedIds([...selectedIds, id])
                  } else {
                    setSelectedIds(selectedIds.filter((i) => i !== id))
                  }
                }}
                onAddPayment={onAddPayment}
                onShowDetails={onShowDetails}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={_cols.length + 1} name='total'>
                <div
                  style={{
                    display: 'flex',
                    verticalAlign: 'middle',
                    alignItems: 'center',
                    marginLeft: 30,
                  }}
                >
                  <div style={{ flex: 1, paddingLeft: 0 }}>
                    <Typography variant='subtitle2'>Yhteensä</Typography>
                  </div>
                  <div style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}
                      >
                        Maksettu{' '}
                        <Typography variant='subtitle2'>
                          {toCurrency(
                            aggRows.reduce((acc, row) => acc + row.paid, 0) /
                              100
                          )}
                        </Typography>
                      </div>
                      <div
                        style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}
                      >
                        Maksamatta{' '}
                        <Typography variant='subtitle2'>
                          {toCurrency(
                            aggRows.reduce((acc, row) => acc + row.unpaid, 0) /
                              100
                          )}
                        </Typography>
                      </div>
                      <div
                        style={{ flex: 1, textAlign: 'right', paddingRight: 5 }}
                      >
                        Summa{' '}
                        <Typography variant='subtitle2'>
                          {toCurrency(
                            aggRows.reduce((acc, row) => acc + row.sum, 0) / 100
                          )}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </TableContainer>
      <Dialog
        open={detailsOpen}
        onClose={handleDetailsClose}
        aria-labelledby='form-dialog-title'
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle id='form-dialog-title'>Laskun tiedot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <InvoiceDetails
              invoice={selectedInvoice}
              creditmemorandumOpen={creditmemorandumOpen}
              onCreditMemorandumChange={handleCreditmemorandumChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: 'auto', justifyContent: 'flex-start' }}>
            <GreenButton
              size='small'
              variant='contained'
              onClick={() => sendInvoice('invoice')}
              color='primary'
              disabled={
                waitingServer ||
                (selectedInvoice &&
                  (selectedInvoice.sent ||
                    selectedInvoice.invoicingstate === 'paid')) ||
                (selectedInvoice && !selectedInvoice.duedate)
              }
            >
              {'Lähetä lasku'}
            </GreenButton>
            <YellowButton
              size='small'
              variant='contained'
              onClick={() => sendInvoice('reminder')}
              color='primary'
              disabled={
                waitingServer ||
                (selectedInvoice &&
                  (!selectedInvoice.sent ||
                    selectedInvoice.invoicingstate === 'paid')) || creditmemorandumOpen
              }
            >
              {'Lähetä muistutus'}
            </YellowButton>
            {/* <RedButton
              size='small'
              variant='contained'
              onClick={() => sendInvoice('creditmemorandum')}
              color='primary'
              disabled={
                waitingServer ||
                (selectedInvoice &&
                  (!selectedInvoice.sent ||
                    selectedInvoice.invoicingstate === 'paid'))
              }
            >
              {'Tee ja lähetä kokohyvitys'}
            </RedButton>
            <RedButton
              size='small'
              variant='contained'
              onClick={() => sendInvoice('creditmemorandum', true)}
              color='primary'
              disabled={
                waitingServer ||
                (selectedInvoice &&
                  (!selectedInvoice.sent ||
                    selectedInvoice.invoicingstate === 'paid'))
              }
            >
              {'Tee kokohyvitys'}
            </RedButton> */}
            <RedButton
              size='small'
              variant='contained'
              onClick={() => setCreditmemorandumOpen(true)}
              color='primary'
              disabled={
                waitingServer ||
                (selectedInvoice &&
                  (!selectedInvoice.sent ||
                    selectedInvoice.invoicingstate === 'paid')) || creditmemorandumOpen
              }
            >
              {'Tee hyvitys'}
            </RedButton>
            {creditmemorandumOpen ? (
              <React.Fragment>
                <GreenButton
                  size='small'
                  variant='contained'
                  onClick={() => sendInvoice('creditmemorandum', true)}
                  color='primary'
                  disabled={
                    waitingServer ||
                    (selectedInvoice &&
                      (!selectedInvoice.sent ||
                        selectedInvoice.invoicingstate === 'paid')) || overCrediting
                  }
                >
                  {'Tallenna'}
                </GreenButton>
                <GreenButton
                  size='small'
                  variant='contained'
                  onClick={() => sendInvoice('creditmemorandum')}
                  color='primary'
                  disabled={
                    waitingServer ||
                    (selectedInvoice &&
                      (!selectedInvoice.sent ||
                        selectedInvoice.invoicingstate === 'paid')) || overCrediting
                  }
                >
                  {'Tallenna ja lähetä'}
                </GreenButton>
              </React.Fragment>
            ) : null}
            {waitingServer ? <CircularProgress size={15} /> : null}
          </div>
          <div>
            <Button onClick={handleDetailsClose} color='primary'>
              Sulje
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Merkkaa maksu</DialogTitle>
        <DialogContent>
          <FormControl style={{ minWidth: 120 }}>
            <InputLabel id='paymenttype-select-label'>Maksuntyyppi</InputLabel>
            <Select
              labelId='paymenttype-select-label'
              id='paymenttype-simple-select'
              value={paymenttype || ''}
              onChange={handlePaymenttypeChange}
            >
              <MenuItem value='' key='noselect'>
                <em>Maksutyyppi</em>
              </MenuItem>
              {paymenttypes.map((c, i) => {
                return (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>{' '}
          <TextField
            margin='dense'
            id='payment-date'
            label='Maksupäivä'
            type='date'
            onChange={(e) => setPaymentDate(e.target.value)}
            value={paymentDate}
            fullWidth
          />
          <TextField
            margin='dense'
            id='payment-comments'
            label='Muistiinpano'
            type='text'
            fullWidth
            onChange={(e) => setPaymentNote(e.target.value)}
          />
          <Divider />
          <Button
            variant='outlined'
            size='small'
            onClick={() => addPayment(true)}
            style={{ marginRight: 5, marginTop: 10 }}
            disabled={!paymenttype}
          >
            {waitingServer ? (
              <CircularProgress size={15} />
            ) : (
              'Merkkaa kokonaan maksetuksi'
            )}
          </Button>
        </DialogContent>
        <DialogContent>
          <Divider />
          <TextField
            autoFocus
            margin='dense'
            id='payment-sum'
            label='Summa'
            type='text'
            onChange={(e) => setPartPayment(e.target.value)}
            value={partPayment || ''}
          />
          <br />
          <Button
            variant='outlined'
            size='small'
            onClick={() => addPayment(false)}
            style={{ marginRight: 5, marginTop: 10 }}
            disabled={!partPayment || !paymenttype}
          >
            {waitingServer ? (
              <CircularProgress size={15} />
            ) : (
              'Merkkaa osittain maksetuksi'
            )}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPaymentDialog(false)}
            color='primary'
            disabled={waitingServer}
          >
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSendDialog}
        onClose={() => setOpenSendDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle id='form-dialog-title'>{sendTitle}</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            id='invoicetitle'
            label='Otsikko'
            type='text'
            fullWidth
            onChange={(e) => setMessageTitle(e.target.value)}
          />
          <TinyEditor
            value={''}
            onChange={setCoveringnote}
            onClose={setCoveringnote}
            disableSave={true}
          />

          {/* <TextField
            autoFocus
            margin='dense'
            id='note'
            label='Saate (ei pakollinen)'
            type='email'
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            onChange={(e) => setCoveringnote(e.target.value)}
          /> */}
          <Button
            variant='contained'
            size='small'
            onClick={() => sendInvoices(sendtype)}
            style={{ marginRight: 5, marginTop: 10 }}
            endIcon={<SendIcon />}
            disabled={waitingServer}
          >
            Lähetä
          </Button>
          {/* <pre>{coveringnote}</pre> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenSendDialog(false)}
            color='primary'
            disabled={waitingServer}
          >
            Peru
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openBillingDateDialog}
        onClose={() => setOpenBillingDateDialog(false)}
      >
        <DialogTitle id='form-dialog-title'>Aseta laskupäivä</DialogTitle>
        <DialogContent>
          <TextField
            id='billingdate'
            label='Laskupäivä'
            type='date'
            value={
              batchBillingdate
                ? batchBillingdate.format('YYYY-MM-DD')
                : moment.utc().format('YYYY-MM-DD')
            }
            onChange={(e) => handleDateChange(e, 'billingdate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenBillingDateDialog(false)}
            color='primary'
          >
            Peruuta
          </Button>
          <Button
            onClick={() => saveDueAndBillingDate(false)}
            color='primary'
            disabled={!batchBillingdate}
          >
            Tallenna
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDueDateDialog}
        onClose={() => setOpenDueDateDialog(false)}
      >
        <DialogTitle id='form-dialog-title'>Aseta eräpäivä</DialogTitle>
        <DialogContent>
          <TextField
            id='duedate'
            label='Eräpäivä'
            type='date'
            value={
              batchDuedate
                ? batchDuedate.format('YYYY-MM-DD')
                : moment.utc().format('YYYY-MM-DD')
            }
            onChange={(e) => handleDateChange(e, 'duedate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDueDateDialog(false)} color='primary'>
            Peruuta
          </Button>
          <Button
            onClick={() => saveDueAndBillingDate(true)}
            color='primary'
            disabled={!batchDuedate}
          >
            Tallenna
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withFirebase(AggregateTable)