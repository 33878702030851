export const _reservationBenefitTimeCardUISchema = {
  resources: {
    'ui:widget':'checkboxes'
  },
  description: {
    'ui:widget': 'editorWidget',
  },
  confirmationMessage: {
    'ui:widget': 'editorWidget',
  },
  image: {
    'ui:widget': 'imageWidget',
  }
}
export const _reservationBenefitTimeCardClass = {
  name: { type: 'string', title: 'Aikakortin nimi' },
  // price: { type: 'number', title: 'Hinta' },
  time: { type: 'number', title: 'Ajan määrä minuutteina', default: 600 },
  description: { type: 'string', title: 'Kuvaus' },
  vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%', '25,5%'] },
  accounting: { type: 'string', title: 'Kirjanpidontili' },
  variations: {
    title: '',
    description: 'Hintavaihtoehdot',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        name: { type: 'string', title: 'Nimi' },
        price: { type: 'number', title: 'Hinta' },
        productCode: { type: 'string', title: 'Tuotekoodi' },
      },
    },
  },
  // validuntil: { type: 'string', format: 'date', title: 'Voimassaolon viimeinen päivä' },
  availableinshop: {
    type: 'string',
    title: 'Näytetäänkö verkkokaupassa',
    enum: ['true', 'false'],
    enumNames: ['Kyllä', 'Ei'],
    default: 'false',
  },
  // sport: {
  //   type: 'string',
  //   title: 'Laji',
  //   enum: [],
  //   enumNames: [],
  // },
  resources: {
    type: 'array',
    title: 'Resurssit',
    items: {
      type: 'string',
      enum: [],
      enumNames: [],
    },
    uniqueItems: true,
  },
  limits: {
    type: 'object',
    title: 'Rajoitukset',
    properties: {
      maxperday: { type: 'number', title: 'Käyttökertojen maksimimäärä päivässä' },
      maxperweek: { type: 'number', title: 'Käyttökertojen maksimimäärä viikossa' },
      maxpermonth: { type: 'number', title: 'Käyttökertojen maksimimäärä kuukaudessa' },
    },
  },
  quota: {
    type: 'number',
    title: 'Yhtäaikaisvarauksien määrä',
    default: 0,
  },
  tag: {
    type: 'string',
    title: 'Varauksen tunniste',
  },
  validity: {
    type: 'string',
    title: 'Voimassaoloaika',
    enum: ['rolling', 'next', 'fixed', 'permanent'],
    enumNames: ['Rullaava ajanjakso', 'Seuraavaan ajanjaksoon', 'Kiinteä ajanjakso', 'Pysyvä'],
  },
  schedule: {
    type: 'string',
    title: 'Voimassaoloaikataulu',
    enum: [],
    enumNames: [],
  },
  // validity: {
  //   type: 'string',
  //   title: 'Voimassaoloaika',
  //   enum: ['rolling', 'next', 'fixed', 'permanent'],
  //   enumNames: ['Rullaava ajanjakso', 'Seuraavaan ajanjaksoon', 'Kiinteä ajanjakso', 'Pysyvä'],
  // },

  // type: {
  //   type: 'string',
  //   title: 'Sarjakortin tyyppi',
  //   enum: ['time', 'count'],
  //   enumNames: ['Aikapohjainen', 'Kertapohjainen'],
  // },
}
export const _reservationBenefitTimeCardClassDeps = {
  availableinshop: {
    oneOf: [
      {
        properties: {
          availableinshop: {
            enum: ['true'],
          },
          image: { type: 'string', title: 'Verkkokaupan tuotekuva', items: { type: 'object', images: [] }, minaspectratio: 1 },
          confirmationSubject: { type: 'string', title: 'Vahvistusviestin otsikko' },
          confirmationMessage: { type: 'string', title: 'Vahvistusviestin sisältö' },
          // validityperiod: {
          //   type: 'number',
          //   title: 'Voimassaoloaika päivinä ostohetkestä (0 = ei päättymistä)',
          // },
          // validfrom: {
          //   type: 'string',
          //   title: 'Voimassaolo alkaa, jätä tyhjäksi jos alkaa ostohetkestä',
          //   format: 'date',
          // },
          // validuntil: {
          //   type: 'string',
          //   title: 'Voimassaolo päättyy, jos voimassa oloaika on asetettu se kumpi on aiemmin määrää',
          //   format: 'date',
          // },
        }
      },
      {
        properties: {
          availableinshop: {
            enum: ['false'],
          },
        }
      }
    ]
  },
  validity: {
    oneOf: [
      {
        properties: {
          validity: {
            enum: ['rolling'],
          },
          unit: {
            type: 'string',
            title: 'Rullaava ajanjakso',
            enum: ['day', 'week', 'month', 'year'],
            enumNames: ['Päivä', 'Viikko', 'Kuukausi', 'Vuosi'],
          },
          amount: {
            type: 'number',
            title: 'Montako ajanjaksoa ostohetkestä',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['next'],
          },
          endof: {
            type: 'string',
            title: 'Päättyy',
            enum: ['month', 'year'],
            enumNames: ['Kuukauden lopussa', 'Vuoden lopussa'],
          },
          daysahead: {
            type: 'number',
            title: 'Montako päivää ennen aletaan myydä seuraavaa ajanjaksoa. 0 = tästä hetkestä kuluvan ajanjakson loppuun asti.',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['fixed'],
          },
          firstvalid: {
            type: 'string',
            title: 'Ensimmäinen voimassaolopäivä',
            format: 'date',
          },
          lastvalid: {
            type: 'string',
            title: 'Viimeinen voimassaolopäivä',
            format: 'date',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['permanent'],
          },
          firstvalid: {
            type: 'string',
            title: 'Ensimmäinen voimassaolopäivä',
            format: 'date',
          },
        },
      },
    ],
  },

}
