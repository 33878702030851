import React, { Component } from 'react'
import { withFirebase } from '../Fb'
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  ButtonGroup,
  ModalFooter,
  Alert,
  Spinner,
  Badge,
  Form,
  FormGroup,
  Label,
  Input,
  
  // Progress
} from 'reactstrap'
// import Switch from 'react-bootstrap-switch'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import Draggable from 'react-draggable'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip'

import CircularProgress from '@material-ui/core/CircularProgress';

import BugReportIcon from '@material-ui/icons/BugReport';
import { v4 as uuidv4 } from 'uuid'
import JSONPretty from 'react-json-pretty'
import moment from 'moment-timezone'
import 'moment/locale/fi'
import { getReservation } from '../variables'
import algoliasearch from 'algoliasearch'
import Ripples from 'react-ripples'
import Reservation from '../Classes/Reservation'
import rainicon from '../../assets/img/d430.png'
import { Checkbox, IconButton } from '@material-ui/core'
import PersonAndOrganisationSearch from './PersonAndOrganisationSearch'
import QuickDurationSelection from '../Buttons/QuickDurationSelection'
import SaldopaymentDialog from '../Dialogs/SaldopaymentDialog'
import TimeInput from './TimeInput'
import MessageModal from './MessageModal'
import { withAuthentication } from '../Session'
import MatchDetails from '../Details/MatchDetails'

const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
const MAX_RECURRENCY_END_DATE = '2028-12-31'
const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f');
const algoliaindex = algoliaclient.initIndex('falcon_combined');
const algoliaprofile = algoliaclient.initIndex('profile');
const TIMEZONE_OFFSET = moment().tz('Europe/Helsinki').utcOffset()

const PROFILE_FIELDS = ['isblocked', 'ispastpayment', 'isrecurring', 'favoriteSport', 'memberships', 'saldos']

const messageTemplatesLocal = {
  cancelmessage: { subject: 'Vuoron peruutus {SUBJECT_SHORT}', body: 'Hei {PERSON_NAME},\r\n\rPeruutetun varauksen tiedot,\r\n\r\n' +
  'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT}' },
  movemessage: { subject: 'Vuoron siirto {SUBJECT_SHORT}', body: 'Hei {PERSON_NAME},\r\n\rSiirretyn varauksen tiedot,\r\n\r\n' +
  'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT} \r\n\r\nTervetuloa!' },
  reservationmessage: { subject: 'Varausvahvistus {SUBJECT_SHORT}', body: 'Hei {PERSON_NAME},\r\n\r\nVahvistetun varauksen tiedot,\r\n\r\n' +
  'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT} \r\n\r\nHinta	{RESERVATION_PRICE},\r\n\r\nTervetuloa!' },
}

const isTooLateForPartner = (reservation) => {
  if (reservation && reservation.resourcegroups && reservation.resourcegroups[0] && reservation.resourcegroups[0].start) {
    const start = moment.utc(reservation.resourcegroups[0].start)
    const now = moment.utc()
    const diff = start.diff(now, 'hours')
    console.log('isTooLateForPartner', diff)
    if (diff < 6) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const rightToReserve = (reservation, role, uid) => {
  if (role === 'PARTNER' || role === 'COOPERATIVE') {
    if (reservation && reservation.persons && reservation.persons.payers && reservation.persons.payers.length > 0) {
      const ok = reservation.persons.payers.find((p) => {
        const isContact = p.contacts ? p.contacts.find((p) => p.id === uid) : null
        const isUser = p.users ? p.users.find((p) => p.id === uid) : null
        const isOwn = p.id === uid
        if (isContact || isUser || isOwn) return true
        return false
      })
      if (!ok) return false
    } 
    return true
  }
  return true
}

// const courtGroupStyles = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
// };
// const courtGroupBadgeStyles = {
//   backgroundColor: '#EBECF0',
//   borderRadius: '2em',
//   color: '#172B4D',
//   display: 'inline-block',
//   fontSize: 12,
//   fontWeight: 'normal',
//   lineHeight: '1',
//   minWidth: 1,
//   padding: '0.16666666666667em 0.5em',
//   textAlign: 'center',
// };

// const formatCourtGroupLabel = data => (
//   <div style={courtGroupStyles}>
//     <span>{data.label}</span>
//     <span style={courtGroupBadgeStyles}>{data.options.length}</span>
//   </div>
// )

// CLASS

const paymenttypes = [
  { value: 'cash', label: 'Käteinen' },
  { value: 'sportmoney-smartum', label: 'Smartum' },
  { value: 'sportmoney-epassi', label: 'ePassi' },
  { value: 'sportmoney-edenred', label: 'Edenred' },
  { value: 'gift-certificate', label: 'Lahjakortti' },
  { value: 'personelbenefit', label: 'Henkilökuntaetu' },
  { value: 'manualsaldo', label: 'Manuaalinen saldomaksu' },
  { value: 'invoice', label: 'Manuaalinen lasku' },
]

const initialState = {
  reservation: null,
  selectedResource: null,
  reservationAlert: false,
  recurrencyEnd: null,
  recurrencyInterval: null,
  contract: null,
  copyInProgress: false,
  showCancelAllConfirm: false,
  showOverbookingConfirm: false,
  showMessageConfirmationModal: false, 
  savePressed: false,
  checkingConflicts: false,
  noConflicts: false,
  conflicts: null,
  recurrencyParent: null,
  courtlocked: true,
  slotpricings: null,
  loadingmemberships: false,
  messageBody: null,
  messagesubject: null,
  saveAfterSend: false,
  paymentnote: null,
  savedAfterChange: true,
  showUnsavedConfirmation: false,
  showAddNoteRow: false,
  notetext: null,
  split: null, 
  showSplitDialog: false,
  possiblesplits: [],
  sendMessagePressed: false,
  showRecurringDetails: false,
  checkingPrice: false,
  messagetemplates: [],
  showRainCancellationDialog: false,
  recurrencyStartValid: true,
  recurrencyEndValid: true,
  sendConfirmationWhenSave: false,
  noteforall: false,
  showallnotes: false,
  splitPrices: null,
  userprofile: null,
  showSaldopaymentDialog: false,
  pricingTag: null,
  showManualAccessCode: false,
  paymenttypes: paymenttypes,
}

// const clearedState =
// {
//   reservation: null,
//   selectedResource: null,
//   reservationAlert: false,
//   recurrencyEnd: null,
//   recurrencyInterval: null,
//   contract: null,
//   showCancelAllConfirm: false,
//   savePressed: false,
//   checkingConflicts: false,
//   noConflicts: false,
//   conflicts: null
// }

const sortObjectByKeys = (o) => {
  // eslint-disable-next-line no-sequences
  return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
}

const cloneAndClean = (object, parametersToClear) => {
  const filledShell = JSON.parse(JSON.stringify({ ...getReservation(), ...object }))
  for (let i = 0; i < parametersToClear.length; i++) {
    delete filledShell[parametersToClear[i]]
  }
  return filledShell
}

class ReservationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      isOpen: props.isOpen,
      tags: this.props.tags || [],
      showMessageSendingDialog: false,
      resourceOptions: this.props.customerdata.resources,
      featureOptions: this.props.customerdata.features,
    }
    this.pricingtimer = null
  }

  unsubscribeConcurrentUpdates = null
  
  allOrganisationsDone = (data) => {
    this.setState({ organisations: data }, this.initOrganisationSearch)
  }

  initOrganisationSearch = () => {
    const data = []
    for (let i = 0; i < this.state.organisations.length; i++) {
      const _organisation = this.state.organisations[i]
      data.push({ ..._organisation, id: _organisation.id })
    }
    this.props.firebase.updateCombinedCache(data, null)
  }

  async componentDidMount() {
    // this.props.firebase.getResourses().then((_resources) => {
    //   let _d = _resources.docs.map((_r) => {
    //     return { ..._r.data(), id: _r.id }
    //   })
    //   _d.sort((a, b) => {
    //     if (a.displayName < b.displayName) {
    //       return -1
    //     }
    //     if (a.displayName > b.displayName) {
    //       return 1
    //     }
    //     return 0
    //   })
    //   this.setState({ resourceOptions: _d })
    // })

    this.props.firebase.getConfig('paymenttypes').onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({ paymenttypes: doc.data().types })
      }
    })

    this.unsubscribeMessageTemplates = this.props.firebase.getMessageTemplates().onSnapshot((doc) => {
      if (doc.exists) {
        console.log('messageTemplates', doc.data().confirmations) 
        this.setState({
          messagetemplates: doc.data().confirmations,
        })
      }
    })
    if (!this.props.firebase.isCacheValid()) {
      this.unsubscribeOrganisations = this.props.firebase.listenOrganisations().onSnapshot((snapshot) => {
        const data = []
        let index = snapshot.docs.length
        snapshot.docs.map(async (doc) => {
          let toAdd = null
          if (doc.data().state === 'deleted') {
            index = index - 1
            if (index === 0) {
              this.allOrganisationsDone(data)
            }
            return
          }
          if (doc.data().organisation) {
            let organisation = await doc.data().organisation.get()
            toAdd = { ...organisation.data(), ...doc.data(), id: organisation.id }
            // data.push({ ...organisation.data(), ...doc.data(), id: organisation.id })
          } else {
            toAdd = { ...doc.data(), id: doc.id }
            // data.push({ ...doc.data(), id: doc.id })
          }
          if (toAdd?.contacts?.[0]?.id) {
            data.push(toAdd)
          }
          index = index - 1
          if (index === 0) {
            this.allOrganisationsDone(data)
          }
        })
      })
    }
    if (!this.props.tags) {
      const _cats = await this.props.firebase.getColorCategories().get()
      if (_cats.exists) {
        this.setState({
          tags: _cats.data().categories.filter((c) => c.type === 'tag' && c.enabled),
        })
      } else {
        // console.log('tags empty')
      }
    } else {
      // console.log('tags not get')
    }
  
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.setState(
          {
            isOpen: this.props.isOpen,
            selectedResource: this.props.resource,
            tags: this.props.tags,
            showStateChangeAlert: false,
            savingSaldoPayment: false
          },
          this.initAndUpdateReservation
        )
      } else {
        this.setState({
          isOpen: this.props.isOpen,
          selectedResource: null,
          resource: null,
          reservation: null,
          contract: null,
          showStateChangeAlert: false,
        })
      }
    } else {
      // console.log('do nothing')
    }

    if (this.props.customerdata.resources !== prevProps.customerdata.resources) {
      console.log('resources updated', this.props.customerdata.resources)
      this.setState({
        resourceOptions: this.props.customerdata.resources
      })
    }
    if (this.props.customerdata.features !== prevProps.customerdata.features) {
      console.log('features updated', this.props.customerdata.features)
      this.setState({
        featureOptions: this.props.customerdata.features
      })
    }

  }

  componentWillUnmount() {
    if (this.unsubscribeMessageTemplates) {
      this.unsubscribeMessageTemplates()
    }
    if (this.unsubscribeConcurrentUpdates) {
      this.unsubscribeConcurrentUpdates()
    }
    if (this.unsubscribeMessageTemplatesToo) {
      this.unsubscribeMessageTemplatesToo()
    }
    if (this.unsubscribeOrganisations) {
      this.unsubscribeOrganisations()
    }
  }

  updateProfile = async (id) => {
    const customerAttributes = PROFILE_FIELDS.map((f) => this.props.firebase.customerid + '.' + f)
    let profile = { firstname: '', lastname: '', email: '', phone: '', streetaddress: '', yob: '', zip: '', city: '', ...customerAttributes }
    try {
      profile = await algoliaprofile.getObject(id, {
        attributesToRetrieve: ['firstname', 'lastname', 'email', 'phone', 'streetaddress', 'yob', 'zip', 'city', ...customerAttributes]
      })
      const _profile = { ...profile, id: profile.objectID }
      console.log('updateProfile', _profile)
      for (let profileField of PROFILE_FIELDS) {
        if (_profile[this.props.firebase.customerid] && _profile[this.props.firebase.customerid][profileField]) {
          _profile[profileField] = _profile[this.props.firebase.customerid][profileField]
          delete _profile[this.props.firebase.customerid][profileField]
        }
      }
      this.setState({ userprofile: _profile })
    } catch (error) {
      console.log(error)
      this.setState({ userprofile: null })
    }
  }

  onCopyPressed = () => {
    const occurencyDate = moment.utc(this.state.selectedResource.start)
    const filledShell = cloneAndClean(this.state.reservation, ['id', 'log', 'contractid', 'contracttype', 'contractsigningtype', 'creatorId', 'payments', 'updated', 'notes', 'cashierid'])
    if (filledShell.resourcegroups && filledShell.resourcegroups[0].resources) filledShell.resourcegroups[0].resources = []
    if (filledShell.resourcegroups && filledShell.resourcegroups[0].cost) filledShell.resourcegroups[0].cost = 0
    const start = moment.utc(filledShell.resourcegroups[0].start).year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
    const end = moment.utc(filledShell.resourcegroups[0].end).year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
    filledShell.resourcegroups[0].start = start.format(TIMEFORMAT)
    filledShell.resourcegroups[0].end = end.format(TIMEFORMAT)

    this.setState({ reservation: filledShell, contract: null, copyInProgress: true })
    setTimeout(() => {
      this.setState({ copyInProgress: false })
    }, 700)
  }

  // MOVE / COPY

  onRainCancellationPressed = () => {
    this.setState({ showRainCancellationDialog: true })
  }

  storeRainCancellationReplacement = async (filledShell) => {
    await this.onReservationSave()
    this.clearState()
    filledShell.persons.payers[0] = {
      "email": "suljettu@falcon-app.mm",
      "firstname": "Suljettu",
      "lastname": "Suljettu",
      "id": "ag_EWhgJL0UdZFVn4dGmEp8_suljettu@falcon-app_dot_mm",
      "share": 1
    }
    this.setState({ reservation: filledShell, contract: null, showRainCancellationDialog: false }, this.onReservationSave)
    this.handleClose()
  }

  storeRainCancellation = () => {
    // console.log('RainCancellationPressed')
    const v = 'cancelled'
    let _r = { ...this.state.reservation }
    const filledShell = cloneAndClean(this.state.reservation, ['id', 'log', 'contractid', 'contracttype', 'contractsigningtype', 'creatorId', 'payments', 'updated', 'notes', 'usernotes', 'cashierid'])
    if (_r.isrecurring) {
      if (this.state.selectedResource) {
        const thisDate = moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
        const occurencyObject = this.state.reservation.recurrencyDates.find((x) => x.date === thisDate)
        occurencyObject.state = v
        if (occurencyObject) {
          let updated = _r.recurrencyDates.map((r) => {
            if (r.date === occurencyObject.date) {
              return occurencyObject
            } else {
              return r
            }
          })
          _r.recurrencyDates = updated
        }
      }
    } else {
      _r.state = v
    }
    filledShell.resourcegroups[0].cost = 0
    filledShell.resourcegroups[0].overridecost = 0
    filledShell.recurrencyDates = []
    filledShell.recurrencyEndDate = ''
    filledShell.recurrencyStartDate = ''
    filledShell.isrecurring = false
    filledShell.title = 'Sadeperuutus'
    const occurencyDate = moment.utc(this.state.selectedResource.start)
    const start = moment.utc(filledShell.resourcegroups[0].start).year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
    const end = moment.utc(filledShell.resourcegroups[0].end).year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
    filledShell.resourcegroups[0].start = start.format(TIMEFORMAT)
    filledShell.resourcegroups[0].end = end.format(TIMEFORMAT)
    this.setState({ reservation: _r }, () => this.storeRainCancellationReplacement(filledShell))
  }


  onSplitPressed = () => {
    this.setState({ showSplitDialog: true, splitPrices: [0, 0], splitPriceErrors: null, splitSuccestions: null, split: null })
  }

  onSplitSaved = async () => {
    if (this.state.split) {
      const occurencyDate = moment.utc(this.state.reservation.resourcegroups[0].start)
      const filledShell = cloneAndClean(this.state.reservation, ['id', 'log', 'contractid', 'contracttype', 'contractsigningtype', 'payments', 'updated', 'recurrencyStartDate', 'notes', 'cashierid'])
      const reservation = { ...this.state.reservation }
      reservation.resourcegroups[0].cost = this.state.splitPrices[0]
      reservation.resourcegroups[0].overridecost = this.state.splitPrices[0]
      const splitmoment = moment.utc(this.state.split, 'HH:mm')
      splitmoment.year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
      reservation.resourcegroups[0].end = splitmoment.format(TIMEFORMAT)
      filledShell.resourcegroups[0].start = splitmoment.format(TIMEFORMAT)
      filledShell.resourcegroups[0].cost = this.state.splitPrices[1]
      filledShell.resourcegroups[0].overridecost = this.state.splitPrices[1]
      await this.props.firebase.updateReservation(reservation)
      await this.props.firebase.createReservation(filledShell)
      this.toggleClose()
    }
  }

  handleSplitPriceChange = (e, index) => {
    const value = e.target.value
    const splitPrices = this.state.splitPrices ? [...this.state.splitPrices] : [null, null]
    splitPrices[index] = value
    this.setState({ splitPrices })
  }

  onMovePressed = () => {
    const occurencyDate = moment.utc(this.state.selectedResource.start)
    // const _shell = getReservation()
    const _r = JSON.parse(JSON.stringify({ ...this.state.reservation }))
    // find this occurency payments
    const odString = occurencyDate.format('YYYY-MM-DD')
    const _copiedPayments = _r.payments ? _r.payments.filter((p) => p.occurency === odString ) : null
    const filledShell = cloneAndClean(this.state.reservation, ['id', 'log', 'contractid', 'contracttype', 'contractsigningtype', 'payments', 'updated', 'recurrencyStartDate', 'notes', 'cashierid'])
    filledShell.parentid = this.state.reservation.id
    filledShell.id = uuidv4()
    filledShell.recurrencyDates = []
    filledShell.isrecurring = false
    filledShell.recurrencyEndDate = ''
    if (_copiedPayments && _copiedPayments.length > 0) {
      filledShell.payments = _copiedPayments
    }
    const start = moment.utc(filledShell.resourcegroups[0].start).year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
    const end = moment.utc(filledShell.resourcegroups[0].end).year(occurencyDate.year()).month(occurencyDate.month()).date(occurencyDate.date())
    filledShell.resourcegroups[0].start = start.format(TIMEFORMAT)
    filledShell.resourcegroups[0].end = end.format(TIMEFORMAT)

    const occurencyObject = { state: 'moved', date: occurencyDate.format('YYYY-MM-DD'), id: filledShell.id }
    let updated = _r.recurrencyDates.map((r) => {
      if (r.date === occurencyObject.date) {
        return occurencyObject
      } else {
        return r
      }
    })
    _r.recurrencyDates = updated

    this.setState({ reservation: filledShell, recurrencyParent: _r, contract: null })
  }

  // messages
  handleMessageBodyChange = (e) => {
    this.setState({ messageBody: e.target.value })
  }

  handleMessageSubjectChange = (e) => {
    this.setState({ messageSubject: e.target.value })
  }

  fillTemplate = async (template) => {
    const _template = JSON.parse(JSON.stringify(template))
    console.log('fillTemplate', this.state.reservation )
    const reservation = new Reservation({ ...this.state.reservation })
    let primaryContact = reservation.getFirstPayerSimple()

    let personalaccesscode = ''
    if (primaryContact) {
      const pinresponse = await this.props.firebase.getPersonPin(primaryContact.id)
      if (pinresponse && pinresponse.data) {
        personalaccesscode = pinresponse.data
      }
    }
    const selectedOccurency = moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
    // const reservationTimeTitle =
    // reservation.getStartMoment().format('dddd D.M.YYYY [klo] H:mm') +
    // ' - ' +
    //   reservation.getEndMoment().format('H:mm')
    
    const reservationTimeTitle =
      reservation.getStartMoment(selectedOccurency).format('dddd D.M.YYYY [klo] H:mm') +
      ' - ' +
      reservation.getEndMoment().format('H:mm')
    const hasHtml = _template.htmlbody ? true : false
    let body = _template.body
    if (hasHtml) {
      delete _template.body
      body = _template.htmlbody
    }

    const _body = body
      .replace('{PERSON_NAME}', primaryContact.firstname + ' ' + primaryContact.lastname)
      .replace('{RESERVATION_DATE_TIME}', reservationTimeTitle)
      .replace('{RESERVATION_VENUE}', reservation.getVenue())
      .replace('{RESERVATION_SPORT}', reservation.getSport())
      .replace('{RESERVATION_COURT}', reservation.getResource().displayName)
      .replace('{RESERVATION_CODE}', personalaccesscode)
      .replace('{RESERVATION_PRICE}', reservation.getPaymentStatus().cost + ' €')
      _template.subject = _template.subject.replace('{SUBJECT_SHORT}', reservation.getShortShortTitle(reservation.getStartMoment(selectedOccurency).format('YYYY-MM-DD')))
    // _template.subject = _template.subject.replace('{SUBJECT_SHORT}', reservation.getShortShortTitle(oc.format('YYYY-MM-DD')))
    if (hasHtml) {
      _template.htmlbody = _body
    } else {
      _template.body = _body
    }
    return Promise.resolve(_template)
  }

  sendMessage = async () => {
    this.setState({ sendMessagePressed: true })
    const reservation = new Reservation({ ...this.state.reservation })
    const { email, id } = reservation.getFirstPayerSimple()
    // console.log('SendMessage', email, this.state.messageSubject, this.state.messageBody)
    const tags = ['reservation']
    if(this.state.saveAfterSend) tags.push('duringsave')
    await this.props.firebase.sendMessage(this.state.messageBody, this.state.messageSubject, email, id, tags)
    // console.log(response)
    if (this.state.saveAfterSend) {
      // console.log('Save reservation')
      await this.onReservationSave()
    }
    this.setState({ sendMessagePressed: false })
    this.toggleMessage()
  }

  sendMessage2 = async (message) => {
    console.log('SendMessage2', message)
    this.setState({ sendMessagePressed: true })
    const reservation = new Reservation({ ...this.state.reservation })
    const { email, id } = reservation.getFirstPayerSimple()
    const tags = ['reservation', 'testing']
    if (this.state.saveAfterSend) tags.push('duringsave')
    console.log('SendMessage2', message.body, message.subject, email, id, tags, message.htmlbody)
    await this.props.firebase.sendMessage(message.body, message.subject, email, id, tags, message.htmlbody, 'reservations', reservation.getId())
    if (this.state.saveAfterSend) {
      // console.log('Save reservation')
      await this.onReservationSave()
    }
    this.setState({ sendMessagePressed: false })
    this.toggleMessage()
  }
  // RECURRENT

  onRecurringChange = () => {
    let _r = { ...this.state.reservation }
    _r.isrecurring = !_r.isrecurring
    let showRecurringDetails = false
    if (_r.isrecurring) {
      showRecurringDetails = true
    }
    if (_r && !_r.recurrencyStartDate) {
      _r.recurrencyStartDate = this.state.selectedResource ? moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD') : null
    }
    this.setState({ reservation: _r, savedAfterChange: false, showRecurringDetails })
  }

  onSeasonBillingChange = () => {
    let _r = { ...this.state.reservation }
    _r.seasonbilling = !_r.seasonbilling
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  // STATE
  toggleClose = () => {
    if (!this.state.savedAfterChange) {
      this.setState({ showUnsavedConfirmation: true })
    } else {
      this.clearState()
      this.handleClose()
    }
  } 

  toggleReservationAlert = () => this.setState({ reservationAlert: !this.state.reservationAlert })

  clearState = () => {
    this.setState({ ...initialState })
  }

  onReservationSave = async (event) => {
    if (event) event.preventDefault()
    this.setState({ savePressed: true })
    const now = moment().unix()
    const reservation = { ...this.state.reservation }
    if (this.state.pricingTag) {
      if (reservation.tags) {
        reservation.tags.push(this.state.pricingTag)
      } else {
        reservation.tags = [this.state.pricingTag]
      }
    }
    if (reservation.id) {
      if (reservation.parentid && !reservation.log) {
        reservation.log = []
        reservation.log.push({
          timestamp: now,
          type: 'created',
          user: this.props.firebase.auth.currentUser.uid,
          email: this.props.firebase.auth.currentUser.email
        })
      }

      if (reservation.parentid) reservation.isrecurring = false
      // console.log(reservation)
      if (typeof reservation.recurrencyDates[0] !== 'object') {
        reservation.recurrencyDates = reservation.recurrencyDates.map((r) => ({ date: r, state: 'reserved' }))
      }
      if (reservation.state === 'cancelled') {
        for (let j = 0; j < reservation.recurrencyDates.length; j++) {
          if (moment.utc().isBefore(moment.utc(reservation.recurrencyDates[j].date)) && reservation.recurrencyDates[j].state !== 'moved') {
            reservation.recurrencyDates[j].state = 'cancelled'
          }
        }
        // update contract
        if (reservation.contractid) {
          await this.props.firebase.updateContract({ status: 'cancelled', id: reservation.contractid })
        }
      } else {
        let contract = null
        if (reservation.contracttype && !reservation.contractid && reservation.contracttype !== 'no-contract') {
          let name = reservation.persons.payers[0].name
            ? reservation.persons.payers[0].name
            : reservation.persons.payers[0].firstname + ' ' + reservation.persons.payers[0].lastname
          contract = {
            type: reservation.contracttype,
            signingtype: reservation.contractsigningtype ? reservation.contractsigningtype : 'digital',
            displayName: 'Vakiovuorosopimus',
            userid: this.props.firebase.auth.currentUser.uid,
            user: name,
            status: 'created',
          }
          if (reservation.contracttype === 'standardshift-fixedterm') {
            contract.validuntil = reservation.recurrencyEndDate
          }
          let contractDoc = await this.props.firebase.createContract(contract)
          reservation.contractid = contractDoc.id
        }
      }

      await this.props.firebase.updateReservation(reservation)
      if (this.state.recurrencyParent) {
        await this.props.firebase.updateReservation(this.state.recurrencyParent)
      }
      this.clearState()
      this.handleClose()
    } else {
      reservation.log = []
      reservation.log.push({
        timestamp: now,
        type: 'created',
        user: this.props.firebase.auth.currentUser.uid,
        email: this.props.firebase.auth.currentUser.email
      })
      // console.log(reservation)
      // create contract object
      let contract = null
      if (reservation.contracttype && !reservation.contractid && reservation.contracttype !== 'no-contract') {
        let name = reservation.persons.payers[0].name
          ? reservation.persons.payers[0].name
          : reservation.persons.payers[0].firstname + ' ' + reservation.persons.payers[0].lastname
        contract = {
          type: reservation.contracttype,
          signingtype: reservation.contractsigningtype ? reservation.contractsigningtype : 'digital',
          displayName: 'Vakiovuorosopimus',
          userid: this.props.firebase.auth.currentUser.uid,
          user: name,
          status: 'created',
        }
        if (reservation.contracttype === 'standardshift-fixedterm') {
          contract.validuntil = reservation.recurrencyEndDate
        }
        let contractDoc = await this.props.firebase.createContract(contract)
        reservation.contractid = contractDoc.id
      }
      try {
        await this.props.firebase.createReservation(reservation)
        this.clearState()
        this.handleClose()
      } catch (error) {
        alert(error)
      }
    }
  }

  listenReservationChanges = () => {
    if (this.state.reservation && this.state.reservation.id && !this.unsubscribeConcurrentUpdates) {
      this.unsubscribeConcurrentUpdates = this.props.firebase.listenReservation(this.state.reservation.id).onSnapshot((doc) => {
        if (doc.exists && !this.state.savePressed) {
          const _r = doc.data()
          if (this.state.reservation && _r.updated && this.state.reservation.updated && typeof _r.updated.toDate === 'function' && typeof this.state.reservation.updated.toDate === 'function') {
            if (_r.updated.toDate().getTime() !== this.state.reservation.updated.toDate().getTime() && !this.state.savingSaldoPayment) {
              alert('Varausta on muokattu toisaalta. Sulje tämä ikkuna ja avaa se uudelleen.')
              this.clearState()
              this.handleClose()
            }
          }
        }
      })
    }
  }

  startListenAndUpdatePrice = () => {
    this.listenReservationChanges()
    this.updatePrice()
  }

  initAndUpdateReservation = async () => {
    let _ns = { reservation: null, defaultStart: null, defaultEnd: null }
    let _r = {}
    let isNew = false

    this.unsubscribeMessageTemplatesToo = this.props.firebase.getMessageTemplates().onSnapshot((doc) => {
      if (doc.exists) {
        console.log('messageTemplates', doc.data().confirmations)
        this.setState({
          messagetemplates: doc.data().confirmations,
        })
      }
    })

    if (!this.state.selectedResource.id) {
      _r = getReservation()
      isNew = true
      _r.resourcegroups[0].start = this.state.selectedResource.start
      _r.resourcegroups[0].end = this.state.selectedResource.end
      if (this.state.resourceOptions) {
        let resOptions = this.state.resourceOptions.filter((r) => r.id === this.state.selectedResource.resourceid)
        _r.resourcegroups[0].resources = resOptions
      } else {
        _r.resourcegroups[0].resources[0].id = this.state.selectedResource.resourceid
        _r.resourcegroups[0].resources[0].displayName = 'Select court again'
      }
      _ns.defaultStart = this.state.selectedResource.start
      _ns.defaultEnd = this.state.selectedResource.end
    } else {
      _r = this.state.selectedResource.data
      _ns.defaultStart = this.state.selectedResource.data.resourcegroups[0].start
      _ns.defaultEnd = this.state.selectedResource.data.resourcegroups[0].end
      if (_r.contractid) {
        let contract = await this.props.firebase.getContract(_r.contractid)
        _ns.contract = contract.data()
      } else {
        _ns.contract = null
      }

      // convert old recurrency format
      // console.log('recurrencyType', typeof _r.recurrencyDates[0])
      if (typeof _r.recurrencyDates[0] !== 'object') {
        _r.recurrencyDates = _r.recurrencyDates.map((r) => ({ date: r, state: 'reserved' }))
      }
    }
    if (_r.state === "" || _r.state === "confirmed" ||  _r.state === "unconfirmed") {
      _r.state = 'reserved'
    }

    const res = new Reservation({ ..._r })
    if (!res.isRecurrent() && res.getStartMoment().isBefore(res.getEndMoment())) {
      const splits = []
      const splittime = moment(res.getStartMoment()).add(30, 'minutes')
      while (splittime.isBefore(res.getEndMoment())) {
        splits.push({ value: splittime.format('HH:mm'), label: splittime.format('HH:mm') })
        splittime.add(30, 'minutes')
      }
      if (splits.length > 0) {
        _ns.possiblesplits = splits
      }
    }

    _ns.reservation = _r
    _ns.conflicts = null
    _ns.isconflict = false
    _ns.noConflicts = true
    _ns.showMessageSendingDialog = true
    
    
    if (isNew) {
      this.setState(_ns, this.updatePriceAndConflicts)
    } else {
      this.setState(_ns, this.startListenAndUpdatePrice)
      // this.setState(_ns, this.updatePrice)
      if (_r.persons && _r.persons.payers && _r.persons.payers[0] && _r.persons.payers[0].id) {
        let personid = _r.persons.payers[0].id
        this.setState({ loadingmemberships: true })
        this.props.firebase.getPersonMemberships(personid, true).then((result) => {
          this.setState({ payermemberships: result.data, loadingmemberships: false })
        })
        this.updateProfile(personid)
      }
    }
  }

  setReservationProperty = (property, value, propsUpdate) => {
    let _r
    if (!this.state.reservation) {
      _r = getReservation()
    } else {
      _r = { ...this.state.reservation }
    }
    _r[property] = value
    this.setState({ reservation: _r })
  }

  // INPUTS

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true
    }
    return false
  }

  verifyNumber = (value) => {
    var numberRex = new RegExp('^[0-9]+$')
    if (numberRex.test(value)) {
      return true
    }
    return false
  }

  validate = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' })
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' })
        }
        break
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' })
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' })
        }
        break
      default:
        break
    }
    this.setReservationProperty(stateName, event.target.value)
  }

  handleSwitch = (elem, switchstate) => {
    this.setReservationProperty(elem.props.name, switchstate)
  }

  

  isDurationValid = () => {
    let _r = { ...this.state.reservation }
    const start = moment.utc(_r.resourcegroups[0].start)
    const end = moment.utc(_r.resourcegroups[0].end)
    return start.isBefore(end)
  }

  getDuration = () => {
    let _r = { ...this.state.reservation }
    const start = moment.utc(_r.resourcegroups[0].start)
    const end = moment.utc(_r.resourcegroups[0].end)
    const duration = moment.duration(end.diff(start))
    return duration.asMinutes()
  }

  updatePriceAndConflicts = (force) => {
    if (this.isDurationValid()) {
      this.updatePrice(force)
      this.setState({ checkingPrice: true, checkingConflicts: true }, this.updateConflicts)
    }
  }

  updatePrice = (force) => {
    if (this.isDurationValid()) {
      let _r = { ...this.state.reservation }
      // console.log('updatePrice', _r.resourcegroups)
      if (_r.resourcegroups && _r.resourcegroups[0] && _r.resourcegroups[0].resources && _r.resourcegroups[0].resources[0]) {
        const slot = {
          start: moment.utc(_r.resourcegroups[0].start).format('YYYY-MM-DD HH:mm'),
          end: moment.utc(_r.resourcegroups[0].end).format('YYYY-MM-DD HH:mm'),
          resourceId: _r.resourcegroups[0].resources[0].id,
        }
        this.props.firebase.getReservationPrices(slot, null).then((response) => {
          let slotpricings = null
          console.log('getReservationPrices', response.data)
          if (response.data && response.data.prices) {
            let _prices = response.data.prices
            if (this.props.profile.role === 'PARTNER' || this.props.profile.role === 'COOPERATIVE') {
              _prices = response.data.prices.filter((p) => p.pricing === 'general')
            }
            slotpricings = _prices.map((p) => {
              p.price = p.price.toFixed(2)
              if (p.price - Math.floor(p.price) === 0) {
                p.price = Math.floor(p.price)
              } 
              return p
            })

            const general = response.data.prices.find((x) => x.pricing === 'general')
            if (general) {
              if (_r.resourcegroups[0].cost <= 0 || force) {
                _r.resourcegroups[0].cost = general.price
              }
            }
          }
          this.setState({ checkingPrice: false, reservation: _r, slotpricings })
        })
      }
    }
  }

  getSplitPrices = (splits) => {
    return Promise.all(splits.map((split) => {
      return this.props.firebase.getReservationPrices(split, null)
    }))
  }

  handlePayer = (event, value) => {
    let _r = this.state.reservation
    if (_r && value) {
      let divider = value.length
      // _r.persons.payers = value.map((p) => { return { person: p, share: 1 / divider }})
      let personid = null
      _r.persons.payers = value.map((p, i) => {
        if (i === 0) personid = p.id
        return { ...p, share: 1 / divider }
      })
      this.setState({ reservation: _r, savedAfterChange: false }, this.updatePrice)
      if (personid) {
        this.setState({ loadingmemberships: true })
        this.props.firebase.getPersonMemberships(personid, true).then((result) => { this.setState({ payermemberships: result.data, loadingmemberships: false }) })
        this.updateProfile(personid)
      }
    }
  }

  handleOwner = (event, value) => {
    let _r = this.state.reservation
    if (_r && value) {
      _r.persons.owner = value
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }

  handleParticipant = (event, value) => {
    let _r = this.state.reservation
    if (_r && value) {
      _r.persons.participants = value
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }


  handleResourceGroup = (event, value) => {
    let _r = { ...this.state.reservation }
    if (_r && value) {
      _r.resourcegroups[0].resources = value
      if (_r.resourcegroups[0].resources[0]) {
        this.setState({ reservation: _r, savedAfterChange: false }, this.updatePriceAndConflicts)
      } else {
        this.setState({ reservation: _r, savedAfterChange: false })
      }
    }
  }

  handleOptionLabel = (opt) => {
    console.log()
    if (opt.businessId) {
      return opt.name
    } else {
      return opt.firstname && opt.lastname ? opt.firstname + ' ' + opt.lastname : ''
    }
  }

  handleResourceOptionLabel = (opt) => {
    return opt.displayName ? opt.displayName : ''
  }

  handleTags = (event, value) => {
    // console.log(value)
    let _r = this.state.reservation
    if (_r && value) {
      _r.tags = value
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }

  onDismissAlert = () => {
    this.setState({ showStateChangeAlert: false })
  }

  updateConflicts = () => {
    // console.log('updateConf')
    let _r = { ...this.state.reservation }
    const slots = []
    if (_r.resourcegroups && _r.resourcegroups[0] && _r.resourcegroups[0].resources && _r.resourcegroups[0].resources[0]) {
      if (_r.recurrencyDates && _r.recurrencyDates.length > 0) {
        let starttime = moment.utc(_r.resourcegroups[0].start)
        let endtime = moment.utc(_r.resourcegroups[0].end)
        for (let i = 0; i < _r.recurrencyDates.length; i++) {
          slots.push({
            start: moment.utc(_r.recurrencyDates[i].date).hour(starttime.hour()).minutes(starttime.minutes()).format('YYYY-MM-DD HH:mm'),
            end: moment.utc(_r.recurrencyDates[i].date).hour(endtime.hour()).minutes(endtime.minutes()).format('YYYY-MM-DD HH:mm'),
            resourceid: _r.resourcegroups[0].resources[0].id
          })
        }
      } else {
        slots.push({
          start: moment.utc(_r.resourcegroups[0].start).format('YYYY-MM-DD HH:mm'),
          end: moment.utc(_r.resourcegroups[0].end).format('YYYY-MM-DD HH:mm'),
          resourceid: _r.resourcegroups[0].resources[0].id,
        })
        // console.log(slots)
      }

      this.props.firebase.isConflict(slots).then((result) => {
        // console.log('get result from conflict', result)
        if (result.error) {
          console.log('error', result.error)
        } else {
          const conflicts = result.filter((c) => c.isconflict)
          if (conflicts.length === 0) {
            this.setState({ conflicts: null, checkingConflicts: false, noConflicts: true })
          } else {
            this.setState({ conflicts, checkingConflicts: false, noConflicts: false })
          }
        }
      })

      // this.props.firebase.isConflictv2(slots).then((result) => {
      //   // console.log('get result from conflict', result)
      //   if (result.error) {
      //     console.log('error', result.error)
      //   } else {
      //     const conflicts = result.filter((c) => c.isconflict).filter((x) => x.collision && x.collision.indexOf(this.state.reservation.id) === -1)
      //     if (conflicts.length === 0) {
      //       this.setState({ conflicts: null, checkingConflicts: false, noConflicts: true })
      //     } else {
      //       this.setState({ conflicts, checkingConflicts: false, noConflicts: false })
      //     }
      //   }
      // })
    }
  }

  setReservationDate = (dateString) => {
    const selectedDate = moment.utc(dateString)
    if (selectedDate.isValid()) {
      const _r = { ...this.state.reservation }
      let _resourcegroups = _r.resourcegroups.map((r) => {
        // if (this.state.selectedResource.resourceId === r.resource) { // TODO: remove if all updated same time
        let _rg = { ...r }
        let _starttime = moment.utc(r['start'])
        let _endtime = moment.utc(r['end'])
        _starttime.year(selectedDate.year()).month(selectedDate.month()).date(selectedDate.date())
        _endtime.year(selectedDate.year()).month(selectedDate.month()).date(selectedDate.date())
        _rg.start = _starttime.format(TIMEFORMAT)
        _rg.end = _endtime.format(TIMEFORMAT)
        // }
        return _rg
      })
      _r.resourcegroups = _resourcegroups
      const _selectedResource = { ...this.state.selectedResource }
      _selectedResource.start = _r.resourcegroups[0].start
      _selectedResource.end = _r.resourcegroups[0].end

      this.setState({ reservation: _r, savedAfterChange: false, selectedResource: _selectedResource }, () => this.updatePriceAndConflicts(true))
    }
  }


  setReservationTime = (prop, value) => {
    let _r = this.state.reservation
    if (!value) return
    clearTimeout(this.pricingtimer)
    let [_hour, _minute] = value.split(':')
    let _time = moment.utc(_r.resourcegroups[0][prop])
    // let _time = moment.utc(this.state.selectedResource[prop])
    _time.hour(parseInt(_hour))
    _time.minute(parseInt(_minute))
    let _resourcegroups = _r.resourcegroups.map((r) => {
      // if (this.state.selectedResource.resourceId === r.resource) { // TODO: remove if all updated same time
      r[prop] = _time.format(TIMEFORMAT)
      // }
      return r
    })
    _r.resourcegroups = _resourcegroups
    // console.log(_resources)
    // this.setReservationProperty('resources', _resources)
    let _state = { reservation: _r, savedAfterChange: false }
    if (prop === 'start') {
      _state.defaultStart = _time.format(TIMEFORMAT)
      // this.setState({ reservation: _r, defaultStart: _time.format(TIMEFORMAT), savedAfterChange: false }, () => this.updatePriceAndConflicts(true))
    } else if (prop === 'end') {
      _state.defaultEnd = _time.format(TIMEFORMAT)
      // this.setState({ reservation: _r, defaultEnd: _time.format(TIMEFORMAT), savedAfterChange: false }, () => this.updatePriceAndConflicts(true))
    }
    this.pricingtimer = setTimeout(() => {
      this.updateReservationTime(_state);
    }, 500)
  }

  changeDuration = (duration) => {
    if (this.state.reservation) {
      const newEndTime = moment.utc(this.state.reservation.resourcegroups[0].start).add(duration, 'minutes')
      this.setReservationTime('end', newEndTime.format('HH:mm'))
    }
  }

  updateReservationTime = (_state) => {
    if (_state) {
      this.setState(_state, () => this.updatePriceAndConflicts(true))
    }
  }

  currentPayer = () => {
    if (
      this.state.reservation &&
      this.state.reservation.id &&
      this.state.reservation.persons &&
      this.state.reservation.persons.payers[0] // &&
      // this.state.reservation.persons.payers[0].person
    ) {
      return (
        <Row>
          <Col>
            Nykyinen asiakas:{' '}
            {this.state.reservation.persons.payers[0].firstname +
              ' ' +
              this.state.reservation.persons.payers[0].lastname}
            {/* {this.state.reservation.persons.payers[0].person.firstname +
              ' ' +
              this.state.reservation.persons.payers[0].person.lastname} */}
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }

  getPayers = () => {
    if (
      this.state.reservation &&
      this.state.reservation.id &&
      this.state.reservation.persons &&
      this.state.reservation.persons.payers
    ) {
      return this.state.reservation.persons.payers
        .map((p) => {
          // return { value: p.person.id, label: p.person.firstname + ' ' + p.person.lastname}
          return { value: p.id, label: p.firstname + ' ' + p.lastname }
        })
        .filter((p) => p.value)
    } else {
      return []
    }
  }

  handleReservedResources = (e) => {
    if (this.state.reservation) {
      let _resources = e.map((r) => {
        return {
          resource: r.value,
          start: this.state.defaultStart,
          end: this.state.defaultEnd,
        }
      })
      let _r = this.state.reservation
      _r.resources = _resources
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }

  setRecurrencyDates = (enddate) => {
    let recurrencyInterval = this.state.reservation.recurrencyInterval || 'weekly'
    let recurrencyDate = this.state.recurrencyStart
      ? moment.utc(this.state.recurrencyStart)
      : this.state.reservation.recurrencyStartDate
      ? moment.utc(this.state.reservation.recurrencyStartDate)
      : null
      // : moment.utc(this.state.selectedResource.start)
    
    // console.log('recurrencyDate', recurrencyDate)
    if (recurrencyDate) {
    
      let reservationDate = moment.utc(this.state.selectedResource.start)
      // let weekday = reservationDate.day()
      if (recurrencyInterval !== 'daily') {
        if (recurrencyDate.day() !== reservationDate.day()) {
          let diff = recurrencyDate.day() - reservationDate.day()
          if (diff > 0) {
            recurrencyDate.add(7 - diff, 'days')
          } else {
            recurrencyDate.add(Math.abs(diff), 'days')
          }
        }
      }
      let recurrencyEndDate = this.state.recurrencyEnd
        ? moment.utc(this.state.recurrencyEnd)
        : moment.utc(this.state.reservation.recurrencyEndDate)
        // : this.state.reservation.recurrencyEndDate ? moment.utc(this.state.reservation.recurrencyEndDate) : null
      let occurrencies = []
      // console.log('recurrencyDate', recurrencyDate)
      // console.log('recurrencyEndDate', recurrencyEndDate, 'isvalid', recurrencyEndDate.isValid())
      // console.log('recurrencyInterval', recurrencyInterval)
      if (recurrencyDate && recurrencyEndDate.isValid()) {
        // console.log('issame', recurrencyDate.isSameOrBefore(recurrencyEndDate, 'day'), recurrencyDate, recurrencyEndDate)
        while (recurrencyDate.isSameOrBefore(recurrencyEndDate, 'day')) { //recurrencyEndDate.diff(recurrencyDate, 'days') > -1) {
          occurrencies.push({ date: recurrencyDate.format('YYYY-MM-DD'), state: 'reserved' })
          if (recurrencyInterval === 'daily') {
            recurrencyDate.add(1, 'd')
          } else if (recurrencyInterval === 'weekly') {
            recurrencyDate.add(1, 'week')
          } else if (recurrencyInterval === 'monthly') {
            recurrencyDate.add(1, 'month')
          }
          // console.log('recurrencyDate', recurrencyDate, 'recurrencyInterval', recurrencyInterval)
        }
      }
      let _r = { ...this.state.reservation }
      // console.log('occurrencies', occurrencies)
      // console.log('recurrencyDate', recurrencyDate)

      if (_r.recurrencyDates && _r.recurrencyDates.length > 0) {
        // temp fix to check non object recurrencies
        // console.log('recurrencyType', typeof _r.recurrencyDates[0])
        if (typeof _r.recurrencyDates[0] !== 'object') {
          _r.recurrencyDates = _r.recurrencyDates.map((r) => ({ date: r, state: 'reserved' }))
        }
        // temp fix end

        let _mergedDates = occurrencies.map((o) => {
          let _o = _r.recurrencyDates.find((_f) => _f.date === o.date)
          if (_o) return _o
          return o
        })
        _r.recurrencyDates = _mergedDates
      } else {
        _r.recurrencyDates = occurrencies
      }
      _r.recurrencyEndDate = recurrencyEndDate.format('YYYY-MM-DD')
      _r.recurrencyInterval = recurrencyInterval
      this.setState({ reservation: _r, checkingConflicts: true }, this.updateConflicts)
    }
  }

  handleRecurringIntervalChange = (e, v) => {
    let _r = this.state.reservation
    _r.recurrencyInterval = e.target.value
    this.setState({ reservation: _r, savedAfterChange: false }, this.setRecurrencyDates)
  }
  
  setRecurrencyEnd = (e) => {
    if (moment.utc(e.target.value, 'YYYY-MM-DD', true).isValid()) {
      let _r = this.state.reservation
      _r.recurrencyEndDate = e.target.value
      if (_r.recurrencyStartDate && moment.utc(_r.recurrencyStartDate, 'YYYY-MM-DD').isBefore(moment.utc(e.target.value, 'YYYY-MM-DD')) && moment.utc(MAX_RECURRENCY_END_DATE, 'YYYY-MM-DD').isAfter(moment.utc(e.target.value, 'YYYY-MM-DD'))) {
        this.setState({ reservation: _r, savedAfterChange: false, recurrencyEndValid: true }, this.setRecurrencyDates)
      } else {
        this.setState({ recurrencyEndValid: false })
      }
    } else {
      this.setState({ recurrencyEndValid: false })
    }
  }

  setRecurrencyStart = (e) => {
    if (moment.utc(e.target.value, 'YYYY-MM-DD', true).isValid()) {
      let _r = { ...this.state.reservation }
      _r.recurrencyStartDate = e.target.value 
      if (moment.utc().startOf('year').isBefore(moment.utc(e.target.value, 'YYYY-MM-DD'))) {
        this.setState({ reservation: _r, savedAfterChange: false, recurrencyStartValid: true }, this.setRecurrencyDates)
      } else {
        this.setState({ recurrencyStartValid: false })
      }
    } else {
      this.setState({ recurrencyStartValid: false })
    }
  }

  setRecurrencyCount = (e) => {
    // console.log(e.target.value)
  }

  showMaksukoodi = (e) => {
    // console.log(e.target.value)
  }

  handleContractTypeChange = (e, v) => {
    // console.log(e.target.value)
    let _r = { ...this.state.reservation }
    _r.contracttype = e.target.value
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  handleStateChange = (e, v) => {
    if (this.state.reservation.isrecurring && !this.state.showRecurringDetails) {
      if (this.state.selectedResource) {
        const thisDate = moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
        const occurencyObject = this.state.reservation.recurrencyDates.find((x) => x.date === thisDate)
        if (occurencyObject) {
          this.handleOccurencyStateChange(e, occurencyObject)
        }
      }
    } else {
      let _r = { ...this.state.reservation }
      _r.state = v
      this.setState({ reservation: _r, showStateChangeAlert: true, showMessageSendingDialog: true, savedAfterChange: false })
    }
  }

  handleOccurencyLightsChange = (e, occurencyObject) => {
    occurencyObject.nolights = e.target.checked
    let _r = this.state.reservation
    let updated = _r.recurrencyDates.map((r) => {
      if (r.date === occurencyObject.date) {
        return occurencyObject
      } else {
        return r
      }
    })
    _r.recurrencyDates = updated
    this.setState({ reservation: _r, showMessageSendingDialog: true })
  }

  onLightsChange = (e) => {
    if (this.state.reservation.isrecurring) {
      if (this.state.selectedResource) {
        const thisDate = moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
        const occurencyObject = this.state.reservation.recurrencyDates.find((x) => x.date === thisDate)
        if (occurencyObject) {
          this.handleOccurencyLightsChange(e, occurencyObject)
        }
      }
    } else {
      let _r = { ...this.state.reservation }
      _r.nolights = e.target.checked
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }


  handleOccurencyFlexEndTimeChange = (e, occurencyObject) => {
    occurencyObject.flexendtime = e.target.checked
    let _r = this.state.reservation
    let updated = _r.recurrencyDates.map((r) => {
      if (r.date === occurencyObject.date) {
        return occurencyObject
      } else {
        return r
      }
    })
    _r.recurrencyDates = updated
    this.setState({ reservation: _r, showMessageSendingDialog: true })
  }

  onFlexEndTimeChange = (e) => {
    if (this.state.reservation.isrecurring) {
      if (this.state.selectedResource) {
        const thisDate = moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
        const occurencyObject = this.state.reservation.recurrencyDates.find((x) => x.date === thisDate)
        if (occurencyObject) {
          this.handleOccurencyFlexEndTimeChange(e, occurencyObject)
        }
      }
    } else {
      let _r = { ...this.state.reservation }
      _r.flexendtime = e.target.checked
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }


  onManualAccessCodeVisibilityChange = (e) => {
    this.setState({ showManualAccessCode: e.target.checked })
    if (!e.target.checked) {
      let _r = { ...this.state.reservation }
      _r.accesscode = null
      this.setState({ reservation: _r, savedAfterChange: false })
    }
  }

  handleAccessCodeChange = (e) => {
    let _r = { ...this.state.reservation }
    if (e.target.value.length < 1) {
      _r.accesscode = null
    } else {
      _r.accesscode = e.target.value
    }
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  handleCostChange = (e) => {
    let _r = { ...this.state.reservation }
    _r.resourcegroups[0].cost = e.target.value
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  manuallySetCost = (pricing) => {
    let _r = { ...this.state.reservation }
    _r.resourcegroups[0].cost = pricing.price
    let pricingTag = null
    if (pricing.membershipname) {
      pricingTag = pricing.membershipname
    }
    this.setState({ reservation: _r, pricingTag, savedAfterChange: false })
  }

  setCost = (price) => {
    let _r = { ...this.state.reservation }
    _r.resourcegroups[0].cost = price
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  handleOverrideCostChange = (e) => {
    let _r = { ...this.state.reservation }
    if (isNaN(parseFloat(e.target.value))) {
      delete  _r.resourcegroups[0].overridecost
    } else {
      _r.resourcegroups[0].overridecost = parseFloat(e.target.value)
    }
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  handleTitleChange = (e) => {
    let _r = { ...this.state.reservation }
    _r.title = e.target.value
    this.setState({ reservation: _r, savedAfterChange: false })

  }

  handlePaidChange = (e) => {
    let _r = { ...this.state.reservation }
    _r.paid = e.target.value
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  markAsPaid = async (event) => {
    event.preventDefault()
    let _r = { ...this.state.reservation }
    _r.paid = _r.resourcegroups[0].cost
    this.setState({ reservation: _r, savedAfterChange: false })
  }

  handleNote = (e) => {
    this.setState({ notetext: e.target.value })
  }

  handlePaymentAmount = (e) => {
    let paymentamount = e.target.value
    this.setState({ paymentamount })
  }

  handlePaymentNote = (e) => {
    let paymentnote = e.target.value
    this.setState({ paymentnote })
  }
 
  handlePaymentType = (e) => {
    const paymenttype = this.state.paymenttypes.find((g) => g.value === e.target.value)
    this.setState({ paymenttype })
  }

  handleSplitChange = (e) => {
    // console.log(e.target.value)
    const splitmoment = this.state.possiblesplits.find((g) => {
      return g.value === e.target.value
    })
    if (splitmoment) {
      this.setState({ split: splitmoment.value, checkingPrice: true })
    }
    const res = new Reservation({ ...this.state.reservation })
    const splitDateString = res.getStartMoment().format('YYYY-MM-DD') + ' ' + splitmoment.value
    const splitOne = { start: res.getStartMoment().format('YYYY-MM-DD HH:mm'), end: splitDateString, resourceId: res.getResource().id }
    const splitTwo = { start: splitDateString, end: res.getEndMoment().format('YYYY-MM-DD HH:mm'), resourceId: res.getResource().id }
    const durationOne = moment.utc(splitOne.end).diff(moment.utc(splitOne.start), 'minutes')
    const durationTwo = moment.utc(splitTwo.end).diff(moment.utc(splitTwo.start), 'minutes')
    const originalCost = res.getCost() * 100
    const splitOneCost = Math.round(originalCost * (durationOne / (durationOne + durationTwo))) / 100
    const splitTwoCost = Math.round(originalCost * (durationTwo / (durationOne + durationTwo))) / 100
    const splitSuccestions = [{ originalSplit: splitOneCost }, { originalSplit: splitTwoCost }] 

    this.getSplitPrices([splitOne, splitTwo]).then((results) => {
      const splitOnePrice = results[0].data.prices.find((p) => p.pricing === 'general')
      const splitTwoPrice = results[1].data.prices.find((p) => p.pricing === 'general')
      const splitPrices = [0, 0]
      const splitPriceErrors = [null, null]
      if (splitOnePrice) {
        splitPrices[0] = splitOnePrice.price
        splitSuccestions[0].pricing = splitOnePrice.price
        if(!splitOnePrice.price) {
          splitPriceErrors[0] = 'Ei hinnoittelua välille'
        }
      }
      if (splitTwoPrice) {
        splitPrices[1] = splitTwoPrice.price
        splitSuccestions[1].pricing = splitTwoPrice.price
        if(!splitTwoPrice.price) {
          splitPriceErrors[1] = 'Ei hinnoittelua välille'
        }
      }
      this.setState({ splitPrices, splitPriceErrors, checkingPrice: false, splitSuccestions })
      // if (response.data && response.data.prices) {
      //   slotpricings = response.data.prices.map((p) => {
      //     p.price = p.price.toFixed(2)
      //     if (p.price - Math.floor(p.price) === 0) {
      //       p.price = Math.floor(p.price)
      //     } 
      //     return p
      //   })

      //   const general = response.data.prices.find((x) => x.pricing === 'general')
      //   if (general) {
      //     if (_r.resourcegroups[0].cost <= 0 || force) {
      //       _r.resourcegroups[0].cost = general.price
      //     }
      //   }
      // }
    })


    // const slot = {
    //   start: moment.utc(_r.resourcegroups[0].start).format('YYYY-MM-DD HH:mm'),
    //   end: moment.utc(_r.resourcegroups[0].end).format('YYYY-MM-DD HH:mm'),
    //   resourceId: _r.resourcegroups[0].resources[0].id,
    // }

  }

  handleContractSigningTypeChange = (e, v) => {
    let _r = this.state.reservation
    _r.contractsigningtype = e.target.value
    this.setState({ reservation: _r })
  }

  toggleAddNote = () => {
    this.setState({ showAddNoteRow: !this.state.showAddNoteRow, notetext: null })
  }

  onSaveNote = () => {
    const _r = new Reservation({ ...this.state.reservation })
    if (this.state.editnoteid) {
      _r.updateNote({
        id: this.state.editnoteid,
        timestamp: moment.utc().format('YYYY.MM.DD HH:mm'),
        note: this.state.notetext,
        occurency: moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD'),
        all: this.state.noteforall,
        u: this.props.firebase.auth.currentUser.email
      })
    } else {
      _r.addNote({
        id: uuidv4(),
        timestamp: moment.utc().format('YYYY.MM.DD HH:mm'),
        note: this.state.notetext,
        occurency: moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD'),
        all: this.state.noteforall,
        u: this.props.firebase.auth.currentUser.email
      })
    }
    this.setState({ reservation: _r.toObject(), notetext: null, savedAfterChange: false, editnoteid: null, noteforall: false }, this.toggleAddNote)
  }

  onEditNote = (id) => {
    const _r = new Reservation({ ...this.state.reservation })
    const note = _r.getNote(id)
    if (note) {
      this.setState({ notetext: note.note, editnoteid: id, showAddNoteRow: true, noteforall: note.all })
    }
  }

  handleShowRecurringDetailsChange = (state) => {
    this.setState({ showRecurringDetails: state })
  }

  toggleAddPayment = () => {
    this.setState({ showAddPaymentRow: !this.state.showAddPaymentRow, paymentamount: null, paymentnote: null, paymenttype: null })
  }

  onSavePayment = () => {
    let _r = { ...this.state.reservation }
    if (!_r.payments) _r.payments = []
    let _amount = Number.parseFloat(this.state.paymentamount.replace(',', '.')) * 100
    let __amount = _amount.toFixed(0)
    _r.payments.push({
      id: uuidv4(),
      type: this.state.paymenttype.value,
      typelabel: this.state.paymenttype.label,
      timestamp: moment.utc().format('YYYY.MM.DD HH:mm'),
      amount: parseInt(__amount),
      note: this.state.paymentnote,
      occurency: moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
    })
    this.setState({ reservation: _r, paymenttype: null, paymentamount: 0, paymentnote: null, savedAfterChange: false }, this.toggleAddPayment)
  }
 
  isConflicting = (start, end, bookings) => {
    const _as = moment.utc(start).unix() + 1
    const _ae = moment.utc(end).unix() - 1
    let collision = bookings.find((b) => {
      const _bs = moment.utc(b.start).unix() + 1
      const _be = moment.utc(b.end).unix() - 1
      // console.log(_as, '<=', _be, _ae, '>=', _bs)
      if ((_as <= _be) && (_ae >= _bs)) {
        return true
      } else {
        return false
      }
    })
    if (collision) {
      return { collision, isconflict: true }
    } else {
      return { isconflict: false }
    }
  }

  toggeleShowDebug = () => {
    this.setState({ showDebug: !this.state.showDebug })
  }

  getDiffs = async () => {
    const diffs = await this.props.firebase.getDiffs(this.state.reservation.id)
    const formattedDiffs = Object.keys(diffs).map((key) => {
      const o = diffs[key]
      let parseError = false
      let b
      try {
        b = JSON.parse(o.diff)
      } catch(e) {
        b = o.diff
        parseError = true
    }

      return (<Row>
        <Col className='small' md={'2'}>{(o.useremail ? o.useremail : o.userid)}</Col>
        <Col className='small' md={'2'}>{moment.utc(o.timestamp).format()}</Col>
        {!parseError ?
          <>
           <Col md={'4'}><JSONPretty data={b.added}></JSONPretty></Col>
            <Col md={'4'}><JSONPretty data={b.updated}></JSONPretty></Col>
            </>
          :
          <Col md={'8'}><code>{b}</code></Col>
        }
      </Row>)
    })
    formattedDiffs.unshift(<Row><Col className='small' md={'2'}>User</Col><Col className='small' md={'2'}>Time (UTC)</Col><Col md={'4'}>Added</Col><Col md={'4'}>Updated</Col></Row>)
    this.setState({ diffs, formattedDiffs })
  }

  setNoteforAll = (e) => {
    this.setState({ noteforall: e.target.checked })
  }

  setConfirmation = (e) => {
    this.setState({ sendConfirmationWhenSave: e.target.checked })
  }

  toggleDiffs = () => {
    this.setState({ showDiffs: !this.state.showDiffs }, this.getDiffs)
  }

  toggleMessage = () => {
    if (!this.state.showMessageModal) {
      let messagetempname = 'reservationmessage'
      if (this.state.reservation.state === 'cancelled') {
        messagetempname = 'cancelmessage'
      }
      if (this.state.reservation.isrecurring) {
        if (this.state.selectedResource) {
          const thisDate = moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD')
          const occurencyObject = this.state.reservation.recurrencyDates.find((x) => x.date === thisDate)
          if (occurencyObject) {
            if (occurencyObject.state === 'cancelled') {
              messagetempname = 'cancelmessage'
            }
          }
        }
      }
      this.updateMessageTemplate(messagetempname)
      this.setState({ showMessageModal: !this.state.showMessageModal })
    } else {
      this.setState({ showMessageModal: !this.state.showMessageModal, messageTemplateName: null, messageBody: null, messageSubject: null, saveAfterSend: false })
    }
  }

  findTemplate = (filter) => {
    let messagetype = filter
    if (filter === 'cancel') {
      messagetype = 'cancellation'
    }
    let _f = null
    let defaultTemplate = null
    if (this.state.messagetemplates && this.state.messagetemplates.length > 0) {
      const reservation = new Reservation({ ...this.state.reservation })
      const venue = reservation.getVenueId()
      const resource = reservation.getResource()
      const category = resource.category
      // console.log('venue', venue)
      // const filters = [x.enabled, x.type === templatename, category && x.category === , venue && x.venue === venue]
      defaultTemplate = this.state.messagetemplates.find((x) => x.default && x.type === messagetype && x.category === 'all')
      _f = this.state.messagetemplates.find((x) => x.enabled && x.type === messagetype && category && x.category === category && venue && x.venue === venue)
      if (!_f) _f = this.state.messagetemplates.find((x) => x.enabled && x.type === messagetype && category && x.category === category)
    }
    if (_f) {
      return _f.message
    } else if (defaultTemplate) {
      return defaultTemplate.message
    } else {
      return messageTemplatesLocal[filter + 'message']
    }
    
  } 

  updateMessageTemplate = (templatename) => {
    const filter = templatename.replace('message', '')
    const template = this.findTemplate(filter)
    this.fillTemplate(template).then((_t) => {
      console.log('fillTemplate', _t)
      this.setState({ messageTemplateName: templatename, messageBody: _t.body, messageSubject: _t.subject })
    })
  }

  handleOccurencyStateChange = (e, occurencyObject) => {
    occurencyObject.state = e.target.value
    let _r = this.state.reservation
    let updated = _r.recurrencyDates.map((r) => {
      if (r.date === occurencyObject.date) {
        return occurencyObject
      } else {
        return r
      }
    })
    _r.recurrencyDates = updated
    this.setState({ reservation: _r, showMessageSendingDialog: true })
  }

  renderContract = () => {
    const isContractCreated = this.state.contract
    if (this.state.reservation?.contractid && this.state.contract) {
      return (
        <>
          <Row style={{ fontSize: 12 }} className='recurrentrow'>
            <Col md='2'>Tila</Col>
            <Col md='4'>Sopimusmalli</Col>
            <Col md='4'>Voimassa</Col>
            <Col md='2'></Col>
          </Row>
          <Row style={{ fontSize: 12 }} className='recurrentrow'>
            <Col md='2'>{this.state.contract.status}</Col>
            <Col md='4'>
              {this.state.contract.type === 'standardshift-fixedterm' ? 'Määräaikainen' : 'Toistaiseksi voimassa'}
            </Col>
            <Col md='4'>{this.state.contract.validuntil}</Col>
            <Col md='2'>
              <a href={this.state.contract.file} target='_blank' rel='noopener noreferrer'>
                Lataa
              </a>
            </Col>
          </Row>
        </>
      )
      // } else if (!this.state.reservation?.persons?.payers[0]?.person?.email) {
    } else if (
      !this.state.reservation?.persons?.payers?.[0]?.email &&
      !this.state.reservation?.persons?.payers?.[0]?.contacts?.[0]?.email
    ) {
      return (
        <Row>
          <Col>Maksajalla ei ole sähköpostiosoitetta, et voi tehdä sopimusta hänen kanssaan.</Col>
        </Row>
      )
    } else {
      return (
        <>
          <Row>
            <Col md='6'>
              <FormControl>
                <InputLabel id='contract-label'>Sopimus</InputLabel>
                <Select
                  labelId='contract'
                  id='contract'
                  value={this.state?.reservation?.contracttype || 'no-contract'}
                  onChange={this.handleContractTypeChange}
                  disabled={isContractCreated}
                >
                  <MenuItem value={'no-contract'}>Ei sopimusta</MenuItem>
                  <MenuItem value={'standardshift-fixedterm'}>Määräaikainen</MenuItem>
                  <MenuItem value={'standardshift-untilfurthernotice'}>Toistaiseksi voimassa oleva</MenuItem>
                </Select>
              </FormControl>

              {/* <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='contract'
                  name='contract'
                  defaultValue={this.state?.reservation?.contractype || 'no-contract'}
                  onChange={this.handleContractTypeChange}
                >
                  <FormControlLabel
                    value='no-contract'
                    control={<Radio color='primary' />}
                    label='Ei sopimusta'
                    labelPlacement='top'
                  />
                  <FormControlLabel
                    value='standardshift-fixedterm'
                    control={<Radio color='primary' />}
                    label='Määräaikainen'
                    labelPlacement='top'
                  />
                  <FormControlLabel
                    value='standardshift-untilfurthernotice'
                    control={<Radio color='primary' />}
                    label='Toistaiseksi voimassa oleva'
                    labelPlacement='top'
                  />
                </RadioGroup>
              </FormControl> */}
            </Col>
            <Col md='6'>
              {this.state.reservation.contracttype && this.state.reservation.contracttype !== 'no-contract' ? (
                <FormControl>
                  <InputLabel id='contractsigningtype-label'>Allekirjoitus</InputLabel>
                  <Select
                    labelId='contractsigningtype'
                    id='contractsigningtype'
                    value={this.state.reservation?.contractsigningtype || 'digital'}
                    onChange={this.handleContractSigningTypeChange}
                    disabled={isContractCreated}
                  >
                    <MenuItem value={'digital'}>Sähköinen</MenuItem>
                    <MenuItem value={'manual'}>Manuaalinen</MenuItem>
                  </Select>
                </FormControl>
              ) : null}
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='contractsigningtype'
                  name='contractsigningtype'
                  defaultValue={this.state.contract?.signingtype || 'digital'}
                  onChange={this.handleContractSigningTypeChange}
                >
                  <FormControlLabel
                    value='digital'
                    control={<Radio color='primary' />}
                    label='Sähköinen allekirjoitus'
                    labelPlacement='top'
                  />
                  <FormControlLabel
                    value='manual'
                    control={<Radio color='primary' />}
                    label='Manuaalinen'
                    labelPlacement='top'
                  />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row> */}
        </>
      )
    }
  }

  isValidBusiness = (option) => {
    if (option.businessId) {
      if (!option.contacts) {
        return true
      }
    }
    return false
  }

  setCourtLock = (value, event) => {
    if (!value && event.shiftKey) {
      this.setState({ courtlocked: value })
    } else {
      this.setState({ courtlocked: true })
    }
  }

  

  clearAlgolia = (x) => { x.id = x.objectID; delete x._highlightResult; delete x.lastmodified; delete x.reservationAlert; delete x.objectID; return x }

  searchCombinedPayers = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => this.clearAlgolia(x) )
      const organisations = this.props.firebase.searchCombined(input).map((p) => p.item)
      const searchresult = persons.concat(organisations)
      console.log('searchCombinedPayers', searchresult)
      this.setState({ searchpayers: searchresult })
    })
  }

  searchCombinedParticipants = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits.map((x) => this.clearAlgolia(x) )
      const organisations = this.props.firebase.searchCombined(input).map((p) => p.item)
      const searchresult = persons.concat(organisations)
      this.setState({ searchparticipants: searchresult })
    })
  }
  
  searchCombinedOwners = (input) => {
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits.map((x) => this.clearAlgolia(x) )
      const organisations = this.props.firebase.searchCombined(input).map((p) => p.item)
      const searchresult = persons.concat(organisations)
      if (!this.state?.reservation?.persons?.owner) {
        const _r = this.state.reservation
        _r.persons.owner = []
        this.setState({ searchowners: searchresult, reservation: _r }) 
      } else {
        this.setState({ searchowners: searchresult })
      }
    })
  }

  renderPaymentRow = (payment, thisOccurency, firstOccurency) => {
    const paymentOccurency = payment.occurency ? payment.occurency : firstOccurency
    let isPaymentForThisOccurency = thisOccurency === paymentOccurency
    const isMinutePayment = payment.type === 'minutestore'
    return (
      <Row key={payment.id}>
        <Col className={isPaymentForThisOccurency ? 'text-info' : 'text-muted'}>
          {isMinutePayment ?  payment.amount + ' min' : (Math.round(payment.amount) / 100).toFixed(2).replace('.', ',') + '€'}
        </Col>
        <Col className={isPaymentForThisOccurency ? 'text-info' : 'text-muted'}>
          {payment.typelabel}
        </Col>
        {payment.note ? (
          <Col className={isPaymentForThisOccurency ? 'text-info' : 'text-muted'}>
            {payment.note}
          </Col>
        ) : null}
        <Col className={isPaymentForThisOccurency ? 'text-info' : 'text-muted'}>
          {moment
            .utc(payment.timestamp, 'YYYY.MM.DD HH:mm')
            .add(TIMEZONE_OFFSET, 'minutes')
            .format('D.M. HH:mm')}
        </Col>
        <Col className={isPaymentForThisOccurency ? 'text-info' : 'text-muted'}>
          Vuoro {moment.utc(paymentOccurency).format('DD.MM.')}
        </Col>
      </Row>
    )
  }

  renderPayments = (thisOccurency, firstOccurency) => {
    if (this.state.reservation.payments) {
      if (this.state.reservation.isrecurring && !this.state.showRecurringDetails) {
        return this.state.reservation.payments.filter((x) => x.occurency === thisOccurency).map((payment) => {
          return this.renderPaymentRow(payment, thisOccurency, firstOccurency)
        })
      } else {
        return this.state.reservation.payments.map((payment) => {
          return this.renderPaymentRow(payment, thisOccurency, firstOccurency)
        })
      }
    } else {
      return <></>
    }
  }

  renderSaldos = () => {
    if (this.state.userprofile && this.state.userprofile.saldos) {
      const saldos = { ...this.state.userprofile.saldos }
      const venues = Object.keys(saldos)
      return venues.map((venue) => {
        const wallettypes = Object.keys(saldos[venue])
        return wallettypes.map((wallettype) => {
          if(saldos[venue][wallettype] === 0) return null
          return (
            <Row key={venue + wallettype} style={{ fontSize: 12 }}>
              <Col>
                {venue} {saldos[venue][wallettype] / 100} €
              </Col>
            </Row>
          )
        }).filter(x => x)
      })
    }
    return <></>
  }

  renderPricingAndPayments = (allReadyPaid, thisOccurency, firstOccurency, sumOpen, thisOccurencyObject, isrecurring, isContractCreated, isValidToSave, isAdminOrCashier) => {
    const disableOverride = (isrecurring && !this.state.showRecurringDetails) || isContractCreated
    let enableCancel = true
    if (!isAdminOrCashier) {
      const isTooLate = isTooLateForPartner(this.state.reservation)
      if (isTooLate || isrecurring) enableCancel = false
      console.log('enableCancel', enableCancel, 'isTooLate', isTooLate, 'isrecurring', isrecurring)
    }
    return (
      <>
      <Row>
      <Col md='3'>
        <TextField
          id='cost'
          label='Hinnaston hinta'
          type='number'
          value={
            this.state.reservation.resourcegroups?.[0]?.cost
              ? this.state.reservation.resourcegroups[0].cost
              : 0
          }
          InputProps={{
            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
          }}
          onChange={this.handleCostChange}
          // disabled={this.state.reservation.resourcegroups?.[0]?.overridecost ? true : false}
          disabled={true}
        />
      </Col>
          <Col md='3'>
            {isAdminOrCashier ? (
              <TextField
                id='paid'
                label='Maksettu'
                type='number'
                value={allReadyPaid}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                }}
              />) : null}
        </Col>
          <Col md='3'>
            {isAdminOrCashier ? (
              <TextField
                id='overridecost'
                label='Käsinkorjattu hinta'
                type='number'
                value={
                  this.state.reservation.resourcegroups?.[0]?.overridecost === 'undefined'
                    ? ''
                    : this.state.reservation.resourcegroups[0].overridecost
                }
                InputProps={{
                  endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                }}
                    onChange={this.handleOverrideCostChange}
                    disabled={disableOverride}
                  />
              ) : null}
      </Col>
      <Col md='3'>
        {this.state.loadingmemberships || this.state.checkingPrice ? (
          <Spinner size={'sm'} />
        ) : this.state.slotpricings ? (
          this.state.slotpricings.map((p) => {
            // check if updated payer has membership
            let _m = null
            if (this.state.payermemberships && p.membershipid) {
              console.log('p.membershipid', p.membershipid)
              console.log('this.state.payermemberships', this.state.payermemberships)
              _m = this.state.payermemberships.find((x) => x.membershipid === p.membershipid)
            }
            let color = 'info'
            if (_m) {
              color = 'success'
            } else if (p.pricing === 'general') {
              color = 'info'
            } else if (this.state.payermemberships) {
              color = 'danger'
            }
            return (
              <Row key={p.pricing}>
                <Col>
                  <Ripples>
                    <Badge
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.manuallySetCost(p)} //this.setCost(p.price)}
                      color={color}
                      size={'sm'}
                    >
                      {p.pricing === 'general' ? 'Hinnasto' : p.membershipname} {p.price + ' €'}
                    </Badge>
                  </Ripples>
                </Col>
              </Row>
            )
          })
        ) : null}
      </Col>

      {/* <Col className='offset-md-2'>
    <Button color='info' onClick={this.markAsPaid}>
      Merkkaa kokonaan maksetuksi
    </Button>
  </Col> */}
    </Row>
    <Row>
      <Col style={{ fontSize: 14 }}>Maksutapahtumat</Col>
    </Row>
    <Row>
      <Col>
          {this.state.reservation.payments
            ? this.renderPayments(thisOccurency, firstOccurency)
          : null}
      </Col>
    </Row>
    {isAdminOrCashier ? (this.state.showAddPaymentRow ? (
      <>
        <Row>
          <Col md='2'>
            <TextField
              id='paymentamount'
              label='Summa'
              type='text'
              value={this.state.paymentamount || ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              }}
                  onChange={this.handlePaymentAmount}
            />
          </Col>
          <Col md='4'>
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel id='paymenttype'>Maksutapa</InputLabel>
              <Select
                labelId='paymenttype-label'
                id='paymenttype'
                value={this.state.paymenttype?.value || 'none'}
                onChange={this.handlePaymentType}
              >
                <MenuItem key={'none'}></MenuItem>
                {this.state.paymenttypes.map((pt) => {
                  return (
                    <MenuItem key={pt.value} value={pt.value}>
                      {pt.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Col>
          <Col md='6'>
            <Button
              outline
              color='success'
              disabled={!this.state.paymenttype}
              onClick={this.onSavePayment}
            >
              Lisää
            </Button>
            <Button outline color='primary' onClick={this.toggleAddPayment}>
              Peruuta
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md='6'>
            <TextField
              id='paymentamount'
              label='Maksun muistiinpano'
              type='text'
              fullWidth
              value={this.state.paymentnote || ''}
              onChange={this.handlePaymentNote}
            />
          </Col>
        </Row>
      </>
    ) : (
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col md='1'>
          <Avatar onClick={this.toggleAddPayment} style={{ height: 30, width: 30 }}>
            <AddIcon />
          </Avatar>
        </Col>
        <Col>
          <div style={{ marginTop: 5 }}>Lisää maksumerkintä</div>
        </Col>
        <Col>
          <Button
            disabled={!(isValidToSave && (this.state.selectedResource && this.state.selectedResource.id))}
            size={'sm'}
            outline
            color='primary'
            onClick={() => this.setState({ showSaldopaymentDialog: true })}
            style={{ marginTop: 0 }}>
            Saldomaksu
          </Button>
        </Col>
      </Row>
    )) : null}

    <Row></Row>
      <Row><Col style={{ fontSize: 14 }}>{this.state.reservation.isrecurring ? (this.state.showRecurringDetails ? <>Koko vakiovuoro</> : <>Vakiovuoron tämä vuoro {moment.utc(thisOccurency).format('D.M.YY')}</>) : null}</Col></Row>
    <Row>
      <Col xs={12} lg={6}>
      <FormControl component='fieldset'>
        <RadioGroup
          row
          aria-label='cancel'
          name='cancel'
          value={this.state.reservation.isrecurring && !this.state.showRecurringDetails ? (thisOccurencyObject ? thisOccurencyObject.state : (this.state.reservation.state || 'reserved')) : (this.state.reservation.state || 'reserved')}
          onChange={this.handleStateChange}
        >
          <FormControlLabel
            value='reserved'
            control={<Radio color='primary' />}
            label='Varattu'
            labelPlacement='top'
          />
          {!this.state.reservation.isrecurring || !this.state.showRecurringDetails ? (
            <FormControlLabel
              value='onsale'
              control={<Radio color='primary' />}
              label='Myynnissä'
              labelPlacement='top'
            />
                ) : null}
              {enableCancel ?
                <FormControlLabel
                  value='cancelled'
                  control={<Radio color='primary' />}
                  label='Peruttu'
                  labelPlacement='top'
                />
          : null}
            </RadioGroup>
      </FormControl>
      </Col>
      <Col xs={3}>
        <Tooltip title='Sadeperuutus' arrow><Button color='danger' size='sm' onClick={this.onRainCancellationPressed} ><img height={20} width={20} src={rainicon} alt=""/></Button></Tooltip>
          </Col>
          {!this.state.showRecurringDetails ? (
        <Col xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.reservation.isrecurring ? (thisOccurencyObject && thisOccurencyObject.nolights) : this.state.reservation.nolights}
                onChange={this.onLightsChange}
                name='lightsoff'
                color='primary'
              />
            }
            label={<small style={{ fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif' }}>Valot pois</small>}
          />
          <br />
          <FormControlLabel
              control={
                  <Checkbox
                    disabled={this.state.reservation.isrecurring}
                    checked={this.state.reservation.isrecurring ? (thisOccurencyObject && thisOccurencyObject.flexendtime) : this.state.reservation.flexendtime}
                    onChange={this.onFlexEndTimeChange}
                    name='flexendtime'
                    color='primary'
                  />
                }
                label={<small style={{ fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif' }}>Joustava lopetusaika</small>}
            />
            </Col>
          ) : null}
    </Row>
    <Row>
      <Col>
        <Alert
          color='danger'
          isOpen={this.state.showStateChangeAlert && this.state.reservation.isrecurring}
          toggle={this.onDismissAlert}
        >
          Huom! Olet muuttamassa koko vakiovuoron tilaa.
        </Alert>
      </Col>
    </Row>
    <Row>
      <Col md='12' className='d-flex justify-content-end'>
        {sumOpen !== 0 ? 'Summa auki: ' + sumOpen + ' €' : 'Ei maksettavaa'}
      </Col>
      </Row></>
    )
  }

  handlePersonSelect = (person) => {
    if(this.props.onSelectPerson) this.props.onSelectPerson(person)
  }

  handleSavedSaldoPayment = () => {
    this.clearState()
    this.handleClose()
  }

  handleClose = () => {
    if (this.unsubscribeConcurrentUpdates) {
      this.unsubscribeConcurrentUpdates()
      this.unsubscribeConcurrentUpdates = null
    }
    this.props.toggle()
  }

  render() {
    console.log('ReservationModal render', this.state.featureOptions)
    // let { titleState, seatsState, courtsState } = this.state
    let sumOpen = 0
    let allReadyPaid = 0
    let cost = 0
    let isPositiveDuration = false
    let thisOccurencyObject = null
    if (this.state.reservation) {
      const thisOccurency = this.state.selectedResource ? moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD') : ''
      let firstOccurency = moment.utc(this.state.reservation.resourcegroups[0].start).format('YYYY-MM-DD')
      let notRecurrent = true
      if (this.state.reservation.recurrencyDates && this.state.reservation.recurrencyDates.length > 0) {
        firstOccurency = this.state.reservation.recurrencyDates[0].date
        notRecurrent = false
        if (thisOccurency !== '') {
          thisOccurencyObject = this.state.reservation.recurrencyDates.find((x) => x.date === thisOccurency)
        }
      }
      if (this.state.reservation.payments) {
        allReadyPaid = this.state.reservation.payments.reduce((acc, cur) => {
          const paymentOccurency = cur.occurency ? cur.occurency : firstOccurency
          // console.log('paymentOccurency', paymentOccurency, 'thisOccurency', thisOccurency, acc)
          const isPaymentForThisOccurency = thisOccurency === paymentOccurency
          if (isPaymentForThisOccurency || notRecurrent) {
            return acc + cur.amount / 100
          } else {
            return acc
          }
        }, 0)
      }
      if (this.state?.reservation?.resourcegroups?.[0]) {
        if (this.state.reservation.resourcegroups[0].hasOwnProperty('cost')) {
          cost = parseFloat(this.state.reservation.resourcegroups[0].cost)
        }
        if (this.state.reservation.resourcegroups[0].hasOwnProperty('overridecost')) {
          if (typeof this.state.reservation.resourcegroups[0].overridecost === 'string') {
            if (!isNaN(parseFloat(this.state.reservation.resourcegroups[0].overridecost))) {
              cost = parseFloat(this.state.reservation.resourcegroups[0].overridecost)
            }
          } else {
            cost = parseFloat(this.state.reservation.resourcegroups[0].overridecost)
          }
        }
        isPositiveDuration = this.isDurationValid()
      }
      sumOpen = cost - allReadyPaid



      let isValidRecurrent = true
      if (this.state.reservation.isrecurring) {
        isValidRecurrent = this.state.reservation.recurrencyDates.length > 0
      } 

      const isContractCreated = this.state.contract ? true : false
      const isRightToReserve = rightToReserve(this.state.reservation, this.props.profile.role, this.props.user.uid)
      const isValidToSave =
        (isRightToReserve &&
          this.state.reservation.persons.payers.length > 0 &&
          this.state.reservation.resourcegroups.length > 0 &&
          this.state.reservation.resourcegroups[0].resources.length > 0 &&
          isPositiveDuration &&
          isValidRecurrent
        )
      const isAdminOrCashier = this.props.profile.role === 'ADMIN' || this.props.profile.role === 'CASHIER'

      return (
        <div>
          <Draggable disabled={!this.state.isMovable}>
            {/* <Dialog
              open={this.state.isOpen}
              keepMounted
              onClose={this.toggleClose}
            > */}
            <Modal isOpen={this.state.isOpen} toggle={this.toggleClose} size='xl' style={{ paddingTop: 0 }}>
              <ModalHeader
                style={
                  this.state.showRecurringDetails && this.state.reservation.isrecurring
                    ? { backgroundColor: '#f05f40', color: 'white' }
                    : {}
                }
                toggle={this.toggleClose}
              >
                <div className='text-left' onClick={() => this.setState({ isMovable: !this.state.isMovable })}>
                  {this.state.isMovable ? <em>Varaus</em> : 'Varaus'}{' '}
                  {this.state.showRecurringDetails && this.state.reservation.isrecurring
                    ? ' - Huom! Olet muokkaamassa koko vakiovuoroa.'
                    : ''}
                </div>
              </ModalHeader>
              {this.state.showDebug ? (
                <ModalBody>
                  <JSONPretty id='json-pretty' data={sortObjectByKeys(this.state.reservation)}></JSONPretty>
                </ModalBody>
              ) : (
                <ModalBody>
                  <Form id='CalendarEventValidation'>
                    <Row>
                      <Col className='border rounded' md='8'>
                        <Row>
                          <Col md='6'>
                              <PersonAndOrganisationSearch
                                label='Lisää maksaja'
                                placeholder='Maksaja'
                                onChange={this.handlePayer}
                                disabled={this.state.reservation.isrecurring && !this.state.showRecurringDetails}
                                values={
                                  this.state?.reservation?.persons?.payers ? this.state.reservation.persons.payers : []
                                }
                              />
                              
                            {/* <Autocomplete
                              multiple
                              disabled={this.state.reservation.isrecurring && !this.state.showRecurringDetails}
                              options={this.state.searchpayers ? this.state.searchpayers : []}
                              getOptionLabel={this.handleOptionLabel} // {option => option.name}
                              onChange={this.handlePayer}
                              getOptionDisabled={this.isValidBusiness}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              value={
                                this.state?.reservation?.persons?.payers ? this.state.reservation.persons.payers : []
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue.length > 2) {
                                  this.searchCombinedPayers(newInputValue)
                                }
                              }}
                                renderInput={(params) => {
                                console.log('renderInput', params)
                                return (
                                  <TextField
                                    {...params}
                                    variant='standard'
                                    label='Lisää maksaja'
                                    placeholder='Maksaja'
                                    margin='normal'
                                    fullWidth
                                  />
                                )
                              }}
                              renderOption={(option, state) => {
                                console.log('renderOption', option, state)
                                let title = ''
                                if (option.businessId) {
                                  title = option.name
                                } else {
                                  title =
                                    option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
                                }
                                return (
                                  <Grid container alignItems='center'>
                                    <Grid item xs>
                                      <span>{title}</span>
                                      <Typography variant='body2' color='textSecondary'>
                                        {option.email}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              }}
                            /> */}
                          </Col>
                            <Col md='6'>
                              <PersonAndOrganisationSearch
                                label='Osallisujat'
                                placeholder='Osallistuja'
                                onChange={this.handleParticipant}
                                disabled={this.state.reservation.isrecurring && this.state.courtlocked}
                                values={
                                  this.state?.reservation?.persons?.participants
                                    ? this.state.reservation.persons.participants
                                    : []
                                }
                              />
                            {/* <Autocomplete
                              multiple
                              disabled={this.state.reservation.isrecurring && this.state.courtlocked}
                              options={this.state.searchparticipants ? this.state.searchparticipants : []}
                              getOptionLabel={this.handleOptionLabel} // {option => option.name}
                              onChange={this.handleParticipant}
                              getOptionDisabled={this.isValidBusiness}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              value={
                                this.state?.reservation?.persons?.participants
                                  ? this.state.reservation.persons.participants
                                  : []
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue.length > 2) {
                                  this.searchCombinedParticipants(newInputValue)
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='standard'
                                  label='Lisää osallistuja'
                                  placeholder='Osallistujat'
                                  margin='normal'
                                  fullWidth
                                />
                              )}
                              renderOption={(option) => {
                                let title = ''
                                if (option.businessId) {
                                  title = option.name
                                } else {
                                  title =
                                    option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
                                }
                                return (
                                  <Grid container alignItems='center'>
                                    <Grid item xs>
                                      <span>{title}</span>
                                      <Typography variant='body2' color='textSecondary'>
                                        {option.email}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              }}
                            /> */}
                          </Col>
                        </Row>
                        {this.state.conflicts && this.state.conflicts.length > 0 ? (
                          <Row>
                            <Col>
                              <Alert color='danger'>
                                Päällekkäinen varaus.{' '}
                                {this.state.conflicts.map((c, i) => {
                                  // const cClean = c.collision.split('#')[1]
                                  const [start, end, title] = c.collision.split('-')
                                  // const [start, end, title] = c.collision.split('#')[1].split('-')
                                  return (
                                    <div key={'conf-' + i}>
                                      {moment.unix(parseInt(start)).utc().format('DD.MM.YY HH:mm')} -{' '}
                                      {moment.unix(parseInt(end)).utc().format('HH:mm')} {title}
                                    </div>
                                  )
                                })}
                              </Alert>
                            </Col>
                          </Row>
                        ) : null}
                        <Row>
                            <Col md='3'>
                              <TimeInput
                                id='starttime'
                                label='Alkuaika'
                                onChange={(value) => this.setReservationTime('start', value)}
                                value={moment.utc(this.state.reservation.resourcegroups[0]['start']).format('HH:mm')}
                                disabled={
                                  this.state.reservation.isrecurring &&
                                  (isContractCreated || !this.state.showRecurringDetails)
                                }
                                error={!isPositiveDuration}
                              />  
                            {/* <TextField
                              id='starttime'
                              label='Alkuaika'
                              type='time'
                              onChange={(e) => this.setReservationTime('start', e.target.value)}
                             value={moment.utc(this.state.reservation.resourcegroups[0]['start']).format('HH:mm')}
                              // defaultValue={
                              //   this.state.selectedResource
                              //     ? moment.utc(this.state.selectedResource.start).format('HH:mm')
                              //     : moment.utc().format('HH:mm')
                              // }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 600,
                              }}
                              disabled={
                                this.state.reservation.isrecurring &&
                                (isContractCreated || !this.state.showRecurringDetails)
                              }
                              error={!isPositiveDuration}
                            /> */}
                          </Col>
                            <Col md='3'>
                            <TimeInput
                              id='endtime'
                              label='Loppuaika'
                              type='time'
                             value={moment.utc(this.state.reservation.resourcegroups[0]['end']).format('HH:mm')}
                              onChange={(value) => this.setReservationTime('end', value)}
                              disabled={
                                this.state.reservation.isrecurring &&
                                (isContractCreated || !this.state.showRecurringDetails)
                              }
                              error={!isPositiveDuration}
                            />
                            {/* <TextField
                              id='endtime'
                              label='Loppuaika'
                              type='time'
                             value={moment.utc(this.state.reservation.resourcegroups[0]['end']).format('HH:mm')}
                              onChange={(e) => this.setReservationTime('end', e.target.value)}
                              // defaultValue={
                              //   this.state.selectedResource
                              //     ? moment.utc(this.state.selectedResource.end).format('HH:mm')
                              //     : moment.utc().format('HH:mm')
                              // }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 600,
                              }}
                              disabled={
                                this.state.reservation.isrecurring &&
                                (isContractCreated || !this.state.showRecurringDetails)
                              }
                              error={!isPositiveDuration}
                            /> */}
                          </Col>
                          <Col md='4'>
                            <TextField
                              id='reservationdate'
                              label='Päivä'
                              type='date'
                              defaultValue={thisOccurency}
                              onChange={(e) => this.setReservationDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={this.state.reservation.isrecurring}
                            />
                          </Col>
                          {this.state.checkingConflicts ? (
                            <Col md='2'>
                              <Spinner /> Tarkistetaan päällekkäisyydet{' '}
                            </Col>
                          ) : this.state.noConflicts ? (
                            <Col md='2'>
                              <small>Ei tuplia</small>
                            </Col>
                          ) : null}
                          </Row>
                          <Row>
                            <Col md={3}></Col>
                            <Col>
                              <QuickDurationSelection disabled={this.state.reservation.isrecurring && (isContractCreated || !this.state.showRecurringDetails)} options={[{ value: 30, label: '30min' }, { value: 60, label: '1h' }, { value: 90, label: '1,5h' }, { value: 120, label: '2h' }]} value={this.getDuration()} onChange={this.changeDuration} />
                            </Col>
                          </Row>
                        <Row>
                          <Col md='5'>
                            <Autocomplete
                              disabled={this.state.reservation.isrecurring && this.state.courtlocked}
                              multiple
                              options={this.state.resourceOptions || []}
                              getOptionLabel={this.handleResourceOptionLabel} // {option => option.name}
                              onChange={this.handleResourceGroup}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              value={
                                this.state?.reservation?.resourcegroups
                                  ? this.state?.reservation?.resourcegroups
                                      .map((rg) => {
                                        return rg.resources
                                      })
                                      .flat()
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='standard'
                                  label='Lisää varattava'
                                  margin='normal'
                                  fullWidth
                                />
                              )}
                            />
                          </Col>
                          <Col md='1' style={{ margin: 'auto', textAlign: 'center' }}>
                            {this.state.reservation.isrecurring ? (
                              this.state.courtlocked ? (
                                <Tooltip title='Avaa klikkaamalla vaihto-näppäin pohjassa (Shift + click)' arrow>
                                  <LockIcon fontSize={'inherit'} onClick={(e) => this.setCourtLock(false, e)} />
                                </Tooltip>
                              ) : (
                                <LockOpenIcon fontSize={'inherit'} onClick={() => this.setCourtLock(true)} />
                              )
                            ) : null}
                          </Col>
                          <Col md='2'></Col>
                            <Col md='4'>
                              <PersonAndOrganisationSearch
                                label='Lisää osakas'
                                placeholder='Osakas'
                                onChange={this.handleOwner}
                                disabled={this.state.reservation.isrecurring && this.state.courtlocked}
                                values={
                                  this.state?.reservation?.persons?.owner ? this.state.reservation.persons.owner : []
                                }
                              />

                            {/* <Autocomplete
                              multiple
                              disabled={this.state.reservation.isrecurring && this.state.courtlocked}
                              options={this.state.searchowners ? this.state.searchowners : []}
                              getOptionLabel={this.handleOptionLabel} // {option => option.name}
                              onChange={this.handleOwner}
                              getOptionDisabled={this.isValidBusiness}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              value={
                                this.state?.reservation?.persons?.owner ? this.state.reservation.persons.owner : []
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue.length > 2) {
                                  this.searchCombinedOwners(newInputValue)
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='standard'
                                  label='Lisää osakas'
                                  placeholder='Osakkaat'
                                  margin='normal'
                                  fullWidth
                                />
                              )}
                              renderOption={(option) => {
                                let title = ''
                                if (option.businessId) {
                                  title = option.name
                                } else {
                                  title =
                                    option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
                                }
                                return (
                                  <Grid container alignItems='center'>
                                    <Grid item xs>
                                      <span>{title}</span>
                                      <Typography variant='body2' color='textSecondary'>
                                        {option.email}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              }}
                            /> */}
                            {/* <Autocomplete
                              multiple
                              options={this.state.searchowners ? this.state.searchowners : []}
                              getOptionLabel={this.handleOptionLabel}
                              onChange={this.handleOwner}
                              getOptionDisabled={this.isValidBusiness}
                              getOptionSelected={(option, val) => {
                                return option.id === val.id
                              }}
                              value={
                                this.state?.reservation?.persons?.owner ? this.state.reservation.persons.owner : []
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue.length > 2) {
                                  this.searchCombinedOwners(newInputValue)
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='standard'
                                  label='Lisää osakas'
                                  placeholder='Osakas'
                                  margin='normal'
                                  fullWidth
                                />
                              )}
                            /> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col md='5'>
                            <TextField
                              id='title'
                              label='Otsikko'
                              style={{ marginBottom: 40, marginTop: 20 }}
                              fullWidth
                              value={this.state.reservation.title ? this.state.reservation.title : ''}
                              onChange={this.handleTitleChange}
                            />
                          </Col>
                          <Col md='7'>
                            <Autocomplete
                              multiple
                              options={this.state.tags ? this.state.tags.map((t) => t.category) : []}
                              onChange={this.handleTags}
                              value={this.state?.reservation?.tags ? this.state?.reservation?.tags : []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='standard'
                                  label='Lisää tagejä'
                                  placeholder='Tagit ...'
                                  margin='normal'
                                  fullWidth
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        {this.state.loadingmemberships || this.state.checkingPrice ? (
                          <Row>
                            <Col>
                              <Spinner size={'sm'} />
                            </Col>
                          </Row>
                        ) : (
                          this.renderPricingAndPayments(
                            allReadyPaid,
                            thisOccurency,
                            firstOccurency,
                            sumOpen,
                            thisOccurencyObject,
                            this.state.reservation.isrecurring,
                            isContractCreated,
                            isValidToSave,
                            isAdminOrCashier
                          )
                        )}
                      </Col>
                      <Col md='4'>
                        <div className='border rounded' style={{ padding: 5, marginBottom: 10 }}>
                          {/* <Row style={{ fontSize: 14 }}>
                            <Col>Yhteystiedot</Col>
                          </Row> */}
                          <Row style={{ fontSize: 12 }}>
                            <Col
                              onClick={() => this.handlePersonSelect({ ...this.state.reservation.persons.payers?.[0] })}
                              style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                              <b>
                                {this.state.reservation.persons.payers?.[0]?.firstname}{' '}
                                {this.state.reservation.persons.payers?.[0]?.lastname}
                              </b>
                            </Col>
                          </Row>
                          <Row style={{ fontSize: 12 }}>
                            <Col md='4'>Puhelin</Col>
                            <Col>{this.state.reservation.persons.payers?.[0]?.phone}</Col>
                          </Row>
                          <Row style={{ fontSize: 12 }}>
                            <Col md='4'>Email</Col>
                            <Col>{this.state.reservation.persons.payers?.[0]?.email}</Col>
                            </Row>
                            <Row style={{ fontSize: 12 }}>
                              <Col md='4'>Osoite</Col>
                              {this.state.userprofile?.streetaddress && this.state.userprofile?.zip ?
                                <Col>{this.state.userprofile?.streetaddress}, {this.state.userprofile?.zip}, {this.state.userprofile?.city}</Col> :
                                (this.state.userprofile ? <Col><ReportProblemOutlinedIcon fontSize='small' style={{ color: 'firebrick' }} /> Puutteelliset osoitetiedot</Col> : '')}
                            </Row>
                            <Row style={{ fontSize: 12 }}>
                              <Col md='4'>Suosikkilaji</Col>
                              <Col>{this.state.userprofile?.favoriteSport}</Col>
                            </Row>

                            <Row style={{ fontSize: 12 }}>
                            <Col>{this.state.userprofile?.isrecurring ? <Chip color="primary" style={{ margin: 2 }} size="small" label='Vakiovuoroasiakas' /> : null}
                                {' '}{this.state.userprofile?.ispastpayment ? <Chip color="primary" size="small" label='Laskutusasiakas' style={{ margin: 2 }} /> : null}
                                {' '}{this.state.userprofile?.isblocked ? <Chip color="secondary" size="small" label='Varauskielto' style={{ margin: 2 }} /> : null}</Col>
                              
                            </Row>
                            <Row style={{ fontSize: 12 }}>
                              <Col md='4'>Jäsenyydet</Col>
                              <Col>{this.state.userprofile?.memberships?.map((m) => m.name).join(' ,')}</Col>
                            </Row>
                            <Row style={{ fontSize: 12 }}>
                              <Col md='4'>Saldot</Col>
                            </Row>
                            {this.renderSaldos()}
                       </div>
                        {this.state?.reservation?.parentid ? (
                          <Row>
                            <Col>
                              Tämä on siirretty vakiovuoro{' '}
                              <OpenInNewIcon style={{ marginTop: 5 }} fontSize={'small'} color={'primary'} />
                            </Col>
                          </Row>
                        ) : null}
                        {this.state.reservation.isrecurring ? (
                          <Row>
                            <Col className='text-right'>
                              <Button
                                size={'sm'}
                                color={this.state.showallnotes ? 'secondary' : 'link'}
                                onClick={() => this.setState({ showallnotes: true })}
                              >
                                Kaikki
                              </Button>
                              <Button
                                size={'sm'}
                                color={this.state.showallnotes ? 'link' : 'secondary'}
                                onClick={() => this.setState({ showallnotes: false })}
                              >
                                Vuoro
                              </Button>
                            </Col>
                          </Row>
                        ) : null}
                        <Row style={{ marginBottom: 10 }}>
                          <Col>
                            {this.state.reservation.isrecurring && this.state.showallnotes ? (
                              <Row key={'note-otsikko'}>
                                <Col className={'text-secondary'}></Col>
                                <Col md='2' className={'text-secondary'}>
                                  Vuoro
                                </Col>
                                <Col md='2' className={'text-secondary'}>
                                  Lisätty
                                </Col>
                                <Col md='1' className={'text-secondary'}></Col>
                              </Row>
                            ) : null}

                            {this.state.reservation.notes
                              ? this.state.reservation.notes
                                  .filter((x) => this.state.showallnotes || x.all || thisOccurency === x.occurency)
                                  .map((note) => {
                                    // let isNoteForThisOccurency = thisOccurency === note.occurency
                                    return (
                                      <Row key={note.id}>
                                        <Col className={'text-info'}>{note.note}</Col>
                                        {this.state.showallnotes ? (
                                          <Col md='2' className={'text-info'}>
                                            {!note.all
                                              ? moment
                                                  .utc(note.occurency, 'YYYY-MM-DD')
                                                  .add(TIMEZONE_OFFSET, 'minutes')
                                                  .format('D.M.YY')
                                              : ''}
                                          </Col>
                                        ) : null}
                                        <Col md='2' className={'text-info'}>
                                          {moment
                                            .utc(note.timestamp, 'YYYY.MM.DD HH:mm')
                                            .add(TIMEZONE_OFFSET, 'minutes')
                                            .format('D.M. HH:mm')}
                                        </Col>
                                        <Col md='1'>
                                          <EditIcon
                                            color='action'
                                            fontSize='small'
                                            onClick={() => this.onEditNote(note.id)}
                                          />
                                        </Col>
                                      </Row>
                                    )
                                  })
                              : null}
                          </Col>
                        </Row>
                        {this.state.showAddNoteRow ? (
                          <>
                            <Row>
                              <Col md='12'>
                                <TextField
                                  id='note'
                                  type='text'
                                  label='Viesti'
                                  fullWidth
                                  value={this.state.notetext || ''}
                                  onChange={this.handleNote}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {' '}
                                {this.state.reservation.isrecurring ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.noteforall}
                                        onChange={this.setNoteforAll}
                                        name='noteforall'
                                        color='primary'
                                      />
                                    }
                                    label='Kaikkiin vuoroihin'
                                  />
                                ) : null}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: 20 }}>
                              <Col md='12'>
                                <Button
                                  size={'sm'}
                                  outline
                                  color='success'
                                  disabled={!this.state.notetext}
                                  onClick={this.onSaveNote}
                                >
                                  {this.state.editnoteid ? 'Tallenna' : 'Lisää'}
                                </Button>
                                <Button size={'sm'} outline color='primary' onClick={this.toggleAddNote}>
                                  Peruuta
                                </Button>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Row>
                            <Col md='3'>
                              <Avatar onClick={this.toggleAddNote} style={{ height: 30, width: 30, cursor: 'pointer' }}>
                                <AddIcon />
                              </Avatar>
                            </Col>
                            <Col>
                              <div style={{ marginTop: 5 }}>Lisää muistiinpano</div>
                            </Col>
                          </Row>
                          )}
                          <hr style={{ marginBottom: 0}}/>
                          
                          <Row>
                            <Col xs={12} style={{ height: 30 }}>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.showManualAccessCode || (this.state.reservation.accesscode ? true : false)}
                                      onChange={this.onManualAccessCodeVisibilityChange}
                                      name='manualaccesscodevisibility'
                                      color='primary'
                                    />
                                  }
                                  label={<small style={{ fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif' }}>Käsin ohjelmoitu PIN-koodi</small>}
                              />
                            </Col>
                            {this.state.showManualAccessCode || (this.state.reservation.accesscode ? true : false) ? (
                            <Col>
                              <TextField
                                id='manualaccesscode'
                                label='PIN-koodi'
                                fullWidth
                                value={this.state.reservation.accesscode || ''}
                                  onChange={this.handleAccessCodeChange}
                              />
                              </Col>
                            ) : null}
                          </Row>
                          <hr />
                          {this.state.featureOptions && this.state.featureOptions.matchattaching ? (
                          <Row>
                          <Col>
                                <MatchDetails match={this.state.reservation.match} enabled={this.state.featureOptions && this.state.featureOptions.matchattaching} isAttached={this.state.reservation.matchreservation} />
                              </Col>
                              </Row>
                            ) : null}
                          {/* <Row style={{ marginTop: 15 }}>
                            {this.state.reservation.activityreservation && this.state.featureOptions && this.state.featureOptions.activityattaching ? (
                              <Col>
                              </Col>
                            ) : (
                              <>
                                <Col xs='1'>
                                  <Avatar onClick={this.toggleAddNote} style={{ cursor:'pointer', height: 20, width: 20 }}>
                                    <AddIcon />
                                  </Avatar>
                                </Col>
                                <Col>
                                  <div display='inline' style={{ marginTop: 0 }}>Kiinnitä aktiviteetti</div>
                                </Col>
                              </>
                            )}
                          </Row> */}
                          {this.state.featureOptions && (this.state.featureOptions.activityattaching || this.state.featureOptions.matchattaching) ? (<hr />) : null}
                        <Row>
                          {(this.state.reservation.isrecurring && this.state.showRecurringDetails) ||
                          !this.state.reservation.isrecurring ? (
                            <Col>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.reservation.isrecurring}
                                    onChange={this.onRecurringChange}
                                    name='recurring'
                                    color='primary'
                                  />
                                }
                                disabled={isContractCreated && !this.props.enableEdit}
                                label='Toistuva / Vakio'
                              />
                            </Col>
                          ) : null}
                          {this.state.reservation.isrecurring && !this.state.showRecurringDetails ? (
                            <Col>
                              <Button onClick={() => this.handleShowRecurringDetailsChange(true)}>
                                Muokkaa vakiovarausta
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                        <Collapse isOpen={this.state.reservation.isrecurring && this.state.showRecurringDetails}>
                          <Row className='recurrentrow'>
                            <Col md='4'>
                              <FormControl>
                                <InputLabel id='recurrency-label'>Toistuvuus</InputLabel>
                                <Select
                                  labelId='recurrency'
                                  id='recurrency'
                                  value={this.state.reservation.recurrencyInterval || 'weekly'}
                                  onChange={this.handleRecurringIntervalChange}
                                  disabled={isContractCreated}
                                >
                                  <MenuItem value={'weekly'}>Viikottain</MenuItem>
                                  <MenuItem value={'daily'}>Päivittäin</MenuItem>
                                  <MenuItem value={'monthly'}>Kuukausittain</MenuItem>
                                </Select>
                              </FormControl>
                            </Col>
                            <Col md='8'>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.reservation.seasonbilling}
                                    onChange={this.onSeasonBillingChange}
                                    name='seasonbilling'
                                    color='primary'
                                  />
                                }
                                label='Kausilaskutus'
                              />
                            </Col>
                          </Row>
                          <Row className='recurrentrow'>
                            <Col md='6'>
                              <TextField
                                id='recurstart'
                                label='Alkupäivä'
                                type='date'
                                defaultValue={
                                  this.state.reservation.recurrencyStartDate
                                    ? moment.utc(this.state.reservation.recurrencyStartDate).format('YYYY-MM-DD')
                                    : thisOccurency
                                }
                                onChange={this.setRecurrencyStart}
                                disabled={isContractCreated && !this.props.enableEdit}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!this.state.recurrencyStartValid}
                              />
                            </Col>
                            {/* <Col md='1'>Päättyy</Col> */}
                            <Col md='6'>
                              <TextField
                                id='recurstop'
                                label='Päättymispäivä'
                                type='date'
                                defaultValue={
                                  this.state.reservation.recurrencyEndDate
                                    ? moment.utc(this.state.reservation.recurrencyEndDate).format('YYYY-MM-DD')
                                    : ''
                                }
                                onChange={this.setRecurrencyEnd}
                                disabled={isContractCreated && !this.props.enableEdit}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={!this.state.recurrencyEndValid}
                              />
                            </Col>
                          </Row>
                          <Row className='recurrentrow'>
                            {/* <Col md='1'></Col> */}
                            <Col md='6'>
                              <TextField
                                id='recurtimes'
                                label='Kerrat'
                                type='number'
                                disabled
                                value={
                                  this.state.reservation.recurrencyDates
                                    ? this.state.reservation.recurrencyDates.length
                                    : 0
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={this.setRecurrencyCount}
                              />
                            </Col>
                          </Row>
                          {this.renderContract()}
                        </Collapse>
                        {/* Tähän listattaan recurrencyt */}
                        {this.state.showRecurringDetails ? (
                          <div
                            className='border rounded overflow-auto'
                            style={this.state.reservation.isrecurring ? { height: 300, padding: 5 } : { height: 0 }}
                          >
                            <Row>
                              <Col style={{ fontSize: 14, fontWeight: 700 }}>Vuorot</Col>
                            </Row>
                            <Row>
                              <Col>
                                {this.state.reservation.isrecurring && this.state.reservation.recurrencyDates
                                  ? this.state.reservation.recurrencyDates.map((d, i) => {
                                      return (
                                        <Row
                                          key={'rec-' + i}
                                          style={{ marginLeft: 0, marginRight: 0 }}
                                          className='border'
                                        >
                                          <Col md='4' style={{ fontSize: 14, paddingTop: 6 }}>
                                            {moment.utc(d.date).format('D.M.YY')}
                                          </Col>
                                          <Col md='8'>
                                            <FormControl>
                                              <Select
                                                labelId='reccurrencyoccurency'
                                                id={'reccurrencyoccurency' + d.date}
                                                value={d.state || 'reserved'}
                                                onChange={(e) => this.handleOccurencyStateChange(e, d)}
                                                disabled={d.state === 'moved'}
                                              >
                                                <MenuItem value={'reserved'}>Varattu</MenuItem>
                                                <MenuItem value={'onsale'}>Myynnissä</MenuItem>
                                                {!(
                                                  (this.props.profile.role === 'PARTNER' || this.props.profile.role === 'COOPERATIVE') &&
                                                  this.state.reservation.isrecurring
                                                ) ? (
                                                  <MenuItem value={'cancelled'}>Peruttu</MenuItem>
                                                ) : null}
                                                <MenuItem disabled={true} value={'moved'}>
                                                  Siirretty
                                                </MenuItem>
                                              </Select>
                                            </FormControl>{' '}
                                            {d.state === 'moved' ? (
                                              <OpenInNewIcon
                                                style={{ marginTop: 5 }}
                                                fontSize={'small'}
                                                color={'primary'}
                                              />
                                            ) : null}
                                          </Col>
                                        </Row>
                                      )
                                    })
                                  : null}
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              )}
              <ModalFooter>
                <div className='mr-auto'>
                  {' '}
                  {this.state.reservation.state === 'cancelled' && this.state.reservation.isrecurring ? (
                    <Button
                      color='success'
                      onClick={() => this.setState({ showCancelAllConfirm: true })}
                      disabled={!isValidToSave || this.state.checkingPrice}
                    >
                      Tallenna
                    </Button>
                  ) : this.state.conflicts ? (
                    <Button
                      color='success'
                      onClick={() => this.setState({ showOverbookingConfirm: true })}
                      disabled={!isValidToSave || this.state.checkingPrice}
                    >
                      Tallenna
                    </Button>
                  ) : this.state.showMessageSendingDialog ? (
                    <Button
                      color='success'
                      onClick={() =>
                        this.state.sendConfirmationWhenSave
                          ? this.setState({ saveAfterSend: true }, this.toggleMessage)
                          : this.onReservationSave()
                      }
                      disabled={!isValidToSave || this.state.checkingPrice}
                    >
                      {this.state.savePressed ? <Spinner size={'sm'} /> : 'Tallenna'}
                    </Button>
                  ) : (
                    <Button color='success' onClick={this.onReservationSave} disabled={!isValidToSave}>
                      {this.state.savePressed ? <Spinner size={'sm'} /> : 'Tallenna'}
                    </Button>
                  )}
                </div>
                <div className='mr-auto'>
                  {' '}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.sendConfirmationWhenSave}
                        onChange={this.setConfirmation}
                        name='sendConfirmationWhenSave'
                        color='primary'
                      />
                    }
                    label='Lähetä vahvistusviesti'
                  />
                </div>
                <div>
                  {' '}
                  {this.state.reservation.isrecurring && this.state.reservation.id ? (
                    <>
                      {this.state.showRecurringDetails ? (
                        <Button color='info' onClick={this.onCopyPressed}>
                          Kopioi vakiovuoro
                        </Button>
                      ) : (
                        <Button color='info' onClick={this.onMovePressed}>
                          Siirrä tämä vuoro
                        </Button>
                      )}
                    </>
                  ) : null}{' '}
                  {!this.state.reservation.isrecurring && this.state.reservation.id ? (
                    <>
                      <Button color='info' onClick={this.onCopyPressed}>
                        Kopioi vuoro
                      </Button>
                      <Button color='info' onClick={this.onSplitPressed}>
                        Jaa vuoro
                      </Button>
                    </>
                  ) : null}
                  {this.state.reservation.id ? (
                    <>
                      <Button color='warning' onClick={this.toggleDiffs}>
                        Näytä muutokset
                      </Button>{' '}
                      <Button
                        color='warning'
                        onClick={this.toggleMessage}
                        disabled={!(this.state.reservation.persons.payers.length > 0)}
                      >
                        Lähetä viesti
                      </Button>{' '}
                    </>
                  ) : null}
                  {/* <Button color='warning' onClick={this.showMaksukoodi}>
                    Näytä maksu koodi
                  </Button>{' '} */}
                  <BugReportIcon style={{ fill: '#fdfdfa' }} fontSize='small' onClick={() => this.toggeleShowDebug()} />
                </div>
              </ModalFooter>
            </Modal>
          </Draggable>
          {this.state.showCancelAllConfirm ? (
            <Dialog
              open={this.state.showCancelAllConfirm}
              keepMounted
              onClose={() => this.setState({ showCancelAllConfirm: !this.state.showCancelAllConfirm })}
              aria-labelledby='alert-dialog-slide-title'
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle id='alert-dialog-slide-title'>{'Vakiovuoron kaikkien vuorojen peruminen'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                  Oletko aivan varma, että haluat peruuttaa tämän vakiovuoron kaikki kerrat? Tämä peruuttaa koko
                  vakiovuoron. Jos haluat peruuttaa yhden, niin se löytyy oikealta alhaalta.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color={'secondary'}
                  onClick={() => this.setState({ showCancelAllConfirm: !this.state.showCancelAllConfirm })}
                >
                  Ei peruta vuoroa
                </Button>
                <Button color={'danger'} onClick={() => this.onReservationSave()}>
                  Kyllä, peruutetaan kaikki!
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}

          {this.state.showOverbookingConfirm ? (
            <Dialog
              open={this.state.showOverbookingConfirm}
              keepMounted
              onClose={() => this.setState({ showOverbookingConfirm: !this.state.showOverbookingConfirm })}
              aria-labelledby='alert-dialog-slide-title'
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle id='alert-dialog-slide-title'>{'Tuplavaraus'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                  Oletko aivan varma, että haluat varata vaikka varaus aiheuttaa tuplavarauksen?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color={'secondary'}
                  onClick={() => this.setState({ showOverbookingConfirm: !this.state.showOverbookingConfirm })}
                >
                  Peruuta
                </Button>
                <Button color={'danger'} onClick={() => this.onReservationSave()}>
                  Kyllä, varataan.
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}

          <Dialog
            open={this.state.copyInProgress}
            keepMounted
            onClose={() => this.setState({ copyInProgress: false })}
            aria-labelledby='copy-dialog-slide-title'
            aria-describedby='copy-dialog-slide-description'
          >
            <DialogTitle id='copy-dialog-slide-title'>Kopioi</DialogTitle>
            <DialogContent>
              <CircularProgress color='inherit' />
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.showRainCancellationDialog}
            keepMounted
            onClose={() => this.setState({ showRainCancellationDialog: false })}
            aria-labelledby='rain-dialog-slide-title'
            aria-describedby='rain-dialog-slide-description'
          >
            <DialogTitle id='rain-dialog-slide-title'>Perutaanko sateen takia</DialogTitle>
            <DialogActions>
              <Button color={'secondary'} onClick={() => this.setState({ showRainCancellationDialog: false })}>
                Peruuta
              </Button>
              <Button color={'success'} onClick={this.storeRainCancellation}>
                Tee sadeperuutus
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.showSplitDialog}
            keepMounted
            onClose={() => this.setState({ showSplitDialog: false })}
            aria-labelledby='split-dialog-slide-title'
            aria-describedby='split-dialog-slide-description'
          >
            <DialogTitle id='split-dialog-slide-title'>Jaa vuoro</DialogTitle>
            <DialogContent>
              Mistä ajasta vuoro katkaistaan?
              <br />
              <FormControl style={{ minWidth: 120 }}>
                <InputLabel id='splittime'>Aika</InputLabel>
                <Select
                  labelId='splittime-label'
                  id='splittime-select'
                  value={this.state.split || ''}
                  onChange={this.handleSplitChange}
                >
                  <MenuItem key={'none'}></MenuItem>
                  {this.state.possiblesplits.map((pt) => {
                    return (
                      <MenuItem key={pt.value} value={pt.value}>
                        {pt.label}
                      </MenuItem>
                    )
                  })}
                </Select>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  Koko varauksen hinta {this.state.reservation.resourcegroups[0].overridecost ? this.state.reservation.resourcegroups[0].overridecost : this.state.reservation.resourcegroups[0].cost} €
                </div>
                <div>{this.state.checkingPrice && (<><Spinner size={'sm'} /> Tarkistetaan hintoja</>)}</div>
                <Label for='splitOne'>Ensimmäisen osan hinta</Label>
                <Input
                  id='splitOne'
                  value={this.state.splitPrices ? this.state.splitPrices[0] : 0}
                  name='splitOne'
                  onChange={(e) => this.handleSplitPriceChange(e, 0)}
                />
                {(this.state.splitPriceErrors && this.state.splitPriceErrors[0]) && (<Typography variant='caption'>{this.state.splitPriceErrors[0]}</Typography>)}
                {(this.state.splitSuccestions && this.state.splitSuccestions[0]) && (<><Typography variant='caption'>Hinnasto: {this.state.splitSuccestions[0].pricing}</Typography><Typography variant='caption'>Tasajako: {this.state.splitSuccestions[0].originalSplit}</Typography></>)}
                <Label for='splitTwo'>Toisen osan hinta</Label>
                <Input
                  id='splitTwo'
                  value={this.state.splitPrices ? this.state.splitPrices[1] : 0}
                  name='splitTwo'
                  onChange={(e) => this.handleSplitPriceChange(e, 1)}
                />
                {(this.state.splitPriceErrors && this.state.splitPriceErrors[1]) && (<Typography variant='caption'>{this.state.splitPriceErrors[1]}</Typography>)}
                {(this.state.splitSuccestions && this.state.splitSuccestions[1]) && (<><Typography variant='caption'>Hinnasto: {this.state.splitSuccestions[1].pricing}</Typography><Typography variant='caption'>Tasajako: {this.state.splitSuccestions[1].originalSplit}</Typography></>)}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button color={'secondary'} onClick={() => this.setState({ showSplitDialog: false })}>
                Peruuta
              </Button>
              <Button
                disabled={!(this.state.splitPrices && this.state.splitPrices.every((x) => x) && this.state.split)}
                color={'success'}
                onClick={this.onSplitSaved}
              >
                Jaa vuoro
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.showUnsavedConfirmation}
            keepMounted
            onClose={() => this.setState({ showUnsavedConfirmation: false })}
            aria-labelledby='unsave-dialog-slide-title'
            aria-describedby='unsave-dialog-slide-description'
          >
            <DialogTitle id='unsave-dialog-slide-title'>Muutoksia ei ole tallennettu</DialogTitle>
            <DialogContent>Tekemiäsi muutoksia ei ole tallennettu.</DialogContent>
            <DialogActions>
              <Button
                color={'danger'}
                onClick={() =>
                  this.setState({ showUnsavedConfirmation: false, savedAfterChange: true }, this.toggleClose)
                }
              >
                Ei tallenneta
              </Button>
              <Button color={'success'} onClick={() => this.setState({ showUnsavedConfirmation: false })}>
                Palaa tallentamaan
              </Button>
            </DialogActions>
          </Dialog>

          <SaldopaymentDialog
            open={this.state.showSaldopaymentDialog}
            onClose={() => this.setState({ showSaldopaymentDialog: false, savingSaldoPayment: false })}
            onSave={this.handleSavedSaldoPayment}
            reservation={this.state.reservation}
            occurency={this.state.selectedResource ? moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')}
          />
0
          {this.state.showMessageConfirmationModal ? (
            <Dialog
              open={this.state.showMessageConfirmationModal}
              keepMounted
              onClose={() => this.setState({ showMessageConfirmationModal: !this.state.showMessageConfirmationModal })}
              aria-labelledby='message-dialog-slide-title'
              aria-describedby='message-dialog-slide-description'
            >
              <DialogTitle id='message-dialog-slide-title'>{'Viesti'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='message-dialog-slide-description'>
                  Lähetäänkö asiakkaalle viesti?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color={'secondary'} onClick={() => this.onReservationSave()}>
                  Ei lähetetä
                </Button>
                <Button
                  color={'success'}
                  onClick={() =>
                    this.setState(
                      { showMessageConfirmationModal: !this.state.showMessageConfirmationModal, saveAfterSend: true },
                      this.toggleMessage
                    )
                  }
                >
                  Kyllä, lähetetään.
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
          <Modal isOpen={this.state.showDiffs} toggle={this.toggleDiffs} size='xl'>
            <ModalHeader toggle={this.toggleDiffs}>Muutokset</ModalHeader>
            <ModalBody>
              {this.state.diffs ? (
                <>
                  <Row>
                    <Col>{this.state.formattedDiffs}</Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <JSONPretty id='json-pretty-diffs' data={this.state.diffs}></JSONPretty>
                    </Col>
                  </Row> */}
                </>
              ) : (
                <div>Loading ...</div>
              )}
            </ModalBody>
          </Modal>

          <MessageModal
            open={this.state.showMessageModal}
            onClose={this.toggleMessage}
            onSend={this.sendMessage2}
            messagetemplates={this.state.messagetemplates}
            initName={this.state.messageTemplateName}
            reservation={this.state.reservation}
            occurency={this.state.selectedResource ? moment.utc(this.state.selectedResource.start).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD')}
          />
          

          {/* <Modal isOpen={this.state.showMessageModal} toggle={this.toggleMessage} size='lg'>
            <ModalHeader toggle={this.toggleMessage}>Viesti</ModalHeader>
            <ModalBody>
              <div>
                <Label for='messagetemplates'>Viestipohjat </Label>
              </div>
              <ButtonGroup className='flex-wrap' id='messagetemplates'>
                <Button
                  key='cancelmessage'
                  className='btn-round'
                  color='info'
                  outline
                  type='button'
                  onClick={() => this.updateMessageTemplate('cancelmessage')}
                  active={this.state.messageTemplateName === 'cancelmessage'}
                >
                  Peruutus
                </Button>
                <Button
                  key='movemessage'
                  className='btn-round'
                  color='info'
                  outline
                  type='button'
                  onClick={() => this.updateMessageTemplate('movemessage')}
                  active={this.state.messageTemplateName === 'movemessage'}
                >
                  Siirto
                </Button>
                <Button
                  key='reservationmessage'
                  className='btn-round'
                  color='info'
                  outline
                  type='button'
                  onClick={() => this.updateMessageTemplate('reservationmessage')}
                  active={this.state.messageTemplateName === 'reservationmessage'}
                >
                  Varaus
                </Button>
              </ButtonGroup>
              <Form>
                <FormGroup>
                  <Label for='exampleEmail'>Otsikko</Label>
                  <Input
                    id='messageSubject'
                    value={this.state.messageSubject ? this.state.messageSubject : ''}
                    name='messageSubject'
                    onChange={this.handleMessageSubjectChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='messageBody'>Viesti</Label>
                  <Input
                    rows='15'
                    value={this.state.messageBody ? this.state.messageBody : ''}
                    id='messageBody'
                    name='messageBody'
                    type='textarea'
                    style={{ maxHeight: 400 }}
                    onChange={this.handleMessageBodyChange}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color='primary' onClick={this.sendMessage}>
                {this.state.sendMessagePressed ? <Spinner size={'sm'} /> : 'Lähetä'}
              </Button>
            </ModalFooter>
          </Modal> */}
        </div>
      )
    } else {
      return <></>
    }
  }
}

// EXPORT
export default withAuthentication(withFirebase(ReservationModal))
