import React, { Component } from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@material-ui/core'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { Row, Col, Button, Spinner } from 'reactstrap'
import Infobox from '../Infos/Infobox'

const REPORTS_WITH_COMPARISON = ['busy', 'busyraw']

const getTimeSteps = (step) => {
  const times = []
  let tt = 0 // start time
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    var mm = tt % 60 // getting minutes of the hour in 0-55 format
    times[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + step
  }
  times.push('23:59')
  return times
}

const getPrevDates = (start, end) => {
  const startdate = moment.utc(start)
  const enddate = moment.utc(end)
  let diff = enddate.diff(startdate, 'days')
  if (diff < 1) {
    diff = 1
  } else {
    diff = diff + 1
  }

  return [startdate.subtract(diff, 'days'), enddate.subtract(diff, 'days')]
}

class ReportsTimePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      report: this.props.report,
      loading: this.props.loading,
      venues: this.props.venues,
      selectedVenue: '',
      startdate: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      enddate: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      starttime: '06:00',
      endtime: '23:00',
      comparisonstartdate: moment
        .utc()
        .subtract(2, 'days')
        .format('YYYY-MM-DD'),
      comparisonenddate: moment.utc().subtract(2, 'days').format('YYYY-MM-DD'),
      comparisonstarttime: '06:00',
      comparisonendtime: '23:00',
      comparison: false,
      allvalid: true,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.setState({ report: this.props.report })
    }
    if(prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading })
    }
  }

  getReport = () => {
    const report = {
      report: this.state.report,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      starttime: this.state.starttime,
      endtime: this.state.endtime,
      comparison: this.state.comparison,
      comparisonstartdate: this.state.comparisonstartdate,
      comparisonenddate: this.state.comparisonenddate,
      comparisonstarttime: this.state.comparisonstarttime,
      comparisonendtime: this.state.comparisonendtime,
      venue: this.state.selectedVenue,
    }
    this.props.onSubmit(report)
  }

  setStartDate = (e) => {
    this.setState({ startdate: e.target.value })
  }

  setEndDate = (e) => {
    this.setState({ enddate: e.target.value })
  }

  setStarttime = (e) => {
    this.setState({ starttime: e.target.value })
  }

  setEndtime = (e) => {
    this.setState({ endtime: e.target.value })
  }

  setVenue = (e) => {
    this.setState({ selectedVenue: e.target.value })
  }

  setComparisonStartDate = (e) => {
    const comparisonstartdate = e.target.value
    const allvalid = moment.utc(comparisonstartdate).isSameOrBefore(moment.utc(this.state.enddate))
    this.setState({ comparisonstartdate, allvalid })
  }

  setComparisonEndDate = (e) => {
    const comparisonenddate = e.target.value
    console.log(comparisonenddate, this.state.comparisonstartdate )
    const allvalid = moment.utc(moment.utc(this.state.comparisonstartdate)).isSameOrBefore(comparisonenddate) && moment.utc(comparisonenddate).isSameOrBefore(moment.utc(this.state.startdate))
    this.setState({ comparisonenddate, allvalid })
  }

  setComparisonStarttime = (e) => {
    const comparisonstarttime = e.target.value
    this.setState({ comparisonstarttime })
  }

  setComparisonEndtime = (e) => {
    const comparisonendtime = e.target.value
    this.setState({ comparisonendtime })
  }
  setComparison = (e) => {
    if (e.target.checked) {
      const [startdate, enddate] = getPrevDates(
        this.state.startdate,
        this.state.enddate
      )
      console.log(this.state.startdate, this.state.enddate)
      console.log(startdate.format('YYYY-MM-DD'), enddate.format('YYYY-MM-DD'))
      this.setState({
        comparison: e.target.checked,
        comparisonstartdate: startdate.format('YYYY-MM-DD'),
        comparisonenddate: enddate.format('YYYY-MM-DD'),
      })
    } else {
      this.setState({ comparison: e.target.checked })
    }
  }

  render() {
    return (
      <>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Row>
            <Col xs={6} md={6}>
              {this.state.report !== 'persons' && (
                <TextField
                  id='recurstart'
                  label='Alkupäivä'
                  type='date'
                  // defaultValue={this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : ''}
                  value={
                    this.state.startdate
                      ? moment.utc(this.state.startdate).format('YYYY-MM-DD')
                      : moment.utc().subtract(1, 'day').format('YYYY-MM-DD')
                  }
                  onChange={this.setStartDate}
                  style={{ marginRight: 25 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {/* </Col>
            <Col> */}
              {this.state.report !== 'persons' && (
                <TextField
                  id='recurstart'
                  label='Loppupäivä'
                  type='date'
                  value={
                    this.state.enddate
                      ? moment.utc(this.state.enddate).format('YYYY-MM-DD')
                      : moment.utc().format('YYYY-MM-DD')
                  }
                  onChange={this.setEndDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginRight: 25 }}
                />
              )}
              {/* </Col> */}

              {this.state.venues && this.state.venues.length > 0 ? (
                <Col>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='venue'>Paikka</InputLabel>
                    <Select
                      fullWidth
                      labelId='venue-label'
                      id='venue'
                      value={this.state.selectedVenue || ''}
                      onChange={this.setVenue}
                    >
                      {this.state.venues.map((v, i) => {
                        return (
                          <MenuItem
                            key={v.name.toLowerCase()}
                            value={v.name.toLowerCase()}
                          >
                            {v.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Col>
              ) : null}
              {this.state.report === 'busy' ||
              this.state.report === 'busyraw' ? (
                <>
                  {/* <Col> */}
                  <FormControl>
                    <InputLabel id='starttime-select-label'>
                      Alkuaika
                    </InputLabel>
                    <Select
                      labelId='starttime-select-label'
                      id='starttime-select-label'
                      value={this.state.starttime || '07:00'}
                      onChange={this.setStarttime}
                    >
                      {getTimeSteps(15).map((v, i) => {
                        return (
                          <MenuItem key={v} value={v}>
                            {v}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>{' '}
                  <FormControl>
                    <InputLabel id='endttime-select-label'>
                      Loppuaika
                    </InputLabel>
                    <Select
                      labelId='endttime-select-label'
                      id='endttime-select-label'
                      value={this.state.endtime || '23:00'}
                      onChange={this.setEndtime}
                    >
                      {getTimeSteps(15).map((v, i) => {
                        return (
                          <MenuItem key={v} value={v}>
                            {v}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  {/* </Col> */}
                </>
              ) : null}
            </Col>
          </Row>

          {REPORTS_WITH_COMPARISON.includes(this.state.report) ? (
            <div>
              {this.state.comparison && <Divider style={{ marginTop: 20 }} />}
              <Row>
                <Col xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.comparison}
                        onChange={this.setComparison}
                        name='comparison'
                        color='primary'
                      />
                    }
                    label='Vertaa aiempaan aikaväliin'
                  />
                </Col>
              </Row>
              {this.state.comparison && (
                <>
                  <Row>
                    <Col xs={6} md={6}>
                      {this.state.report !== 'persons' && (
                        <TextField
                          id='comparisonstart'
                          label='Alkupäivä'
                          type='date'
                          value={
                            this.state.comparisonstartdate
                              ? moment
                                  .utc(this.state.comparisonstartdate)
                                  .format('YYYY-MM-DD')
                              : moment
                                  .utc()
                                  .subtract(1, 'day')
                                  .format('YYYY-MM-DD')
                          }
                          onChange={this.setComparisonStartDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ marginRight: 25 }}
                        />
                      )}
                      {/* </Col>
                    <Col> */}
                      {this.state.report !== 'persons' && (
                        <TextField
                          id='comparisonend'
                          label='Loppupäivä'
                          type='date'
                          value={
                            this.state.comparisonenddate
                              ? moment
                                  .utc(this.state.comparisonenddate)
                                  .format('YYYY-MM-DD')
                              : moment.utc().format('YYYY-MM-DD')
                          }
                          onChange={this.setComparisonEndDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ marginRight: 25 }}
                        />
                      )}
                      {this.state.report === 'busy' ||
                      this.state.report === 'busyraw' ? (
                        // <Row>
                        //   <Col>
                        <>
                          <FormControl>
                            <InputLabel id='comparison-starttime-select-label'>
                              Alkuaika
                            </InputLabel>
                            <Select
                              labelId='comparison-starttime-select-label'
                              id='comparison-starttime-select-label'
                              value={this.state.comparisonstarttime || '06:00'}
                              onChange={this.setComparisonStarttime}
                            >
                              {getTimeSteps(15).map((v, i) => {
                                return (
                                  <MenuItem key={v} value={v}>
                                    {v}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>{' '}
                          <FormControl>
                            <InputLabel id='comparison-endttime-select-label'>
                              Loppuaika
                            </InputLabel>
                            <Select
                              labelId='comparison-endttime-select-label'
                              id='comparison-endttime-select-label'
                              value={this.state.comparisonendtime || '23:00'}
                              onChange={this.setComparisonEndtime}
                            >
                              {getTimeSteps(15).map((v, i) => {
                                return (
                                  <MenuItem key={v} value={v}>
                                    {v}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </>
                      ) : //   </Col>
                      // </Row>
                      null}
                    </Col>
                  </Row>
                </>
              )}
              {this.state.comparison && <Divider style={{ marginTop: 20 }} />}
            </div>
          ) : null}
          <Row>
            <Col xs={2}>
              <Button
                disabled={
                  !(
                    this.state.startdate &&
                    this.state.enddate &&
                    this.state.report &&
                    this.state.allvalid
                  )
                }
                onClick={() => this.getReport()}
              >
                {this.state.loading ? <Spinner size={'sm'} /> : 'Hae raportti'}
              </Button>
            </Col>
            {this.state.report === 'payments' &&
              <Col>
                <Infobox index={0} />
              </Col>
            }
          </Row>
        </MuiPickersUtilsProvider>
      </>
    )
  }
}

export default ReportsTimePicker
