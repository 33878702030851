import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import PivotTable from 'react-pivottable/PivotTable'
import 'react-pivottable/pivottable.css'
import TableRenderers from 'react-pivottable/TableRenderers'
import Plot from 'react-plotly.js'
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers'

const PlotlyRenderers = createPlotlyRenderers(Plot)


  

class PivotTableUISmartWrapper extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { pivotState: props }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ pivotState: nextProps })
  }

  updateReportConfig = (config) => {
    // console.log(config)
    // this.setState({ pivotState: config })
    this.props.onChange(config)
  }

  render() {
    if (this.state.pivotState.mode === 'edit') {
      return (
        <PivotTableUI
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...this.state.pivotState}
          onChange={(s) => this.updateReportConfig(s)}
          unusedOrientationCutoff={Infinity}
        />
      )
    } else {
      return (
        <PivotTable
          {...this.state.pivotState}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          unusedOrientationCutoff={Infinity}
        />
      )
    }
  }
}

var tableToExcel = (function() {
  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
  return function(table, name) {
    if (!table.nodeType) table = document.getElementsByClassName(table)[0]
    var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
    window.location.href = uri + base64(format(template, ctx))
  }
})()

class Pivoting extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     rows: [],
  //   }
  // }


  componentWillMount() {
    this.setState({
      report: this.props.report,
      mode: 'view',
      data: [],
      rows: [],
      cols: [],
      aggregatorName: 'Sum',
      vals: ['€'],
      rendererName: 'Table',
      // sorters: {
      //     Meal: sortAs(['Lunch', 'Dinner']),
      //     'Day of Week': sortAs([
      //         'Thursday',
      //         'Friday',
      //         'Saturday',
      //         'Sunday',
      //     ]),
      // },
      plotlyOptions: { width: 900, height: 500 },
      plotlyConfig: {},
      // tableOptions: {
      //     clickCallback: function(e, value, filters, pivotData) {
      //         var names = [];
      //         pivotData.forEachMatchingRecord(filters, function(
      //             record
      //         ) {
      //             names.push(record.Meal);
      //         });
      //         alert(names.join('\n'));
      //     },
      // },
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      // console.log('rows changed', this.props.rows)
      this.setState({ data: this.props.rows })
    }
  }

  bySport = () => {
    this.setState({
      rows: ['Laji'],
      cols: [],
    })
  }

  bySportAndDay = () => {
    this.setState({
      cols: ['Laji'],
      rows: ['Käyttöpäivä'],
    })
  }

  bySportAndDayAndChannel = () => {
    this.setState({
      cols: ['Laji', 'Kanava'],
      rows: ['Käyttöpäivä'],
    })
  }

  byPaymentdayAndChannel = () => {
    this.setState({
      cols: ['Kanava'],
      rows: ['Maksupäivä'],
    })
  }

  byDay = () => {
    this.setState({
      rows: ['Päivä'],
      cols: [],
    })
  }

  byEmailNameAndDay = () => {
    this.setState({
      rows: ['Email','Nimi'],
      cols: ['Päivä'],
    })
  }

  setEditMode = () => {
    this.setState({ mode: this.state.mode === 'edit' ? 'view' : 'edit' })
  }

  pivotChanged = (config) => {
    console.log(config)
    this.setState({ ...config })
  }

  getPreDefines = () => {
    switch (this.props.report) {
      case 'saldos':
        return (
          <Button size='sm' onClick={this.byDay}>
            Päivittäin
          </Button>
        )
      case 'payments':
        return (
          <React.Fragment>
            <Button size='sm' onClick={this.bySport}>
              Lajeittain
            </Button>{' '}
            <Button size='sm' onClick={this.bySportAndDay}>
              Lajeittain ja vuoropäivittäin
            </Button>{' '}
            <Button size='sm' onClick={this.bySportAndDayAndChannel}>
              Lajeittain ja vuoropäivittäin ja maksukanavittain
            </Button>{' '}
            <Button size='sm' onClick={this.byPaymentdayAndChannel}>
              Maksupäivittäin ja maksukanavittain (Kirjanpito)
            </Button>{' '}
          </React.Fragment>
        )
      case 'sales':
        return (
          <React.Fragment>
            <Button size='sm' onClick={this.bySport}>
              Lajeittain
            </Button>{' '}
            <Button size='sm' onClick={this.bySportAndDay}>
              Lajeittain ja vuoropäivittäin
            </Button>{' '}
          </React.Fragment>
        )
      case 'personalsaldos':
        return (
          <React.Fragment>
            <Button size='sm' onClick={this.byEmailNameAndDay}>
              Sähköpostin, nimen ja päivän mukaan
            </Button>{' '}
          </React.Fragment>
        )

      default:
        return null
    }
  }

  render() {
    if (this.props.rows.length > 0) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              {this.getPreDefines()}{' '}
              <Button size='sm' onClick={this.setEditMode}>
                {this.state.mode === 'edit' ? 'Näytä' : 'Muokkaa'}
              </Button>
              <Button size='sm' onClick={() => tableToExcel('pvtTable')}>
                Export to Excel
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <PivotTableUISmartWrapper {...this.state} onChange={this.pivotChanged} />
            </Col>
          </Row>
        </React.Fragment>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }
}
export default Pivoting
